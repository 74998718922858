var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"2"}},[_c('DataTable',_vm._b({scopedSlots:_vm._u([{key:"modal-form",fn:function(ref){
var editedItem = ref.editedItem;
return [_c('Form',{attrs:{"form":editedItem}})]}},{key:"content",fn:function(ref){
var item = ref.item;
return [_c('v-expansion-panels',{staticClass:"rounded-lg pa-3"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',[_vm._v(" mdi-text-search ")])]},proxy:true}],null,true)},[_vm._v(" Klik untuk melihat ")]),_c('v-expansion-panel-content',[_c('span',{staticClass:"text-wrap",domProps:{"innerHTML":_vm._s(item.content)}})])],1)],1)]}},{key:"category_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[(item.category_name === 'Gizi Buruk')?_c('v-chip',{attrs:{"color":"red darken-2","small":"","dark":""}},[_vm._v("Gizi Buruk (Severely Thinness)")]):_vm._e(),(item.category_name === 'Gizi Kurang')?_c('v-chip',{attrs:{"color":"yellow darken-4","small":"","dark":""}},[_vm._v("Gizi Kurang (Thinness)")]):_vm._e(),(item.category_name === 'Gizi Baik')?_c('v-chip',{attrs:{"color":"green darken-2","small":"","dark":""}},[_vm._v("Gizi Baik (Normal)")]):_vm._e(),(item.category_name === 'Gizi Lebih')?_c('v-chip',{attrs:{"color":"yellow darken-4","small":"","dark":""}},[_vm._v("Gizi Lebih (Overweight)")]):_vm._e(),(item.category_name === 'Obesitas')?_c('v-chip',{attrs:{"color":"red darken-2","small":"","dark":""}},[_vm._v("Obesitas (Obese)")]):_vm._e()],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.sync == 'presync')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-time-four-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Belum Sinkron Server")])]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.notify(item)}}},[_vm._v(" mdi-bell ")])]}}])},'DataTable',_vm.datatable,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }