<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem }">
          <Form :form="editedItem" />
        </template>

        <template #type="{ item }">
          <div class="font-weight-bold">
            <v-chip
              v-if="item.type === 'PENINGKATAN'"
              color="green darken-2"
              small
              dark
            >
              {{ item.type }}
            </v-chip>

            <v-chip
              v-if="item.type === 'TIDAK ADA PERUBAHAN'"
              color="yellow darken-2"
              small
              dark
            >
              {{ item.type }}
            </v-chip>

            <v-chip
              v-if="item.type === 'PENURUNAN'"
              color="red darken-2"
              small
              dark
            >
              {{ item.type }}
            </v-chip>
          </div>
        </template>

        <template #content="{ item }">
          <v-expansion-panels class="rounded-lg pa-3">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Klik untuk melihat
                <template v-slot:actions>
                  <v-icon> mdi-text-search </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span class="text-wrap" v-html="item.content"></span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>

        <template #variable_1="{ item }">
          <NutritionalCategory
            :categoryName="item.variable_1"
          ></NutritionalCategory>
        </template>

        <template #variable_2="{ item }">
          <NutritionalCategory
            :categoryName="item.variable_2"
          ></NutritionalCategory>
        </template>

        <template #actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.sync == 'presync'"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                small
                class="mr-2"
              >
                mdi-clock-time-four-outline
              </v-icon>
            </template>
            <span>Belum Sinkron Server</span>
          </v-tooltip>
          <v-icon color="success" small class="mr-2" @click="notify(item)">
            mdi-bell
          </v-icon>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import Form from "./Form";
import NutritionalCategory from "../../components/NutritionalCategory.vue";

export default {
  components: {
    DataTable,
    Form,
    NutritionalCategory,
  },
  data() {
    return {
      datatable: {
        moduleName: "RekomendasiDuaVariabel",
        title: "Daftar Rekomendasi Dua Variabel",
        headers: [
          { text: "No", value: "num", sortable: false },
          { text: "Tipe", value: "type", slot: true },
          { text: "Isi Konten", value: "content", slot: true },
          { text: "Variabel 1", value: "variable_1", slot: true },
          { text: "Variabel 2", value: "variable_2", slot: true },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ],
      },
    };
  },
};
</script>
