export default {
  namespaced: true,
  state: {
    bmiData: {
      "laki laki": {
        5: {
          1: {
            L: -0.7387,
            M: 15.2641,
            S: 0.0839,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.6,
            "+2 SD": 18.3,
            "+3 SD": 20.2,
          },
          2: {
            L: -0.7621,
            M: 15.2616,
            S: 0.08414,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.6,
            "+2 SD": 18.3,
            "+3 SD": 20.2,
          },
          3: {
            L: -0.7856,
            M: 15.2604,
            S: 0.08439,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.3,
            "+3 SD": 20.2,
          },
          4: {
            L: -0.8089,
            M: 15.2605,
            S: 0.08464,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.3,
            "+3 SD": 20.3,
          },
          5: {
            L: -0.8322,
            M: 15.2619,
            S: 0.0849,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.3,
            "+3 SD": 20.3,
          },
          6: {
            L: -0.8554,
            M: 15.2645,
            S: 0.08516,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.4,
            "+3 SD": 20.4,
          },
          7: {
            L: -0.8785,
            M: 15.2684,
            S: 0.08543,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.4,
            "+3 SD": 20.4,
          },
          8: {
            L: -0.9015,
            M: 15.2737,
            S: 0.0857,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.4,
            "+3 SD": 20.5,
          },
          9: {
            L: -0.9243,
            M: 15.2801,
            S: 0.08597,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.4,
            "+3 SD": 20.5,
          },
          10: {
            L: -0.9471,
            M: 15.2877,
            S: 0.08625,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.5,
            "+3 SD": 20.6,
          },
          11: {
            L: -0.9697,
            M: 15.2965,
            S: 0.08653,
            "-3 SD": 12.1,
            "-2 SD": 13,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.7,
            "+2 SD": 18.5,
            "+3 SD": 20.6,
          },
        },
        6: {
          0: {
            L: -0.9921,
            M: 15.3062,
            S: 0.08682,
            "-3 SD": 12.1,
            "-2 SD": 13.0,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.8,
            "+2 SD": 18.5,
            "+3 SD": 20.7,
          },
          1: {
            L: -1.0144,
            M: 15.3169,
            S: 0.08711,
            "-3 SD": 12.1,
            "-2 SD": 13.0,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.8,
            "+2 SD": 18.6,
            "+3 SD": 20.8,
          },
          2: {
            L: -1.0365,
            M: 15.3285,
            S: 0.08741,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.8,
            "+2 SD": 18.6,
            "+3 SD": 20.8,
          },
          3: {
            L: -1.0584,
            M: 15.3408,
            S: 0.08771,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.1,
            Median: 15.3,
            "+1 SD": 16.8,
            "+2 SD": 18.6,
            "+3 SD": 20.9,
          },
          4: {
            L: -1.0801,
            M: 15.354,
            S: 0.08802,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.1,
            Median: 15.4,
            "+1 SD": 16.8,
            "+2 SD": 18.7,
            "+3 SD": 21.0,
          },
          5: {
            L: -1.1017,
            M: 15.3679,
            S: 0.08833,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.1,
            Median: 15.4,
            "+1 SD": 16.9,
            "+2 SD": 18.7,
            "+3 SD": 21.0,
          },
          6: {
            L: -1.123,
            M: 15.3825,
            S: 0.08865,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.1,
            Median: 15.4,
            "+1 SD": 16.9,
            "+2 SD": 18.7,
            "+3 SD": 21.1,
          },
          7: {
            L: -1.1441,
            M: 15.3978,
            S: 0.08898,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.1,
            Median: 15.4,
            "+1 SD": 16.9,
            "+2 SD": 18.8,
            "+3 SD": 21.2,
          },
          8: {
            L: -1.1649,
            M: 15.4137,
            S: 0.08931,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.2,
            Median: 15.4,
            "+1 SD": 16.9,
            "+2 SD": 18.8,
            "+3 SD": 21.3,
          },
          9: {
            L: -1.1856,
            M: 15.4302,
            S: 0.08964,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.2,
            Median: 15.4,
            "+1 SD": 17.0,
            "+2 SD": 18.9,
            "+3 SD": 21.3,
          },
          10: {
            L: -1.206,
            M: 15.4473,
            S: 0.08998,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.2,
            Median: 15.4,
            "+1 SD": 17.0,
            "+2 SD": 18.9,
            "+3 SD": 21.4,
          },
          11: {
            L: -1.2261,
            M: 15.465,
            S: 0.09033,
            "-3 SD": 12.2,
            "-2 SD": 13.1,
            "-1 SD": 14.2,
            Median: 15.5,
            "+1 SD": 17.0,
            "+2 SD": 19.0,
            "+3 SD": 21.5,
          },
        },
        7: {
          0: {
            L: -1.246,
            M: 15.4832,
            S: 0.09068,
            "-3 SD": 12.3,
            "-2 SD": 13.1,
            "-1 SD": 14.2,
            Median: 15.5,
            "+1 SD": 17.0,
            "+2 SD": 19.0,
            "+3 SD": 21.6,
          },
          1: {
            L: -1.2656,
            M: 15.5019,
            S: 0.09103,
            "-3 SD": 12.3,
            "-2 SD": 13.2,
            "-1 SD": 14.2,
            Median: 15.5,
            "+1 SD": 17.1,
            "+2 SD": 19.1,
            "+3 SD": 21.7,
          },
          2: {
            L: -1.2849,
            M: 15.521,
            S: 0.09139,
            "-3 SD": 12.3,
            "-2 SD": 13.2,
            "-1 SD": 14.2,
            Median: 15.5,
            "+1 SD": 17.1,
            "+2 SD": 19.1,
            "+3 SD": 21.8,
          },
          3: {
            L: -1.304,
            M: 15.5407,
            S: 0.09176,
            "-3 SD": 12.3,
            "-2 SD": 13.2,
            "-1 SD": 14.3,
            Median: 15.5,
            "+1 SD": 17.1,
            "+2 SD": 19.2,
            "+3 SD": 21.9,
          },
          4: {
            L: -1.3228,
            M: 15.5608,
            S: 0.09213,
            "-3 SD": 12.3,
            "-2 SD": 13.2,
            "-1 SD": 14.3,
            Median: 15.6,
            "+1 SD": 17.2,
            "+2 SD": 19.2,
            "+3 SD": 22.0,
          },
          5: {
            L: -1.3414,
            M: 15.5814,
            S: 0.09251,
            "-3 SD": 12.3,
            "-2 SD": 13.2,
            "-1 SD": 14.3,
            Median: 15.6,
            "+1 SD": 17.2,
            "+2 SD": 19.3,
            "+3 SD": 22.0,
          },
          6: {
            L: -1.3596,
            M: 15.6023,
            S: 0.09289,
            "-3 SD": 12.3,
            "-2 SD": 13.2,
            "-1 SD": 14.3,
            Median: 15.6,
            "+1 SD": 17.2,
            "+2 SD": 19.3,
            "+3 SD": 22.1,
          },
          7: {
            L: -1.3776,
            M: 15.6237,
            S: 0.09327,
            "-3 SD": 12.3,
            "-2 SD": 13.2,
            "-1 SD": 14.3,
            Median: 15.6,
            "+1 SD": 17.3,
            "+2 SD": 19.4,
            "+3 SD": 22.2,
          },
          8: {
            L: -1.3953,
            M: 15.6455,
            S: 0.09366,
            "-3 SD": 12.3,
            "-2 SD": 13.2,
            "-1 SD": 14.3,
            Median: 15.6,
            "+1 SD": 17.3,
            "+2 SD": 19.4,
            "+3 SD": 22.4,
          },
          9: {
            L: -1.4126,
            M: 15.6677,
            S: 0.09406,
            "-3 SD": 12.4,
            "-2 SD": 13.3,
            "-1 SD": 14.3,
            Median: 15.7,
            "+1 SD": 17.3,
            "+2 SD": 19.5,
            "+3 SD": 22.5,
          },
          10: {
            L: -1.4297,
            M: 15.6903,
            S: 0.09445,
            "-3 SD": 12.4,
            "-2 SD": 13.3,
            "-1 SD": 14.4,
            Median: 15.7,
            "+1 SD": 17.4,
            "+2 SD": 19.6,
            "+3 SD": 22.6,
          },
          11: {
            L: -1.4464,
            M: 15.7133,
            S: 0.09486,
            "-3 SD": 12.4,
            "-2 SD": 13.3,
            "-1 SD": 14.4,
            Median: 15.7,
            "+1 SD": 17.4,
            "+2 SD": 19.6,
            "+3 SD": 22.7,
          },
        },
        8: {
          0: {
            L: -1.4629,
            M: 15.7368,
            S: 0.09526,
            "-3 SD": 12.4,
            "-2 SD": 13.3,
            "-1 SD": 14.4,
            Median: 15.7,
            "+1 SD": 17.4,
            "+2 SD": 19.7,
            "+3 SD": 22.8,
          },
          1: {
            L: -1.479,
            M: 15.7606,
            S: 0.09567,
            "-3 SD": 12.4,
            "-2 SD": 13.3,
            "-1 SD": 14.4,
            Median: 15.8,
            "+1 SD": 17.5,
            "+2 SD": 19.7,
            "+3 SD": 22.9,
          },
          2: {
            L: -1.4947,
            M: 15.7848,
            S: 0.09609,
            "-3 SD": 12.4,
            "-2 SD": 13.3,
            "-1 SD": 14.4,
            Median: 15.8,
            "+1 SD": 17.5,
            "+2 SD": 19.8,
            "+3 SD": 23.0,
          },
          3: {
            L: -1.5101,
            M: 15.8094,
            S: 0.09651,
            "-3 SD": 12.4,
            "-2 SD": 13.3,
            "-1 SD": 14.4,
            Median: 15.8,
            "+1 SD": 17.5,
            "+2 SD": 19.9,
            "+3 SD": 23.1,
          },
          4: {
            L: -1.5252,
            M: 15.8344,
            S: 0.09693,
            "-3 SD": 12.4,
            "-2 SD": 13.4,
            "-1 SD": 14.5,
            Median: 15.8,
            "+1 SD": 17.6,
            "+2 SD": 19.9,
            "+3 SD": 23.3,
          },
          5: {
            L: -1.5399,
            M: 15.8597,
            S: 0.09735,
            "-3 SD": 12.5,
            "-2 SD": 13.4,
            "-1 SD": 14.5,
            Median: 15.9,
            "+1 SD": 17.6,
            "+2 SD": 20.0,
            "+3 SD": 23.4,
          },
          6: {
            L: -1.5542,
            M: 15.8855,
            S: 0.09778,
            "-3 SD": 12.5,
            "-2 SD": 13.4,
            "-1 SD": 14.5,
            Median: 15.9,
            "+1 SD": 17.7,
            "+2 SD": 20.1,
            "+3 SD": 23.5,
          },
          7: {
            L: -1.5681,
            M: 15.9116,
            S: 0.09821,
            "-3 SD": 12.5,
            "-2 SD": 13.4,
            "-1 SD": 14.5,
            Median: 15.9,
            "+1 SD": 17.7,
            "+2 SD": 20.1,
            "+3 SD": 23.6,
          },
          8: {
            L: -1.5817,
            M: 15.9381,
            S: 0.09864,
            "-3 SD": 12.5,
            "-2 SD": 13.4,
            "-1 SD": 14.5,
            Median: 15.9,
            "+1 SD": 17.7,
            "+2 SD": 20.2,
            "+3 SD": 23.8,
          },
          9: {
            L: -1.5948,
            M: 15.9651,
            S: 0.09907,
            "-3 SD": 12.5,
            "-2 SD": 13.4,
            "-1 SD": 14.6,
            Median: 16.0,
            "+1 SD": 17.8,
            "+2 SD": 20.3,
            "+3 SD": 23.9,
          },
          10: {
            L: -1.6076,
            M: 15.9925,
            S: 0.09951,
            "-3 SD": 12.5,
            "-2 SD": 13.5,
            "-1 SD": 14.6,
            Median: 16.0,
            "+1 SD": 17.8,
            "+2 SD": 20.3,
            "+3 SD": 24.0,
          },
          11: {
            L: -1.6199,
            M: 16.0205,
            S: 0.09994,
            "-3 SD": 12.5,
            "-2 SD": 13.5,
            "-1 SD": 14.6,
            Median: 16.0,
            "+1 SD": 17.9,
            "+2 SD": 20.4,
            "+3 SD": 24.2,
          },
        },
        9: {
          0: {
            L: -1.6318,
            M: 16.049,
            S: 0.10038,
            "-3 SD": 12.6,
            "-2 SD": 13.5,
            "-1 SD": 14.6,
            Median: 16.0,
            "+1 SD": 17.9,
            "+2 SD": 20.5,
            "+3 SD": 24.3,
          },
          1: {
            L: -1.6433,
            M: 16.0781,
            S: 0.10082,
            "-3 SD": 12.6,
            "-2 SD": 13.5,
            "-1 SD": 14.6,
            Median: 16.1,
            "+1 SD": 18.0,
            "+2 SD": 20.5,
            "+3 SD": 24.4,
          },
          2: {
            L: -1.6544,
            M: 16.1078,
            S: 0.10126,
            "-3 SD": 12.6,
            "-2 SD": 13.5,
            "-1 SD": 14.7,
            Median: 16.1,
            "+1 SD": 18.0,
            "+2 SD": 20.6,
            "+3 SD": 24.6,
          },
          3: {
            L: -1.6651,
            M: 16.1381,
            S: 0.1017,
            "-3 SD": 12.6,
            "-2 SD": 13.5,
            "-1 SD": 14.7,
            Median: 16.1,
            "+1 SD": 18.0,
            "+2 SD": 20.7,
            "+3 SD": 24.7,
          },
          4: {
            L: -1.6753,
            M: 16.1692,
            S: 0.10214,
            "-3 SD": 12.6,
            "-2 SD": 13.6,
            "-1 SD": 14.7,
            Median: 16.2,
            "+1 SD": 18.1,
            "+2 SD": 20.8,
            "+3 SD": 24.9,
          },
          5: {
            L: -1.6851,
            M: 16.2009,
            S: 0.10259,
            "-3 SD": 12.6,
            "-2 SD": 13.6,
            "-1 SD": 14.7,
            Median: 16.2,
            "+1 SD": 18.1,
            "+2 SD": 20.8,
            "+3 SD": 25.0,
          },
          6: {
            L: -1.6944,
            M: 16.2333,
            S: 0.10303,
            "-3 SD": 12.7,
            "-2 SD": 13.6,
            "-1 SD": 14.8,
            Median: 16.2,
            "+1 SD": 18.2,
            "+2 SD": 20.9,
            "+3 SD": 25.1,
          },
          7: {
            L: -1.7032,
            M: 16.2665,
            S: 0.10347,
            "-3 SD": 12.7,
            "-2 SD": 13.6,
            "-1 SD": 14.8,
            Median: 16.3,
            "+1 SD": 18.2,
            "+2 SD": 21.0,
            "+3 SD": 25.3,
          },
          8: {
            L: -1.7116,
            M: 16.3004,
            S: 0.10391,
            "-3 SD": 12.7,
            "-2 SD": 13.6,
            "-1 SD": 14.8,
            Median: 16.3,
            "+1 SD": 18.3,
            "+2 SD": 21.1,
            "+3 SD": 25.5,
          },
          9: {
            L: -1.7196,
            M: 16.3351,
            S: 0.10435,
            "-3 SD": 12.7,
            "-2 SD": 13.7,
            "-1 SD": 14.8,
            Median: 16.3,
            "+1 SD": 18.3,
            "+2 SD": 21.2,
            "+3 SD": 25.6,
          },
          10: {
            L: -1.7271,
            M: 16.3704,
            S: 0.10478,
            "-3 SD": 12.7,
            "-2 SD": 13.7,
            "-1 SD": 14.9,
            Median: 16.4,
            "+1 SD": 18.4,
            "+2 SD": 21.2,
            "+3 SD": 25.8,
          },
          11: {
            L: -1.7341,
            M: 16.4065,
            S: 0.10522,
            "-3 SD": 12.8,
            "-2 SD": 13.7,
            "-1 SD": 14.9,
            Median: 16.4,
            "+1 SD": 18.4,
            "+2 SD": 21.3,
            "+3 SD": 25.9,
          },
        },
        10: {
          0: {
            L: -1.7407,
            M: 16.4433,
            S: 0.10566,
            "-3 SD": 12.8,
            "-2 SD": 13.7,
            "-1 SD": 14.9,
            Median: 16.4,
            "+1 SD": 18.5,
            "+2 SD": 21.4,
            "+3 SD": 26.1,
          },
          1: {
            L: -1.7468,
            M: 16.4807,
            S: 0.10609,
            "-3 SD": 12.8,
            "-2 SD": 13.8,
            "-1 SD": 15.0,
            Median: 16.5,
            "+1 SD": 18.5,
            "+2 SD": 21.5,
            "+3 SD": 26.2,
          },
          2: {
            L: -1.7525,
            M: 16.5189,
            S: 0.10652,
            "-3 SD": 12.8,
            "-2 SD": 13.8,
            "-1 SD": 15.0,
            Median: 16.5,
            "+1 SD": 18.6,
            "+2 SD": 21.6,
            "+3 SD": 26.4,
          },
          3: {
            L: -1.7578,
            M: 16.5578,
            S: 0.10695,
            "-3 SD": 12.8,
            "-2 SD": 13.8,
            "-1 SD": 15.0,
            Median: 16.6,
            "+1 SD": 18.6,
            "+2 SD": 21.7,
            "+3 SD": 26.6,
          },
          4: {
            L: -1.7626,
            M: 16.5974,
            S: 0.10738,
            "-3 SD": 12.9,
            "-2 SD": 13.8,
            "-1 SD": 15.0,
            Median: 16.6,
            "+1 SD": 18.7,
            "+2 SD": 21.7,
            "+3 SD": 26.7,
          },
          5: {
            L: -1.767,
            M: 16.6376,
            S: 0.1078,
            "-3 SD": 12.9,
            "-2 SD": 13.9,
            "-1 SD": 15.1,
            Median: 16.6,
            "+1 SD": 18.8,
            "+2 SD": 21.8,
            "+3 SD": 26.9,
          },
          6: {
            L: -1.771,
            M: 16.6786,
            S: 0.10823,
            "-3 SD": 12.9,
            "-2 SD": 13.9,
            "-1 SD": 15.1,
            Median: 16.7,
            "+1 SD": 18.8,
            "+2 SD": 21.9,
            "+3 SD": 27.0,
          },
          7: {
            L: -1.7745,
            M: 16.7203,
            S: 0.10865,
            "-3 SD": 12.9,
            "-2 SD": 13.9,
            "-1 SD": 15.1,
            Median: 16.7,
            "+1 SD": 18.9,
            "+2 SD": 22.0,
            "+3 SD": 27.2,
          },
          8: {
            L: -1.7777,
            M: 16.7628,
            S: 0.10906,
            "-3 SD": 13.0,
            "-2 SD": 13.9,
            "-1 SD": 15.2,
            Median: 16.8,
            "+1 SD": 18.9,
            "+2 SD": 22.1,
            "+3 SD": 27.4,
          },
          9: {
            L: -1.7804,
            M: 16.8059,
            S: 0.10948,
            "-3 SD": 13.0,
            "-2 SD": 14.0,
            "-1 SD": 15.2,
            Median: 16.8,
            "+1 SD": 19.0,
            "+2 SD": 22.2,
            "+3 SD": 27.5,
          },
          10: {
            L: -1.7828,
            M: 16.8497,
            S: 0.10989,
            "-3 SD": 13.0,
            "-2 SD": 14.0,
            "-1 SD": 15.2,
            Median: 16.9,
            "+1 SD": 19.0,
            "+2 SD": 22.3,
            "+3 SD": 27.7,
          },
          11: {
            L: -1.7847,
            M: 16.8941,
            S: 0.1103,
            "-3 SD": 13.0,
            "-2 SD": 14.0,
            "-1 SD": 15.3,
            Median: 16.9,
            "+1 SD": 19.1,
            "+2 SD": 22.4,
            "+3 SD": 27.9,
          },
        },
        11: {
          0: {
            L: -1.7862,
            M: 16.9392,
            S: 0.1107,
            "-3 SD": 13.1,
            "-2 SD": 14.1,
            "-1 SD": 15.3,
            Median: 16.9,
            "+1 SD": 19.2,
            "+2 SD": 22.5,
            "+3 SD": 28.0,
          },
          1: {
            L: -1.7873,
            M: 16.985,
            S: 0.1111,
            "-3 SD": 13.1,
            "-2 SD": 14.1,
            "-1 SD": 15.3,
            Median: 17.0,
            "+1 SD": 19.2,
            "+2 SD": 22.5,
            "+3 SD": 28.2,
          },
          2: {
            L: -1.7881,
            M: 17.0314,
            S: 0.1115,
            "-3 SD": 13.1,
            "-2 SD": 14.1,
            "-1 SD": 15.4,
            Median: 17.0,
            "+1 SD": 19.3,
            "+2 SD": 22.6,
            "+3 SD": 28.4,
          },
          3: {
            L: -1.7884,
            M: 17.0784,
            S: 0.11189,
            "-3 SD": 13.1,
            "-2 SD": 14.1,
            "-1 SD": 15.4,
            Median: 17.1,
            "+1 SD": 19.3,
            "+2 SD": 22.7,
            "+3 SD": 28.5,
          },
          4: {
            L: -1.7884,
            M: 17.1262,
            S: 0.11228,
            "-3 SD": 13.2,
            "-2 SD": 14.2,
            "-1 SD": 15.5,
            Median: 17.1,
            "+1 SD": 19.4,
            "+2 SD": 22.8,
            "+3 SD": 28.7,
          },
          5: {
            L: -1.788,
            M: 17.1746,
            S: 0.11266,
            "-3 SD": 13.2,
            "-2 SD": 14.2,
            "-1 SD": 15.5,
            Median: 17.2,
            "+1 SD": 19.5,
            "+2 SD": 22.9,
            "+3 SD": 28.8,
          },
          6: {
            L: -1.7873,
            M: 17.2236,
            S: 0.11304,
            "-3 SD": 13.2,
            "-2 SD": 14.2,
            "-1 SD": 15.5,
            Median: 17.2,
            "+1 SD": 19.5,
            "+2 SD": 23.0,
            "+3 SD": 29.0,
          },
          7: {
            L: -1.7861,
            M: 17.2734,
            S: 0.11342,
            "-3 SD": 13.2,
            "-2 SD": 14.3,
            "-1 SD": 15.6,
            Median: 17.3,
            "+1 SD": 19.6,
            "+2 SD": 23.1,
            "+3 SD": 29.2,
          },
          8: {
            L: -1.7846,
            M: 17.324,
            S: 0.11379,
            "-3 SD": 13.3,
            "-2 SD": 14.3,
            "-1 SD": 15.6,
            Median: 17.3,
            "+1 SD": 19.7,
            "+2 SD": 23.2,
            "+3 SD": 29.3,
          },
          9: {
            L: -1.7828,
            M: 17.3752,
            S: 0.11415,
            "-3 SD": 13.3,
            "-2 SD": 14.3,
            "-1 SD": 15.7,
            Median: 17.4,
            "+1 SD": 19.7,
            "+2 SD": 23.3,
            "+3 SD": 29.5,
          },
          10: {
            L: -1.7806,
            M: 17.4272,
            S: 0.11451,
            "-3 SD": 13.3,
            "-2 SD": 14.4,
            "-1 SD": 15.7,
            Median: 17.4,
            "+1 SD": 19.8,
            "+2 SD": 23.4,
            "+3 SD": 29.6,
          },
          11: {
            L: -1.778,
            M: 17.4799,
            S: 0.11487,
            "-3 SD": 13.4,
            "-2 SD": 14.4,
            "-1 SD": 15.7,
            Median: 17.5,
            "+1 SD": 19.9,
            "+2 SD": 23.5,
            "+3 SD": 29.8,
          },
        },
        12: {
          0: {
            L: -1.7751,
            M: 17.5334,
            S: 0.11522,
            "-3 SD": 13.4,
            "-2 SD": 14.5,
            "-1 SD": 15.8,
            Median: 17.5,
            "+1 SD": 19.9,
            "+2 SD": 23.6,
            "+3 SD": 30.0,
          },
          1: {
            L: -1.7719,
            M: 17.5877,
            S: 0.11556,
            "-3 SD": 13.4,
            "-2 SD": 14.5,
            "-1 SD": 15.8,
            Median: 17.6,
            "+1 SD": 20.0,
            "+2 SD": 23.7,
            "+3 SD": 30.1,
          },
          2: {
            L: -1.7684,
            M: 17.6427,
            S: 0.1159,
            "-3 SD": 13.5,
            "-2 SD": 14.5,
            "-1 SD": 15.9,
            Median: 17.6,
            "+1 SD": 20.1,
            "+2 SD": 23.8,
            "+3 SD": 30.3,
          },
          3: {
            L: -1.7645,
            M: 17.6985,
            S: 0.11623,
            "-3 SD": 13.5,
            "-2 SD": 14.6,
            "-1 SD": 15.9,
            Median: 17.7,
            "+1 SD": 20.2,
            "+2 SD": 23.9,
            "+3 SD": 30.4,
          },
          4: {
            L: -1.7604,
            M: 17.7551,
            S: 0.11656,
            "-3 SD": 13.5,
            "-2 SD": 14.6,
            "-1 SD": 16.0,
            Median: 17.8,
            "+1 SD": 20.2,
            "+2 SD": 24.0,
            "+3 SD": 30.6,
          },
          5: {
            L: -1.7559,
            M: 17.8124,
            S: 0.11688,
            "-3 SD": 13.6,
            "-2 SD": 14.6,
            "-1 SD": 16.0,
            Median: 17.8,
            "+1 SD": 20.3,
            "+2 SD": 24.1,
            "+3 SD": 30.7,
          },
          6: {
            L: -1.7511,
            M: 17.8704,
            S: 0.1172,
            "-3 SD": 13.6,
            "-2 SD": 14.7,
            "-1 SD": 16.1,
            Median: 17.9,
            "+1 SD": 20.4,
            "+2 SD": 24.2,
            "+3 SD": 30.9,
          },
          7: {
            L: -1.7461,
            M: 17.9292,
            S: 0.11751,
            "-3 SD": 13.6,
            "-2 SD": 14.7,
            "-1 SD": 16.1,
            Median: 17.9,
            "+1 SD": 20.4,
            "+2 SD": 24.3,
            "+3 SD": 31.0,
          },
          8: {
            L: -1.7408,
            M: 17.9887,
            S: 0.11781,
            "-3 SD": 13.7,
            "-2 SD": 14.8,
            "-1 SD": 16.2,
            Median: 18.0,
            "+1 SD": 20.5,
            "+2 SD": 24.4,
            "+3 SD": 31.1,
          },
          9: {
            L: -1.7352,
            M: 18.0488,
            S: 0.11811,
            "-3 SD": 13.7,
            "-2 SD": 14.8,
            "-1 SD": 16.2,
            Median: 18.0,
            "+1 SD": 20.6,
            "+2 SD": 24.5,
            "+3 SD": 31.3,
          },
          10: {
            L: -1.7293,
            M: 18.1096,
            S: 0.11841,
            "-3 SD": 13.7,
            "-2 SD": 14.8,
            "-1 SD": 16.3,
            Median: 18.1,
            "+1 SD": 20.7,
            "+2 SD": 24.6,
            "+3 SD": 31.4,
          },
          11: {
            L: -1.7232,
            M: 18.171,
            S: 0.11869,
            "-3 SD": 13.8,
            "-2 SD": 14.9,
            "-1 SD": 16.3,
            Median: 18.2,
            "+1 SD": 20.8,
            "+2 SD": 24.7,
            "+3 SD": 31.6,
          },
        },
        13: {
          0: {
            L: -1.7168,
            M: 18.233,
            S: 0.11898,
            "-3 SD": 13.8,
            "-2 SD": 14.9,
            "-1 SD": 16.4,
            Median: 18.2,
            "+1 SD": 20.8,
            "+2 SD": 24.8,
            "+3 SD": 31.7,
          },
          1: {
            L: -1.7102,
            M: 18.2955,
            S: 0.11925,
            "-3 SD": 13.8,
            "-2 SD": 15.0,
            "-1 SD": 16.4,
            Median: 18.3,
            "+1 SD": 20.9,
            "+2 SD": 24.9,
            "+3 SD": 31.8,
          },
          2: {
            L: -1.7033,
            M: 18.3586,
            S: 0.11952,
            "-3 SD": 13.9,
            "-2 SD": 15.0,
            "-1 SD": 16.5,
            Median: 18.4,
            "+1 SD": 21.0,
            "+2 SD": 25.0,
            "+3 SD": 31.9,
          },
          3: {
            L: -1.6962,
            M: 18.4221,
            S: 0.11979,
            "-3 SD": 13.9,
            "-2 SD": 15.1,
            "-1 SD": 16.5,
            Median: 18.4,
            "+1 SD": 21.1,
            "+2 SD": 25.1,
            "+3 SD": 32.1,
          },
          4: {
            L: -1.6888,
            M: 18.486,
            S: 0.12005,
            "-3 SD": 14.0,
            "-2 SD": 15.1,
            "-1 SD": 16.6,
            Median: 18.5,
            "+1 SD": 21.1,
            "+2 SD": 25.2,
            "+3 SD": 32.2,
          },
          5: {
            L: -1.6811,
            M: 18.5502,
            S: 0.1203,
            "-3 SD": 14.0,
            "-2 SD": 15.2,
            "-1 SD": 16.6,
            Median: 18.6,
            "+1 SD": 21.2,
            "+2 SD": 25.2,
            "+3 SD": 32.3,
          },
          6: {
            L: -1.6732,
            M: 18.6148,
            S: 0.12055,
            "-3 SD": 14.0,
            "-2 SD": 15.2,
            "-1 SD": 16.7,
            Median: 18.6,
            "+1 SD": 21.3,
            "+2 SD": 25.3,
            "+3 SD": 32.4,
          },
          7: {
            L: -1.6651,
            M: 18.6795,
            S: 0.12079,
            "-3 SD": 14.1,
            "-2 SD": 15.2,
            "-1 SD": 16.7,
            Median: 18.7,
            "+1 SD": 21.4,
            "+2 SD": 25.4,
            "+3 SD": 32.6,
          },
          8: {
            L: -1.6568,
            M: 18.7445,
            S: 0.12102,
            "-3 SD": 14.1,
            "-2 SD": 15.3,
            "-1 SD": 16.8,
            Median: 18.7,
            "+1 SD": 21.5,
            "+2 SD": 25.5,
            "+3 SD": 32.7,
          },
          9: {
            L: -1.6482,
            M: 18.8095,
            S: 0.12125,
            "-3 SD": 14.1,
            "-2 SD": 15.3,
            "-1 SD": 16.8,
            Median: 18.8,
            "+1 SD": 21.5,
            "+2 SD": 25.6,
            "+3 SD": 32.8,
          },
          10: {
            L: -1.6394,
            M: 18.8746,
            S: 0.12148,
            "-3 SD": 14.2,
            "-2 SD": 15.4,
            "-1 SD": 16.9,
            Median: 18.9,
            "+1 SD": 21.6,
            "+2 SD": 25.7,
            "+3 SD": 32.9,
          },
          11: {
            L: -1.6304,
            M: 18.9398,
            S: 0.1217,
            "-3 SD": 14.2,
            "-2 SD": 15.4,
            "-1 SD": 17.0,
            Median: 18.9,
            "+1 SD": 21.7,
            "+2 SD": 25.8,
            "+3 SD": 33.0,
          },
        },
        14: {
          0: {
            L: -1.6211,
            M: 19.005,
            S: 0.12191,
            "-3 SD": 14.3,
            "-2 SD": 15.5,
            "-1 SD": 17.0,
            Median: 19.0,
            "+1 SD": 21.8,
            "+2 SD": 25.9,
            "+3 SD": 33.1,
          },
          1: {
            L: -1.6116,
            M: 19.0701,
            S: 0.12212,
            "-3 SD": 14.3,
            "-2 SD": 15.5,
            "-1 SD": 17.1,
            Median: 19.1,
            "+1 SD": 21.8,
            "+2 SD": 26.0,
            "+3 SD": 33.2,
          },
          2: {
            L: -1.602,
            M: 19.1351,
            S: 0.12233,
            "-3 SD": 14.3,
            "-2 SD": 15.6,
            "-1 SD": 17.1,
            Median: 19.1,
            "+1 SD": 21.9,
            "+2 SD": 26.1,
            "+3 SD": 33.3,
          },
          3: {
            L: -1.5921,
            M: 19.2,
            S: 0.12253,
            "-3 SD": 14.4,
            "-2 SD": 15.6,
            "-1 SD": 17.2,
            Median: 19.2,
            "+1 SD": 22.0,
            "+2 SD": 26.2,
            "+3 SD": 33.4,
          },
          4: {
            L: -1.5821,
            M: 19.2648,
            S: 0.12272,
            "-3 SD": 14.4,
            "-2 SD": 15.7,
            "-1 SD": 17.2,
            Median: 19.3,
            "+1 SD": 22.1,
            "+2 SD": 26.3,
            "+3 SD": 33.5,
          },
          5: {
            L: -1.5719,
            M: 19.3294,
            S: 0.12291,
            "-3 SD": 14.5,
            "-2 SD": 15.7,
            "-1 SD": 17.3,
            Median: 19.3,
            "+1 SD": 22.2,
            "+2 SD": 26.4,
            "+3 SD": 33.5,
          },
          6: {
            L: -1.5615,
            M: 19.3937,
            S: 0.1231,
            "-3 SD": 14.5,
            "-2 SD": 15.7,
            "-1 SD": 17.3,
            Median: 19.4,
            "+1 SD": 22.2,
            "+2 SD": 26.5,
            "+3 SD": 33.6,
          },
          7: {
            L: -1.551,
            M: 19.4578,
            S: 0.12328,
            "-3 SD": 14.5,
            "-2 SD": 15.8,
            "-1 SD": 17.4,
            Median: 19.5,
            "+1 SD": 22.3,
            "+2 SD": 26.5,
            "+3 SD": 33.7,
          },
          8: {
            L: -1.5403,
            M: 19.5217,
            S: 0.12346,
            "-3 SD": 14.6,
            "-2 SD": 15.8,
            "-1 SD": 17.4,
            Median: 19.5,
            "+1 SD": 22.4,
            "+2 SD": 26.6,
            "+3 SD": 33.8,
          },
          9: {
            L: -1.5294,
            M: 19.5853,
            S: 0.12363,
            "-3 SD": 14.6,
            "-2 SD": 15.9,
            "-1 SD": 17.5,
            Median: 19.6,
            "+1 SD": 22.5,
            "+2 SD": 26.7,
            "+3 SD": 33.9,
          },
          10: {
            L: -1.5185,
            M: 19.6486,
            S: 0.1238,
            "-3 SD": 14.6,
            "-2 SD": 15.9,
            "-1 SD": 17.5,
            Median: 19.6,
            "+1 SD": 22.5,
            "+2 SD": 26.8,
            "+3 SD": 33.9,
          },
          11: {
            L: -1.5074,
            M: 19.7117,
            S: 0.12396,
            "-3 SD": 14.7,
            "-2 SD": 16.0,
            "-1 SD": 17.6,
            Median: 19.7,
            "+1 SD": 22.6,
            "+2 SD": 26.9,
            "+3 SD": 34.0,
          },
        },
        15: {
          0: {
            L: -1.4961,
            M: 19.7744,
            S: 0.12412,
            "-3 SD": 14.7,
            "-2 SD": 16.0,
            "-1 SD": 17.6,
            Median: 19.8,
            "+1 SD": 22.7,
            "+2 SD": 27.0,
            "+3 SD": 34.1,
          },
          1: {
            L: -1.4848,
            M: 19.8367,
            S: 0.12428,
            "-3 SD": 14.7,
            "-2 SD": 16.1,
            "-1 SD": 17.7,
            Median: 19.8,
            "+1 SD": 22.8,
            "+2 SD": 27.1,
            "+3 SD": 34.1,
          },
          2: {
            L: -1.4733,
            M: 19.8987,
            S: 0.12443,
            "-3 SD": 14.8,
            "-2 SD": 16.1,
            "-1 SD": 17.8,
            Median: 19.9,
            "+1 SD": 22.8,
            "+2 SD": 27.1,
            "+3 SD": 34.2,
          },
          3: {
            L: -1.4617,
            M: 19.9603,
            S: 0.12458,
            "-3 SD": 14.8,
            "-2 SD": 16.1,
            "-1 SD": 17.8,
            Median: 20.0,
            "+1 SD": 22.9,
            "+2 SD": 27.2,
            "+3 SD": 34.3,
          },
          4: {
            L: -1.45,
            M: 20.0215,
            S: 0.12473,
            "-3 SD": 14.8,
            "-2 SD": 16.2,
            "-1 SD": 17.9,
            Median: 20.0,
            "+1 SD": 23.0,
            "+2 SD": 27.3,
            "+3 SD": 34.3,
          },
          5: {
            L: -1.4382,
            M: 20.0823,
            S: 0.12487,
            "-3 SD": 14.9,
            "-2 SD": 16.2,
            "-1 SD": 17.9,
            Median: 20.1,
            "+1 SD": 23.0,
            "+2 SD": 27.4,
            "+3 SD": 34.4,
          },
          6: {
            L: -1.4263,
            M: 20.1427,
            S: 0.12501,
            "-3 SD": 14.9,
            "-2 SD": 16.3,
            "-1 SD": 18.0,
            Median: 20.1,
            "+1 SD": 23.1,
            "+2 SD": 27.4,
            "+3 SD": 34.5,
          },
          7: {
            L: -1.4143,
            M: 20.2026,
            S: 0.12514,
            "-3 SD": 15.0,
            "-2 SD": 16.3,
            "-1 SD": 18.0,
            Median: 20.2,
            "+1 SD": 23.2,
            "+2 SD": 27.5,
            "+3 SD": 34.5,
          },
          8: {
            L: -1.4022,
            M: 20.2621,
            S: 0.12528,
            "-3 SD": 15.0,
            "-2 SD": 16.3,
            "-1 SD": 18.1,
            Median: 20.3,
            "+1 SD": 23.3,
            "+2 SD": 27.6,
            "+3 SD": 34.6,
          },
          9: {
            L: -1.39,
            M: 20.3211,
            S: 0.12541,
            "-3 SD": 15.0,
            "-2 SD": 16.4,
            "-1 SD": 18.1,
            Median: 20.3,
            "+1 SD": 23.3,
            "+2 SD": 27.7,
            "+3 SD": 34.6,
          },
          10: {
            L: -1.3777,
            M: 20.3796,
            S: 0.12554,
            "-3 SD": 15.0,
            "-2 SD": 16.4,
            "-1 SD": 18.2,
            Median: 20.4,
            "+1 SD": 23.4,
            "+2 SD": 27.7,
            "+3 SD": 34.7,
          },
          11: {
            L: -1.3653,
            M: 20.4376,
            S: 0.12567,
            "-3 SD": 15.1,
            "-2 SD": 16.5,
            "-1 SD": 18.2,
            Median: 20.4,
            "+1 SD": 23.5,
            "+2 SD": 27.8,
            "+3 SD": 34.7,
          },
        },
        16: {
          0: {
            L: -1.3529,
            M: 20.4951,
            S: 0.12579,
            "-3 SD": 15.1,
            "-2 SD": 16.5,
            "-1 SD": 18.2,
            Median: 20.5,
            "+1 SD": 23.5,
            "+2 SD": 27.9,
            "+3 SD": 34.8,
          },
          1: {
            L: -1.3403,
            M: 20.5521,
            S: 0.12591,
            "-3 SD": 15.1,
            "-2 SD": 16.5,
            "-1 SD": 18.3,
            Median: 20.6,
            "+1 SD": 23.6,
            "+2 SD": 27.9,
            "+3 SD": 34.8,
          },
          2: {
            L: -1.3277,
            M: 20.6085,
            S: 0.12603,
            "-3 SD": 15.2,
            "-2 SD": 16.6,
            "-1 SD": 18.3,
            Median: 20.6,
            "+1 SD": 23.7,
            "+2 SD": 28.0,
            "+3 SD": 34.8,
          },
          3: {
            L: -1.3149,
            M: 20.6644,
            S: 0.12615,
            "-3 SD": 15.2,
            "-2 SD": 16.6,
            "-1 SD": 18.4,
            Median: 20.7,
            "+1 SD": 23.7,
            "+2 SD": 28.1,
            "+3 SD": 34.9,
          },
          4: {
            L: -1.3021,
            M: 20.7197,
            S: 0.12627,
            "-3 SD": 15.2,
            "-2 SD": 16.7,
            "-1 SD": 18.4,
            Median: 20.7,
            "+1 SD": 23.8,
            "+2 SD": 28.1,
            "+3 SD": 34.9,
          },
          5: {
            L: -1.2892,
            M: 20.7745,
            S: 0.12638,
            "-3 SD": 15.3,
            "-2 SD": 16.7,
            "-1 SD": 18.5,
            Median: 20.8,
            "+1 SD": 23.8,
            "+2 SD": 28.2,
            "+3 SD": 35.0,
          },
          6: {
            L: -1.2762,
            M: 20.8287,
            S: 0.1265,
            "-3 SD": 15.3,
            "-2 SD": 16.7,
            "-1 SD": 18.5,
            Median: 20.8,
            "+1 SD": 23.9,
            "+2 SD": 28.3,
            "+3 SD": 35.0,
          },
          7: {
            L: -1.2631,
            M: 20.8824,
            S: 0.12661,
            "-3 SD": 15.3,
            "-2 SD": 16.8,
            "-1 SD": 18.6,
            Median: 20.9,
            "+1 SD": 24.0,
            "+2 SD": 28.3,
            "+3 SD": 35.0,
          },
          8: {
            L: -1.2499,
            M: 20.9355,
            S: 0.12672,
            "-3 SD": 15.3,
            "-2 SD": 16.8,
            "-1 SD": 18.6,
            Median: 20.9,
            "+1 SD": 24.0,
            "+2 SD": 28.4,
            "+3 SD": 35.1,
          },
          9: {
            L: -1.2366,
            M: 20.9881,
            S: 0.12683,
            "-3 SD": 15.4,
            "-2 SD": 16.8,
            "-1 SD": 18.7,
            Median: 21.0,
            "+1 SD": 24.1,
            "+2 SD": 28.5,
            "+3 SD": 35.1,
          },
          10: {
            L: -1.2233,
            M: 21.04,
            S: 0.12694,
            "-3 SD": 15.4,
            "-2 SD": 16.9,
            "-1 SD": 18.7,
            Median: 21.0,
            "+1 SD": 24.2,
            "+2 SD": 28.5,
            "+3 SD": 35.1,
          },
          11: {
            L: -1.2098,
            M: 21.0914,
            S: 0.12704,
            "-3 SD": 15.4,
            "-2 SD": 16.9,
            "-1 SD": 18.7,
            Median: 21.1,
            "+1 SD": 24.2,
            "+2 SD": 28.6,
            "+3 SD": 35.2,
          },
        },
        17: {
          0: {
            L: -1.1962,
            M: 21.1423,
            S: 0.12715,
            "-3 SD": 15.4,
            "-2 SD": 16.9,
            "-1 SD": 18.8,
            Median: 21.1,
            "+1 SD": 24.3,
            "+2 SD": 28.6,
            "+3 SD": 35.2,
          },
          1: {
            L: -1.1826,
            M: 21.1925,
            S: 0.12726,
            "-3 SD": 15.5,
            "-2 SD": 17.0,
            "-1 SD": 18.8,
            Median: 21.2,
            "+1 SD": 24.3,
            "+2 SD": 28.7,
            "+3 SD": 35.2,
          },
          2: {
            L: -1.1688,
            M: 21.2423,
            S: 0.12736,
            "-3 SD": 15.5,
            "-2 SD": 17.0,
            "-1 SD": 18.9,
            Median: 21.2,
            "+1 SD": 24.4,
            "+2 SD": 28.7,
            "+3 SD": 35.2,
          },
          3: {
            L: -1.155,
            M: 21.2914,
            S: 0.12746,
            "-3 SD": 15.5,
            "-2 SD": 17.0,
            "-1 SD": 18.9,
            Median: 21.3,
            "+1 SD": 24.4,
            "+2 SD": 28.8,
            "+3 SD": 35.3,
          },
          4: {
            L: -1.141,
            M: 21.34,
            S: 0.12756,
            "-3 SD": 15.5,
            "-2 SD": 17.1,
            "-1 SD": 18.9,
            Median: 21.3,
            "+1 SD": 24.5,
            "+2 SD": 28.9,
            "+3 SD": 35.3,
          },
          5: {
            L: -1.127,
            M: 21.388,
            S: 0.12767,
            "-3 SD": 15.6,
            "-2 SD": 17.1,
            "-1 SD": 19.0,
            Median: 21.4,
            "+1 SD": 24.5,
            "+2 SD": 28.9,
            "+3 SD": 35.3,
          },
          6: {
            L: -1.1129,
            M: 21.4354,
            S: 0.12777,
            "-3 SD": 15.6,
            "-2 SD": 17.1,
            "-1 SD": 19.0,
            Median: 21.4,
            "+1 SD": 24.6,
            "+2 SD": 29.0,
            "+3 SD": 35.3,
          },
          7: {
            L: -1.0986,
            M: 21.4822,
            S: 0.12787,
            "-3 SD": 15.6,
            "-2 SD": 17.1,
            "-1 SD": 19.1,
            Median: 21.5,
            "+1 SD": 24.7,
            "+2 SD": 29.0,
            "+3 SD": 35.4,
          },
          8: {
            L: -1.0843,
            M: 21.5285,
            S: 0.12797,
            "-3 SD": 15.6,
            "-2 SD": 17.2,
            "-1 SD": 19.1,
            Median: 21.5,
            "+1 SD": 24.7,
            "+2 SD": 29.1,
            "+3 SD": 35.4,
          },
          9: {
            L: -1.0699,
            M: 21.5742,
            S: 0.12807,
            "-3 SD": 15.6,
            "-2 SD": 17.2,
            "-1 SD": 19.1,
            Median: 21.6,
            "+1 SD": 24.8,
            "+2 SD": 29.1,
            "+3 SD": 35.4,
          },
          10: {
            L: -1.0553,
            M: 21.6193,
            S: 0.12816,
            "-3 SD": 15.7,
            "-2 SD": 17.2,
            "-1 SD": 19.2,
            Median: 21.6,
            "+1 SD": 24.8,
            "+2 SD": 29.2,
            "+3 SD": 35.4,
          },
          11: {
            L: -1.0407,
            M: 21.6638,
            S: 0.12826,
            "-3 SD": 15.7,
            "-2 SD": 17.3,
            "-1 SD": 19.2,
            Median: 21.7,
            "+1 SD": 24.9,
            "+2 SD": 29.2,
            "+3 SD": 35.4,
          },
        },
        18: {
          0: {
            L: -1.026,
            M: 21.7077,
            S: 0.12836,
            "-3 SD": 15.7,
            "-2 SD": 17.3,
            "-1 SD": 19.2,
            Median: 21.7,
            "+1 SD": 24.9,
            "+2 SD": 29.2,
            "+3 SD": 35.4,
          },
          1: {
            L: -1.0112,
            M: 21.751,
            S: 0.12845,
            "-3 SD": 15.7,
            "-2 SD": 17.3,
            "-1 SD": 19.3,
            Median: 21.8,
            "+1 SD": 25.0,
            "+2 SD": 29.3,
            "+3 SD": 35.4,
          },
          2: {
            L: -0.9962,
            M: 21.7937,
            S: 0.12855,
            "-3 SD": 15.7,
            "-2 SD": 17.3,
            "-1 SD": 19.3,
            Median: 21.8,
            "+1 SD": 25.0,
            "+2 SD": 29.3,
            "+3 SD": 35.5,
          },
          3: {
            L: -0.9812,
            M: 21.8358,
            S: 0.12864,
            "-3 SD": 15.7,
            "-2 SD": 17.4,
            "-1 SD": 19.3,
            Median: 21.8,
            "+1 SD": 25.1,
            "+2 SD": 29.4,
            "+3 SD": 35.5,
          },
          4: {
            L: -0.9661,
            M: 21.8773,
            S: 0.12874,
            "-3 SD": 15.8,
            "-2 SD": 17.4,
            "-1 SD": 19.4,
            Median: 21.9,
            "+1 SD": 25.1,
            "+2 SD": 29.4,
            "+3 SD": 35.5,
          },
          5: {
            L: -0.9509,
            M: 21.9182,
            S: 0.12883,
            "-3 SD": 15.8,
            "-2 SD": 17.4,
            "-1 SD": 19.4,
            Median: 21.9,
            "+1 SD": 25.1,
            "+2 SD": 29.5,
            "+3 SD": 35.5,
          },
          6: {
            L: -0.9356,
            M: 21.9585,
            S: 0.12893,
            "-3 SD": 15.8,
            "-2 SD": 17.4,
            "-1 SD": 19.4,
            Median: 22.0,
            "+1 SD": 25.2,
            "+2 SD": 29.5,
            "+3 SD": 35.5,
          },
          7: {
            L: -0.9202,
            M: 21.9982,
            S: 0.12902,
            "-3 SD": 15.8,
            "-2 SD": 17.5,
            "-1 SD": 19.5,
            Median: 22.0,
            "+1 SD": 25.2,
            "+2 SD": 29.5,
            "+3 SD": 35.5,
          },
          8: {
            L: -0.9048,
            M: 22.0374,
            S: 0.12911,
            "-3 SD": 15.8,
            "-2 SD": 17.5,
            "-1 SD": 19.5,
            Median: 22.0,
            "+1 SD": 25.3,
            "+2 SD": 29.6,
            "+3 SD": 35.5,
          },
          9: {
            L: -0.8892,
            M: 22.076,
            S: 0.1292,
            "-3 SD": 15.8,
            "-2 SD": 17.5,
            "-1 SD": 19.5,
            Median: 22.1,
            "+1 SD": 25.3,
            "+2 SD": 29.6,
            "+3 SD": 35.5,
          },
          10: {
            L: -0.8735,
            M: 22.114,
            S: 0.1293,
            "-3 SD": 15.8,
            "-2 SD": 17.5,
            "-1 SD": 19.6,
            Median: 22.1,
            "+1 SD": 25.4,
            "+2 SD": 29.6,
            "+3 SD": 35.5,
          },
          11: {
            L: -0.8578,
            M: 22.1514,
            S: 0.12939,
            "-3 SD": 15.8,
            "-2 SD": 17.5,
            "-1 SD": 19.6,
            Median: 22.2,
            "+1 SD": 25.4,
            "+2 SD": 29.7,
            "+3 SD": 35.5,
          },
        },
        19: {
          0: {
            L: -0.8419,
            M: 22.1883,
            S: 0.12948,
            "-3 SD": 15.9,
            "-2 SD": 17.6,
            "-1 SD": 19.6,
            Median: 22.2,
            "+1 SD": 25.4,
            "+2 SD": 29.7,
            "+3 SD": 35.5,
          },
        },
      },
      perempuan: {
        5: {
          1: {
            L: -0.8886,
            M: 15.2441,
            S: 0.09692,
            "-3 SD": 11.8,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.2,
            "+1 SD": 16.9,
            "+2 SD": 18.9,
            "+3 SD": 21.3,
          },
          2: {
            L: -0.9068,
            M: 15.2434,
            S: 0.09738,
            "-3 SD": 11.8,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.2,
            "+1 SD": 16.9,
            "+2 SD": 18.9,
            "+3 SD": 21.4,
          },
          3: {
            L: -0.9248,
            M: 15.2433,
            S: 0.09783,
            "-3 SD": 11.8,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.2,
            "+1 SD": 16.9,
            "+2 SD": 18.9,
            "+3 SD": 21.5,
          },
          4: {
            L: -0.9427,
            M: 15.2438,
            S: 0.09829,
            "-3 SD": 11.8,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.2,
            "+1 SD": 16.9,
            "+2 SD": 18.9,
            "+3 SD": 21.5,
          },
          5: {
            L: -0.9605,
            M: 15.2448,
            S: 0.09875,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.2,
            "+1 SD": 16.9,
            "+2 SD": 19.0,
            "+3 SD": 21.6,
          },
          6: {
            L: -0.978,
            M: 15.2464,
            S: 0.0992,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.2,
            "+1 SD": 16.9,
            "+2 SD": 19.0,
            "+3 SD": 21.7,
          },
          7: {
            L: -0.9954,
            M: 15.2487,
            S: 0.09966,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.2,
            "+1 SD": 16.9,
            "+2 SD": 19.0,
            "+3 SD": 21.7,
          },
          8: {
            L: -1.0126,
            M: 15.2516,
            S: 0.10012,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.0,
            "+2 SD": 19.1,
            "+3 SD": 21.8,
          },
          9: {
            L: -1.0296,
            M: 15.2551,
            S: 0.10058,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.0,
            "+2 SD": 19.1,
            "+3 SD": 21.9,
          },
          10: {
            L: -1.0464,
            M: 15.2592,
            S: 0.10104,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.0,
            "+2 SD": 19.1,
            "+3 SD": 22.0,
          },
          11: {
            L: -1.063,
            M: 15.2641,
            S: 0.10149,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.0,
            "+2 SD": 19.2,
            "+3 SD": 22.1,
          },
        },
        6: {
          0: {
            L: -1.0794,
            M: 15.2697,
            S: 0.10195,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.0,
            "+2 SD": 19.2,
            "+3 SD": 22.1,
          },
          1: {
            L: -1.0956,
            M: 15.276,
            S: 0.10241,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.0,
            "+2 SD": 19.3,
            "+3 SD": 22.2,
          },
          2: {
            L: -1.1115,
            M: 15.2831,
            S: 0.10287,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.0,
            "+2 SD": 19.3,
            "+3 SD": 22.3,
          },
          3: {
            L: -1.1272,
            M: 15.2911,
            S: 0.10333,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.1,
            "+2 SD": 19.3,
            "+3 SD": 22.4,
          },
          4: {
            L: -1.1427,
            M: 15.2998,
            S: 0.10379,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.1,
            "+2 SD": 19.4,
            "+3 SD": 22.5,
          },
          5: {
            L: -1.1579,
            M: 15.3095,
            S: 0.10425,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.1,
            "+2 SD": 19.4,
            "+3 SD": 22.6,
          },
          6: {
            L: -1.1728,
            M: 15.32,
            S: 0.10471,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.1,
            "+2 SD": 19.5,
            "+3 SD": 22.7,
          },
          7: {
            L: -1.1875,
            M: 15.3314,
            S: 0.10517,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.2,
            "+2 SD": 19.5,
            "+3 SD": 22.8,
          },
          8: {
            L: -1.2019,
            M: 15.3439,
            S: 0.10562,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.3,
            "+1 SD": 17.2,
            "+2 SD": 19.6,
            "+3 SD": 22.9,
          },
          9: {
            L: -1.216,
            M: 15.3572,
            S: 0.10608,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.4,
            "+1 SD": 17.2,
            "+2 SD": 19.6,
            "+3 SD": 23.0,
          },
          10: {
            L: -1.2298,
            M: 15.3717,
            S: 0.10654,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.4,
            "+1 SD": 17.2,
            "+2 SD": 19.7,
            "+3 SD": 23.1,
          },
          11: {
            L: -1.2433,
            M: 15.3871,
            S: 0.107,
            "-3 SD": 11.7,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.4,
            "+1 SD": 17.3,
            "+2 SD": 19.7,
            "+3 SD": 23.2,
          },
        },
        7: {
          0: {
            L: -1.2565,
            M: 15.4036,
            S: 0.10746,
            "-3 SD": 11.8,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.4,
            "+1 SD": 17.3,
            "+2 SD": 19.8,
            "+3 SD": 23.3,
          },
          1: {
            L: -1.2693,
            M: 15.4211,
            S: 0.10792,
            "-3 SD": 11.8,
            "-2 SD": 12.7,
            "-1 SD": 13.9,
            Median: 15.4,
            "+1 SD": 17.3,
            "+2 SD": 19.8,
            "+3 SD": 23.4,
          },
          2: {
            L: -1.2819,
            M: 15.4397,
            S: 0.10837,
            "-3 SD": 11.8,
            "-2 SD": 12.8,
            "-1 SD": 14.0,
            Median: 15.4,
            "+1 SD": 17.4,
            "+2 SD": 19.9,
            "+3 SD": 23.5,
          },
          3: {
            L: -1.2941,
            M: 15.4593,
            S: 0.10883,
            "-3 SD": 11.8,
            "-2 SD": 12.8,
            "-1 SD": 14.0,
            Median: 15.5,
            "+1 SD": 17.4,
            "+2 SD": 20.0,
            "+3 SD": 23.6,
          },
          4: {
            L: -1.306,
            M: 15.4798,
            S: 0.10929,
            "-3 SD": 11.8,
            "-2 SD": 12.8,
            "-1 SD": 14.0,
            Median: 15.5,
            "+1 SD": 17.4,
            "+2 SD": 20.0,
            "+3 SD": 23.7,
          },
          5: {
            L: -1.3175,
            M: 15.5014,
            S: 0.10974,
            "-3 SD": 11.8,
            "-2 SD": 12.8,
            "-1 SD": 14.0,
            Median: 15.5,
            "+1 SD": 17.5,
            "+2 SD": 20.1,
            "+3 SD": 23.9,
          },
          6: {
            L: -1.3287,
            M: 15.524,
            S: 0.1102,
            "-3 SD": 11.8,
            "-2 SD": 12.8,
            "-1 SD": 14.0,
            Median: 15.5,
            "+1 SD": 17.5,
            "+2 SD": 20.1,
            "+3 SD": 24.0,
          },
          7: {
            L: -1.3395,
            M: 15.5476,
            S: 0.11065,
            "-3 SD": 11.8,
            "-2 SD": 12.8,
            "-1 SD": 14.0,
            Median: 15.5,
            "+1 SD": 17.5,
            "+2 SD": 20.2,
            "+3 SD": 24.1,
          },
          8: {
            L: -1.3499,
            M: 15.5723,
            S: 0.1111,
            "-3 SD": 11.8,
            "-2 SD": 12.8,
            "-1 SD": 14.0,
            Median: 15.6,
            "+1 SD": 17.6,
            "+2 SD": 20.3,
            "+3 SD": 24.2,
          },
          9: {
            L: -1.36,
            M: 15.5979,
            S: 0.11156,
            "-3 SD": 11.8,
            "-2 SD": 12.8,
            "-1 SD": 14.1,
            Median: 15.6,
            "+1 SD": 17.6,
            "+2 SD": 20.3,
            "+3 SD": 24.4,
          },
          10: {
            L: -1.3697,
            M: 15.6246,
            S: 0.11201,
            "-3 SD": 11.9,
            "-2 SD": 12.9,
            "-1 SD": 14.1,
            Median: 15.6,
            "+1 SD": 17.6,
            "+2 SD": 20.4,
            "+3 SD": 24.5,
          },
          11: {
            L: -1.379,
            M: 15.6523,
            S: 0.11246,
            "-3 SD": 11.9,
            "-2 SD": 12.9,
            "-1 SD": 14.1,
            Median: 15.7,
            "+1 SD": 17.7,
            "+2 SD": 20.5,
            "+3 SD": 24.6,
          },
        },
        8: {
          0: {
            L: -1.388,
            M: 15.681,
            S: 0.11291,
            "-3 SD": 11.9,
            "-2 SD": 12.9,
            "-1 SD": 14.1,
            Median: 15.7,
            "+1 SD": 17.7,
            "+2 SD": 20.6,
            "+3 SD": 24.8,
          },
          1: {
            L: -1.3966,
            M: 15.7107,
            S: 0.11335,
            "-3 SD": 11.9,
            "-2 SD": 12.9,
            "-1 SD": 14.1,
            Median: 15.7,
            "+1 SD": 17.8,
            "+2 SD": 20.6,
            "+3 SD": 24.9,
          },
          2: {
            L: -1.4047,
            M: 15.7415,
            S: 0.1138,
            "-3 SD": 11.9,
            "-2 SD": 12.9,
            "-1 SD": 14.2,
            Median: 15.7,
            "+1 SD": 17.8,
            "+2 SD": 20.7,
            "+3 SD": 25.1,
          },
          3: {
            L: -1.4125,
            M: 15.7732,
            S: 0.11424,
            "-3 SD": 11.9,
            "-2 SD": 12.9,
            "-1 SD": 14.2,
            Median: 15.8,
            "+1 SD": 17.9,
            "+2 SD": 20.8,
            "+3 SD": 25.2,
          },
          4: {
            L: -1.4199,
            M: 15.8058,
            S: 0.11469,
            "-3 SD": 11.9,
            "-2 SD": 13.0,
            "-1 SD": 14.2,
            Median: 15.8,
            "+1 SD": 17.9,
            "+2 SD": 20.9,
            "+3 SD": 25.3,
          },
          5: {
            L: -1.427,
            M: 15.8394,
            S: 0.11513,
            "-3 SD": 12.0,
            "-2 SD": 13.0,
            "-1 SD": 14.2,
            Median: 15.8,
            "+1 SD": 18.0,
            "+2 SD": 20.9,
            "+3 SD": 25.5,
          },
          6: {
            L: -1.4336,
            M: 15.8738,
            S: 0.11557,
            "-3 SD": 12.0,
            "-2 SD": 13.0,
            "-1 SD": 14.3,
            Median: 15.9,
            "+1 SD": 18.0,
            "+2 SD": 21.0,
            "+3 SD": 25.6,
          },
          7: {
            L: -1.4398,
            M: 15.909,
            S: 0.11601,
            "-3 SD": 12.0,
            "-2 SD": 13.0,
            "-1 SD": 14.3,
            Median: 15.9,
            "+1 SD": 18.1,
            "+2 SD": 21.1,
            "+3 SD": 25.8,
          },
          8: {
            L: -1.4456,
            M: 15.9451,
            S: 0.11644,
            "-3 SD": 12.0,
            "-2 SD": 13.0,
            "-1 SD": 14.3,
            Median: 15.9,
            "+1 SD": 18.1,
            "+2 SD": 21.2,
            "+3 SD": 25.9,
          },
          9: {
            L: -1.4511,
            M: 15.9818,
            S: 0.11688,
            "-3 SD": 12.0,
            "-2 SD": 13.1,
            "-1 SD": 14.3,
            Median: 16.0,
            "+1 SD": 18.2,
            "+2 SD": 21.3,
            "+3 SD": 26.1,
          },
          10: {
            L: -1.4561,
            M: 16.0194,
            S: 0.11731,
            "-3 SD": 12.1,
            "-2 SD": 13.1,
            "-1 SD": 14.4,
            Median: 16.0,
            "+1 SD": 18.2,
            "+2 SD": 21.3,
            "+3 SD": 26.2,
          },
          11: {
            L: -1.4607,
            M: 16.0575,
            S: 0.11774,
            "-3 SD": 12.1,
            "-2 SD": 13.1,
            "-1 SD": 14.4,
            Median: 16.1,
            "+1 SD": 18.3,
            "+2 SD": 21.4,
            "+3 SD": 26.4,
          },
        },
        9: {
          0: {
            L: -1.465,
            M: 16.0964,
            S: 0.11816,
            "-3 SD": 12.1,
            "-2 SD": 13.1,
            "-1 SD": 14.4,
            Median: 16.1,
            "+1 SD": 18.3,
            "+2 SD": 21.5,
            "+3 SD": 26.5,
          },
          1: {
            L: -1.4688,
            M: 16.1358,
            S: 0.11859,
            "-3 SD": 12.1,
            "-2 SD": 13.2,
            "-1 SD": 14.5,
            Median: 16.1,
            "+1 SD": 18.4,
            "+2 SD": 21.6,
            "+3 SD": 26.7,
          },
          2: {
            L: -1.4723,
            M: 16.1759,
            S: 0.11901,
            "-3 SD": 12.1,
            "-2 SD": 13.2,
            "-1 SD": 14.5,
            Median: 16.2,
            "+1 SD": 18.4,
            "+2 SD": 21.7,
            "+3 SD": 26.8,
          },
          3: {
            L: -1.4753,
            M: 16.2166,
            S: 0.11943,
            "-3 SD": 12.2,
            "-2 SD": 13.2,
            "-1 SD": 14.5,
            Median: 16.2,
            "+1 SD": 18.5,
            "+2 SD": 21.8,
            "+3 SD": 27.0,
          },
          4: {
            L: -1.478,
            M: 16.258,
            S: 0.11985,
            "-3 SD": 12.2,
            "-2 SD": 13.2,
            "-1 SD": 14.6,
            Median: 16.3,
            "+1 SD": 18.6,
            "+2 SD": 21.9,
            "+3 SD": 27.2,
          },
          5: {
            L: -1.4803,
            M: 16.2999,
            S: 0.12026,
            "-3 SD": 12.2,
            "-2 SD": 13.3,
            "-1 SD": 14.6,
            Median: 16.3,
            "+1 SD": 18.6,
            "+2 SD": 21.9,
            "+3 SD": 27.3,
          },
          6: {
            L: -1.4823,
            M: 16.3425,
            S: 0.12067,
            "-3 SD": 12.2,
            "-2 SD": 13.3,
            "-1 SD": 14.6,
            Median: 16.3,
            "+1 SD": 18.7,
            "+2 SD": 22.0,
            "+3 SD": 27.5,
          },
          7: {
            L: -1.4838,
            M: 16.3858,
            S: 0.12108,
            "-3 SD": 12.3,
            "-2 SD": 13.3,
            "-1 SD": 14.7,
            Median: 16.4,
            "+1 SD": 18.7,
            "+2 SD": 22.1,
            "+3 SD": 27.6,
          },
          8: {
            L: -1.485,
            M: 16.4298,
            S: 0.12148,
            "-3 SD": 12.3,
            "-2 SD": 13.4,
            "-1 SD": 14.7,
            Median: 16.4,
            "+1 SD": 18.8,
            "+2 SD": 22.2,
            "+3 SD": 27.8,
          },
          9: {
            L: -1.4859,
            M: 16.4746,
            S: 0.12188,
            "-3 SD": 12.3,
            "-2 SD": 13.4,
            "-1 SD": 14.7,
            Median: 16.5,
            "+1 SD": 18.8,
            "+2 SD": 22.3,
            "+3 SD": 27.9,
          },
          10: {
            L: -1.4864,
            M: 16.52,
            S: 0.12228,
            "-3 SD": 12.3,
            "-2 SD": 13.4,
            "-1 SD": 14.8,
            Median: 16.5,
            "+1 SD": 18.9,
            "+2 SD": 22.4,
            "+3 SD": 28.1,
          },
          11: {
            L: -1.4866,
            M: 16.5663,
            S: 0.12268,
            "-3 SD": 12.4,
            "-2 SD": 13.4,
            "-1 SD": 14.8,
            Median: 16.6,
            "+1 SD": 19.0,
            "+2 SD": 22.5,
            "+3 SD": 28.2,
          },
        },
        10: {
          0: {
            L: -1.4864,
            M: 16.6133,
            S: 0.12307,
            "-3 SD": 12.4,
            "-2 SD": 13.5,
            "-1 SD": 14.8,
            Median: 16.6,
            "+1 SD": 19.0,
            "+2 SD": 22.6,
            "+3 SD": 28.4,
          },
          1: {
            L: -1.4859,
            M: 16.6612,
            S: 0.12346,
            "-3 SD": 12.4,
            "-2 SD": 13.5,
            "-1 SD": 14.9,
            Median: 16.7,
            "+1 SD": 19.1,
            "+2 SD": 22.7,
            "+3 SD": 28.5,
          },
          2: {
            L: -1.4851,
            M: 16.71,
            S: 0.12384,
            "-3 SD": 12.4,
            "-2 SD": 13.5,
            "-1 SD": 14.9,
            Median: 16.7,
            "+1 SD": 19.2,
            "+2 SD": 22.8,
            "+3 SD": 28.7,
          },
          3: {
            L: -1.4839,
            M: 16.7595,
            S: 0.12422,
            "-3 SD": 12.5,
            "-2 SD": 13.6,
            "-1 SD": 15.0,
            Median: 16.8,
            "+1 SD": 19.2,
            "+2 SD": 22.8,
            "+3 SD": 28.8,
          },
          4: {
            L: -1.4825,
            M: 16.81,
            S: 0.1246,
            "-3 SD": 12.5,
            "-2 SD": 13.6,
            "-1 SD": 15.0,
            Median: 16.8,
            "+1 SD": 19.3,
            "+2 SD": 22.9,
            "+3 SD": 29.0,
          },
          5: {
            L: -1.4807,
            M: 16.8614,
            S: 0.12497,
            "-3 SD": 12.5,
            "-2 SD": 13.6,
            "-1 SD": 15.0,
            Median: 16.9,
            "+1 SD": 19.4,
            "+2 SD": 23.0,
            "+3 SD": 29.1,
          },
          6: {
            L: -1.4787,
            M: 16.9136,
            S: 0.12534,
            "-3 SD": 12.5,
            "-2 SD": 13.7,
            "-1 SD": 15.1,
            Median: 16.9,
            "+1 SD": 19.4,
            "+2 SD": 23.1,
            "+3 SD": 29.3,
          },
          7: {
            L: -1.4763,
            M: 16.9667,
            S: 0.12571,
            "-3 SD": 12.6,
            "-2 SD": 13.7,
            "-1 SD": 15.1,
            Median: 17.0,
            "+1 SD": 19.5,
            "+2 SD": 23.2,
            "+3 SD": 29.4,
          },
          8: {
            L: -1.4737,
            M: 17.0208,
            S: 0.12607,
            "-3 SD": 12.6,
            "-2 SD": 13.7,
            "-1 SD": 15.2,
            Median: 17.0,
            "+1 SD": 19.6,
            "+2 SD": 23.3,
            "+3 SD": 29.6,
          },
          9: {
            L: -1.4708,
            M: 17.0757,
            S: 0.12643,
            "-3 SD": 12.6,
            "-2 SD": 13.8,
            "-1 SD": 15.2,
            Median: 17.1,
            "+1 SD": 19.6,
            "+2 SD": 23.4,
            "+3 SD": 29.7,
          },
          10: {
            L: -1.4677,
            M: 17.1316,
            S: 0.12678,
            "-3 SD": 12.7,
            "-2 SD": 13.8,
            "-1 SD": 15.3,
            Median: 17.1,
            "+1 SD": 19.7,
            "+2 SD": 23.5,
            "+3 SD": 29.9,
          },
          11: {
            L: -1.4642,
            M: 17.1883,
            S: 0.12713,
            "-3 SD": 12.7,
            "-2 SD": 13.8,
            "-1 SD": 15.3,
            Median: 17.2,
            "+1 SD": 19.8,
            "+2 SD": 23.6,
            "+3 SD": 30.0,
          },
        },
        11: {
          0: {
            L: -1.4606,
            M: 17.2459,
            S: 0.12748,
            "-3 SD": 12.7,
            "-2 SD": 13.9,
            "-1 SD": 15.3,
            Median: 17.2,
            "+1 SD": 19.9,
            "+2 SD": 23.7,
            "+3 SD": 30.2,
          },
          1: {
            L: -1.4567,
            M: 17.3044,
            S: 0.12782,
            "-3 SD": 12.8,
            "-2 SD": 13.9,
            "-1 SD": 15.4,
            Median: 17.3,
            "+1 SD": 19.9,
            "+2 SD": 23.8,
            "+3 SD": 30.3,
          },
          2: {
            L: -1.4526,
            M: 17.3637,
            S: 0.12816,
            "-3 SD": 12.8,
            "-2 SD": 14.0,
            "-1 SD": 15.4,
            Median: 17.4,
            "+1 SD": 20.0,
            "+2 SD": 23.9,
            "+3 SD": 30.5,
          },
          3: {
            L: -1.4482,
            M: 17.4238,
            S: 0.12849,
            "-3 SD": 12.8,
            "-2 SD": 14.0,
            "-1 SD": 15.5,
            Median: 17.4,
            "+1 SD": 20.1,
            "+2 SD": 24.0,
            "+3 SD": 30.6,
          },
          4: {
            L: -1.4436,
            M: 17.4847,
            S: 0.12882,
            "-3 SD": 12.9,
            "-2 SD": 14.0,
            "-1 SD": 15.5,
            Median: 17.5,
            "+1 SD": 20.2,
            "+2 SD": 24.1,
            "+3 SD": 30.8,
          },
          5: {
            L: -1.4389,
            M: 17.5464,
            S: 0.12914,
            "-3 SD": 12.9,
            "-2 SD": 14.1,
            "-1 SD": 15.6,
            Median: 17.5,
            "+1 SD": 20.2,
            "+2 SD": 24.2,
            "+3 SD": 30.9,
          },
          6: {
            L: -1.4339,
            M: 17.6088,
            S: 0.12946,
            "-3 SD": 12.9,
            "-2 SD": 14.1,
            "-1 SD": 15.6,
            Median: 17.6,
            "+1 SD": 20.3,
            "+2 SD": 24.3,
            "+3 SD": 31.1,
          },
          7: {
            L: -1.4288,
            M: 17.6719,
            S: 0.12978,
            "-3 SD": 13.0,
            "-2 SD": 14.2,
            "-1 SD": 15.7,
            Median: 17.7,
            "+1 SD": 20.4,
            "+2 SD": 24.4,
            "+3 SD": 31.2,
          },
          8: {
            L: -1.4235,
            M: 17.7357,
            S: 0.13009,
            "-3 SD": 13.0,
            "-2 SD": 14.2,
            "-1 SD": 15.7,
            Median: 17.7,
            "+1 SD": 20.5,
            "+2 SD": 24.5,
            "+3 SD": 31.4,
          },
          9: {
            L: -1.418,
            M: 17.8001,
            S: 0.1304,
            "-3 SD": 13.0,
            "-2 SD": 14.3,
            "-1 SD": 15.8,
            Median: 17.8,
            "+1 SD": 20.6,
            "+2 SD": 24.7,
            "+3 SD": 31.5,
          },
          10: {
            L: -1.4123,
            M: 17.8651,
            S: 0.1307,
            "-3 SD": 13.1,
            "-2 SD": 14.3,
            "-1 SD": 15.8,
            Median: 17.9,
            "+1 SD": 20.6,
            "+2 SD": 24.8,
            "+3 SD": 31.6,
          },
          11: {
            L: -1.4065,
            M: 17.9306,
            S: 0.13099,
            "-3 SD": 13.1,
            "-2 SD": 14.3,
            "-1 SD": 15.9,
            Median: 17.9,
            "+1 SD": 20.7,
            "+2 SD": 24.9,
            "+3 SD": 31.8,
          },
        },
        12: {
          0: {
            L: -1.4006,
            M: 17.9966,
            S: 0.13129,
            "-3 SD": 13.2,
            "-2 SD": 14.4,
            "-1 SD": 16.0,
            Median: 18.0,
            "+1 SD": 20.8,
            "+2 SD": 25.0,
            "+3 SD": 31.9,
          },
          1: {
            L: -1.3945,
            M: 18.063,
            S: 0.13158,
            "-3 SD": 13.2,
            "-2 SD": 14.4,
            "-1 SD": 16.0,
            Median: 18.1,
            "+1 SD": 20.9,
            "+2 SD": 25.1,
            "+3 SD": 32.0,
          },
          2: {
            L: -1.3883,
            M: 18.1297,
            S: 0.13186,
            "-3 SD": 13.2,
            "-2 SD": 14.5,
            "-1 SD": 16.1,
            Median: 18.1,
            "+1 SD": 21.0,
            "+2 SD": 25.2,
            "+3 SD": 32.2,
          },
          3: {
            L: -1.3819,
            M: 18.1967,
            S: 0.13214,
            "-3 SD": 13.3,
            "-2 SD": 14.5,
            "-1 SD": 16.1,
            Median: 18.2,
            "+1 SD": 21.1,
            "+2 SD": 25.3,
            "+3 SD": 32.3,
          },
          4: {
            L: -1.3755,
            M: 18.2639,
            S: 0.13241,
            "-3 SD": 13.3,
            "-2 SD": 14.6,
            "-1 SD": 16.2,
            Median: 18.3,
            "+1 SD": 21.1,
            "+2 SD": 25.4,
            "+3 SD": 32.4,
          },
          5: {
            L: -1.3689,
            M: 18.3312,
            S: 0.13268,
            "-3 SD": 13.3,
            "-2 SD": 14.6,
            "-1 SD": 16.2,
            Median: 18.3,
            "+1 SD": 21.2,
            "+2 SD": 25.5,
            "+3 SD": 32.6,
          },
          6: {
            L: -1.3621,
            M: 18.3986,
            S: 0.13295,
            "-3 SD": 13.4,
            "-2 SD": 14.7,
            "-1 SD": 16.3,
            Median: 18.4,
            "+1 SD": 21.3,
            "+2 SD": 25.6,
            "+3 SD": 32.7,
          },
          7: {
            L: -1.3553,
            M: 18.466,
            S: 0.13321,
            "-3 SD": 13.4,
            "-2 SD": 14.7,
            "-1 SD": 16.3,
            Median: 18.5,
            "+1 SD": 21.4,
            "+2 SD": 25.7,
            "+3 SD": 32.8,
          },
          8: {
            L: -1.3483,
            M: 18.5333,
            S: 0.13347,
            "-3 SD": 13.5,
            "-2 SD": 14.8,
            "-1 SD": 16.4,
            Median: 18.5,
            "+1 SD": 21.5,
            "+2 SD": 25.8,
            "+3 SD": 33.0,
          },
          9: {
            L: -1.3413,
            M: 18.6006,
            S: 0.13372,
            "-3 SD": 13.5,
            "-2 SD": 14.8,
            "-1 SD": 16.4,
            Median: 18.6,
            "+1 SD": 21.6,
            "+2 SD": 25.9,
            "+3 SD": 33.1,
          },
          10: {
            L: -1.3341,
            M: 18.6677,
            S: 0.13397,
            "-3 SD": 13.5,
            "-2 SD": 14.8,
            "-1 SD": 16.5,
            Median: 18.7,
            "+1 SD": 21.6,
            "+2 SD": 26.0,
            "+3 SD": 33.2,
          },
          11: {
            L: -1.3269,
            M: 18.7346,
            S: 0.13421,
            "-3 SD": 13.6,
            "-2 SD": 14.9,
            "-1 SD": 16.6,
            Median: 18.7,
            "+1 SD": 21.7,
            "+2 SD": 26.1,
            "+3 SD": 33.3,
          },
        },
        13: {
          0: {
            L: -1.3195,
            M: 18.8012,
            S: 0.13445,
            "-3 SD": 13.6,
            "-2 SD": 14.9,
            "-1 SD": 16.6,
            Median: 18.8,
            "+1 SD": 21.8,
            "+2 SD": 26.2,
            "+3 SD": 33.4,
          },
          1: {
            L: -1.3121,
            M: 18.8675,
            S: 0.13469,
            "-3 SD": 13.6,
            "-2 SD": 15.0,
            "-1 SD": 16.7,
            Median: 18.9,
            "+1 SD": 21.9,
            "+2 SD": 26.3,
            "+3 SD": 33.6,
          },
          2: {
            L: -1.3046,
            M: 18.9335,
            S: 0.13492,
            "-3 SD": 13.7,
            "-2 SD": 15.0,
            "-1 SD": 16.7,
            Median: 18.9,
            "+1 SD": 22.0,
            "+2 SD": 26.4,
            "+3 SD": 33.7,
          },
          3: {
            L: -1.297,
            M: 18.9991,
            S: 0.13514,
            "-3 SD": 13.7,
            "-2 SD": 15.1,
            "-1 SD": 16.8,
            Median: 19.0,
            "+1 SD": 22.0,
            "+2 SD": 26.5,
            "+3 SD": 33.8,
          },
          4: {
            L: -1.2894,
            M: 19.0642,
            S: 0.13537,
            "-3 SD": 13.8,
            "-2 SD": 15.1,
            "-1 SD": 16.8,
            Median: 19.1,
            "+1 SD": 22.1,
            "+2 SD": 26.6,
            "+3 SD": 33.9,
          },
          5: {
            L: -1.2816,
            M: 19.1289,
            S: 0.13559,
            "-3 SD": 13.8,
            "-2 SD": 15.2,
            "-1 SD": 16.9,
            Median: 19.1,
            "+1 SD": 22.2,
            "+2 SD": 26.7,
            "+3 SD": 34.0,
          },
          6: {
            L: -1.2739,
            M: 19.1931,
            S: 0.1358,
            "-3 SD": 13.8,
            "-2 SD": 15.2,
            "-1 SD": 16.9,
            Median: 19.2,
            "+1 SD": 22.3,
            "+2 SD": 26.8,
            "+3 SD": 34.1,
          },
          7: {
            L: -1.2661,
            M: 19.2567,
            S: 0.13601,
            "-3 SD": 13.9,
            "-2 SD": 15.2,
            "-1 SD": 17.0,
            Median: 19.3,
            "+1 SD": 22.4,
            "+2 SD": 26.9,
            "+3 SD": 34.2,
          },
          8: {
            L: -1.2583,
            M: 19.3197,
            S: 0.13622,
            "-3 SD": 13.9,
            "-2 SD": 15.3,
            "-1 SD": 17.0,
            Median: 19.3,
            "+1 SD": 22.4,
            "+2 SD": 27.0,
            "+3 SD": 34.3,
          },
          9: {
            L: -1.2504,
            M: 19.382,
            S: 0.13642,
            "-3 SD": 13.9,
            "-2 SD": 15.3,
            "-1 SD": 17.1,
            Median: 19.4,
            "+1 SD": 22.5,
            "+2 SD": 27.1,
            "+3 SD": 34.4,
          },
          10: {
            L: -1.2425,
            M: 19.4437,
            S: 0.13662,
            "-3 SD": 14.0,
            "-2 SD": 15.4,
            "-1 SD": 17.1,
            Median: 19.4,
            "+1 SD": 22.6,
            "+2 SD": 27.1,
            "+3 SD": 34.5,
          },
          11: {
            L: -1.2345,
            M: 19.5045,
            S: 0.13681,
            "-3 SD": 14.0,
            "-2 SD": 15.4,
            "-1 SD": 17.2,
            Median: 19.5,
            "+1 SD": 22.7,
            "+2 SD": 27.2,
            "+3 SD": 34.6,
          },
        },
        14: {
          0: {
            L: -1.2266,
            M: 19.5647,
            S: 0.137,
            "-3 SD": 14.0,
            "-2 SD": 15.4,
            "-1 SD": 17.2,
            Median: 19.6,
            "+1 SD": 22.7,
            "+2 SD": 27.3,
            "+3 SD": 34.7,
          },
          1: {
            L: -1.2186,
            M: 19.624,
            S: 0.13719,
            "-3 SD": 14.1,
            "-2 SD": 15.5,
            "-1 SD": 17.3,
            Median: 19.6,
            "+1 SD": 22.8,
            "+2 SD": 27.4,
            "+3 SD": 34.7,
          },
          2: {
            L: -1.2107,
            M: 19.6824,
            S: 0.13738,
            "-3 SD": 14.1,
            "-2 SD": 15.5,
            "-1 SD": 17.3,
            Median: 19.7,
            "+1 SD": 22.9,
            "+2 SD": 27.5,
            "+3 SD": 34.8,
          },
          3: {
            L: -1.2027,
            M: 19.74,
            S: 0.13756,
            "-3 SD": 14.1,
            "-2 SD": 15.6,
            "-1 SD": 17.4,
            Median: 19.7,
            "+1 SD": 22.9,
            "+2 SD": 27.6,
            "+3 SD": 34.9,
          },
          4: {
            L: -1.1947,
            M: 19.7966,
            S: 0.13774,
            "-3 SD": 14.1,
            "-2 SD": 15.6,
            "-1 SD": 17.4,
            Median: 19.8,
            "+1 SD": 23.0,
            "+2 SD": 27.7,
            "+3 SD": 35.0,
          },
          5: {
            L: -1.1867,
            M: 19.8523,
            S: 0.13791,
            "-3 SD": 14.2,
            "-2 SD": 15.6,
            "-1 SD": 17.5,
            Median: 19.9,
            "+1 SD": 23.1,
            "+2 SD": 27.7,
            "+3 SD": 35.1,
          },
          6: {
            L: -1.1788,
            M: 19.907,
            S: 0.13808,
            "-3 SD": 14.2,
            "-2 SD": 15.7,
            "-1 SD": 17.5,
            Median: 19.9,
            "+1 SD": 23.1,
            "+2 SD": 27.8,
            "+3 SD": 35.1,
          },
          7: {
            L: -1.1708,
            M: 19.9607,
            S: 0.13825,
            "-3 SD": 14.2,
            "-2 SD": 15.7,
            "-1 SD": 17.6,
            Median: 20.0,
            "+1 SD": 23.2,
            "+2 SD": 27.9,
            "+3 SD": 35.2,
          },
          8: {
            L: -1.1629,
            M: 20.0133,
            S: 0.13841,
            "-3 SD": 14.3,
            "-2 SD": 15.7,
            "-1 SD": 17.6,
            Median: 20.0,
            "+1 SD": 23.3,
            "+2 SD": 28.0,
            "+3 SD": 35.3,
          },
          9: {
            L: -1.1549,
            M: 20.0648,
            S: 0.13858,
            "-3 SD": 14.3,
            "-2 SD": 15.8,
            "-1 SD": 17.6,
            Median: 20.1,
            "+1 SD": 23.3,
            "+2 SD": 28.0,
            "+3 SD": 35.4,
          },
          10: {
            L: -1.147,
            M: 20.1152,
            S: 0.13873,
            "-3 SD": 14.3,
            "-2 SD": 15.8,
            "-1 SD": 17.7,
            Median: 20.1,
            "+1 SD": 23.4,
            "+2 SD": 28.1,
            "+3 SD": 35.4,
          },
          11: {
            L: -1.139,
            M: 20.1644,
            S: 0.13889,
            "-3 SD": 14.3,
            "-2 SD": 15.8,
            "-1 SD": 17.7,
            Median: 20.2,
            "+1 SD": 23.5,
            "+2 SD": 28.2,
            "+3 SD": 35.5,
          },
        },
        15: {
          0: {
            L: -1.1311,
            M: 20.2125,
            S: 0.13904,
            "-3 SD": 14.4,
            "-2 SD": 15.9,
            "-1 SD": 17.8,
            Median: 20.2,
            "+1 SD": 23.5,
            "+2 SD": 28.2,
            "+3 SD": 35.5,
          },
          1: {
            L: -1.1232,
            M: 20.2595,
            S: 0.1392,
            "-3 SD": 14.4,
            "-2 SD": 15.9,
            "-1 SD": 17.8,
            Median: 20.3,
            "+1 SD": 23.6,
            "+2 SD": 28.3,
            "+3 SD": 35.6,
          },
          2: {
            L: -1.1153,
            M: 20.3053,
            S: 0.13934,
            "-3 SD": 14.4,
            "-2 SD": 15.9,
            "-1 SD": 17.8,
            Median: 20.3,
            "+1 SD": 23.6,
            "+2 SD": 28.4,
            "+3 SD": 35.7,
          },
          3: {
            L: -1.1074,
            M: 20.3499,
            S: 0.13949,
            "-3 SD": 14.4,
            "-2 SD": 16.0,
            "-1 SD": 17.9,
            Median: 20.4,
            "+1 SD": 23.7,
            "+2 SD": 28.4,
            "+3 SD": 35.7,
          },
          4: {
            L: -1.0996,
            M: 20.3934,
            S: 0.13963,
            "-3 SD": 14.5,
            "-2 SD": 16.0,
            "-1 SD": 17.9,
            Median: 20.4,
            "+1 SD": 23.7,
            "+2 SD": 28.5,
            "+3 SD": 35.8,
          },
          5: {
            L: -1.0917,
            M: 20.4357,
            S: 0.13977,
            "-3 SD": 14.5,
            "-2 SD": 16.0,
            "-1 SD": 17.9,
            Median: 20.4,
            "+1 SD": 23.8,
            "+2 SD": 28.5,
            "+3 SD": 35.8,
          },
          6: {
            L: -1.0838,
            M: 20.4769,
            S: 0.13991,
            "-3 SD": 14.5,
            "-2 SD": 16.0,
            "-1 SD": 18.0,
            Median: 20.5,
            "+1 SD": 23.8,
            "+2 SD": 28.6,
            "+3 SD": 35.8,
          },
          7: {
            L: -1.076,
            M: 20.517,
            S: 0.14005,
            "-3 SD": 14.5,
            "-2 SD": 16.1,
            "-1 SD": 18.0,
            Median: 20.5,
            "+1 SD": 23.9,
            "+2 SD": 28.6,
            "+3 SD": 35.9,
          },
          8: {
            L: -1.0681,
            M: 20.556,
            S: 0.14018,
            "-3 SD": 14.5,
            "-2 SD": 16.1,
            "-1 SD": 18.0,
            Median: 20.6,
            "+1 SD": 23.9,
            "+2 SD": 28.7,
            "+3 SD": 35.9,
          },
          9: {
            L: -1.0603,
            M: 20.5938,
            S: 0.14031,
            "-3 SD": 14.5,
            "-2 SD": 16.1,
            "-1 SD": 18.1,
            Median: 20.6,
            "+1 SD": 24.0,
            "+2 SD": 28.7,
            "+3 SD": 36.0,
          },
          10: {
            L: -1.0525,
            M: 20.6306,
            S: 0.14044,
            "-3 SD": 14.6,
            "-2 SD": 16.1,
            "-1 SD": 18.1,
            Median: 20.6,
            "+1 SD": 24.0,
            "+2 SD": 28.8,
            "+3 SD": 36.0,
          },
          11: {
            L: -1.0447,
            M: 20.6663,
            S: 0.14057,
            "-3 SD": 14.6,
            "-2 SD": 16.2,
            "-1 SD": 18.1,
            Median: 20.7,
            "+1 SD": 24.1,
            "+2 SD": 28.8,
            "+3 SD": 36.0,
          },
        },
        16: {
          0: {
            L: -1.0368,
            M: 20.7008,
            S: 0.1407,
            "-3 SD": 14.6,
            "-2 SD": 16.2,
            "-1 SD": 18.2,
            Median: 20.7,
            "+1 SD": 24.1,
            "+2 SD": 28.9,
            "+3 SD": 36.1,
          },
          1: {
            L: -1.029,
            M: 20.7344,
            S: 0.14082,
            "-3 SD": 14.6,
            "-2 SD": 16.2,
            "-1 SD": 18.2,
            Median: 20.7,
            "+1 SD": 24.1,
            "+2 SD": 28.9,
            "+3 SD": 36.1,
          },
          2: {
            L: -1.0212,
            M: 20.7668,
            S: 0.14094,
            "-3 SD": 14.6,
            "-2 SD": 16.2,
            "-1 SD": 18.2,
            Median: 20.8,
            "+1 SD": 24.2,
            "+2 SD": 29.0,
            "+3 SD": 36.1,
          },
          3: {
            L: -1.0134,
            M: 20.7982,
            S: 0.14106,
            "-3 SD": 14.6,
            "-2 SD": 16.2,
            "-1 SD": 18.2,
            Median: 20.8,
            "+1 SD": 24.2,
            "+2 SD": 29.0,
            "+3 SD": 36.1,
          },
          4: {
            L: -1.0055,
            M: 20.8286,
            S: 0.14118,
            "-3 SD": 14.6,
            "-2 SD": 16.2,
            "-1 SD": 18.3,
            Median: 20.8,
            "+1 SD": 24.3,
            "+2 SD": 29.0,
            "+3 SD": 36.2,
          },
          5: {
            L: -0.9977,
            M: 20.858,
            S: 0.1413,
            "-3 SD": 14.6,
            "-2 SD": 16.3,
            "-1 SD": 18.3,
            Median: 20.9,
            "+1 SD": 24.3,
            "+2 SD": 29.1,
            "+3 SD": 36.2,
          },
          6: {
            L: -0.9898,
            M: 20.8863,
            S: 0.14142,
            "-3 SD": 14.7,
            "-2 SD": 16.3,
            "-1 SD": 18.3,
            Median: 20.9,
            "+1 SD": 24.3,
            "+2 SD": 29.1,
            "+3 SD": 36.2,
          },
          7: {
            L: -0.9819,
            M: 20.9137,
            S: 0.14153,
            "-3 SD": 14.7,
            "-2 SD": 16.3,
            "-1 SD": 18.3,
            Median: 20.9,
            "+1 SD": 24.4,
            "+2 SD": 29.1,
            "+3 SD": 36.2,
          },
          8: {
            L: -0.974,
            M: 20.9401,
            S: 0.14164,
            "-3 SD": 14.7,
            "-2 SD": 16.3,
            "-1 SD": 18.3,
            Median: 20.9,
            "+1 SD": 24.4,
            "+2 SD": 29.2,
            "+3 SD": 36.2,
          },
          9: {
            L: -0.9661,
            M: 20.9656,
            S: 0.14176,
            "-3 SD": 14.7,
            "-2 SD": 16.3,
            "-1 SD": 18.4,
            Median: 21.0,
            "+1 SD": 24.4,
            "+2 SD": 29.2,
            "+3 SD": 36.3,
          },
          10: {
            L: -0.9582,
            M: 20.9901,
            S: 0.14187,
            "-3 SD": 14.7,
            "-2 SD": 16.3,
            "-1 SD": 18.4,
            Median: 21.0,
            "+1 SD": 24.4,
            "+2 SD": 29.2,
            "+3 SD": 36.3,
          },
          11: {
            L: -0.9503,
            M: 21.0138,
            S: 0.14198,
            "-3 SD": 14.7,
            "-2 SD": 16.3,
            "-1 SD": 18.4,
            Median: 21.0,
            "+1 SD": 24.5,
            "+2 SD": 29.3,
            "+3 SD": 36.3,
          },
        },
        17: {
          0: {
            L: -0.9423,
            M: 21.0367,
            S: 0.14208,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.4,
            Median: 21.0,
            "+1 SD": 24.5,
            "+2 SD": 29.3,
            "+3 SD": 36.3,
          },
          1: {
            L: -0.9344,
            M: 21.0587,
            S: 0.14219,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.4,
            Median: 21.1,
            "+1 SD": 24.5,
            "+2 SD": 29.3,
            "+3 SD": 36.3,
          },
          2: {
            L: -0.9264,
            M: 21.0801,
            S: 0.1423,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.4,
            Median: 21.1,
            "+1 SD": 24.6,
            "+2 SD": 29.3,
            "+3 SD": 36.3,
          },
          3: {
            L: -0.9184,
            M: 21.1007,
            S: 0.1424,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.5,
            Median: 21.1,
            "+1 SD": 24.6,
            "+2 SD": 29.4,
            "+3 SD": 36.3,
          },
          4: {
            L: -0.9104,
            M: 21.1206,
            S: 0.1425,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.5,
            Median: 21.1,
            "+1 SD": 24.6,
            "+2 SD": 29.4,
            "+3 SD": 36.3,
          },
          5: {
            L: -0.9024,
            M: 21.1399,
            S: 0.14261,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.5,
            Median: 21.1,
            "+1 SD": 24.6,
            "+2 SD": 29.4,
            "+3 SD": 36.3,
          },
          6: {
            L: -0.8944,
            M: 21.1586,
            S: 0.14271,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.5,
            Median: 21.2,
            "+1 SD": 24.6,
            "+2 SD": 29.4,
            "+3 SD": 36.3,
          },
          7: {
            L: -0.8863,
            M: 21.1768,
            S: 0.14281,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.5,
            Median: 21.2,
            "+1 SD": 24.7,
            "+2 SD": 29.4,
            "+3 SD": 36.3,
          },
          8: {
            L: -0.8783,
            M: 21.1944,
            S: 0.14291,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.5,
            Median: 21.2,
            "+1 SD": 24.7,
            "+2 SD": 29.5,
            "+3 SD": 36.3,
          },
          9: {
            L: -0.8703,
            M: 21.2116,
            S: 0.14301,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.5,
            Median: 21.2,
            "+1 SD": 24.7,
            "+2 SD": 29.5,
            "+3 SD": 36.3,
          },
          10: {
            L: -0.8623,
            M: 21.2282,
            S: 0.14311,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.5,
            Median: 21.2,
            "+1 SD": 24.7,
            "+2 SD": 29.5,
            "+3 SD": 36.3,
          },
          11: {
            L: -0.8542,
            M: 21.2444,
            S: 0.1432,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.6,
            Median: 21.2,
            "+1 SD": 24.8,
            "+2 SD": 29.5,
            "+3 SD": 36.3,
          },
        },
        18: {
          0: {
            L: -0.8462,
            M: 21.2603,
            S: 0.1433,
            "-3 SD": 14.7,
            "-2 SD": 16.4,
            "-1 SD": 18.6,
            Median: 21.3,
            "+1 SD": 24.8,
            "+2 SD": 29.5,
            "+3 SD": 36.3,
          },
          1: {
            L: -0.8382,
            M: 21.2757,
            S: 0.1434,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.6,
            Median: 21.3,
            "+1 SD": 24.8,
            "+2 SD": 29.5,
            "+3 SD": 36.3,
          },
          2: {
            L: -0.8301,
            M: 21.2908,
            S: 0.14349,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.6,
            Median: 21.3,
            "+1 SD": 24.8,
            "+2 SD": 29.6,
            "+3 SD": 36.3,
          },
          3: {
            L: -0.8221,
            M: 21.3055,
            S: 0.14359,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.6,
            Median: 21.3,
            "+1 SD": 24.8,
            "+2 SD": 29.6,
            "+3 SD": 36.3,
          },
          4: {
            L: -0.814,
            M: 21.32,
            S: 0.14368,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.6,
            Median: 21.3,
            "+1 SD": 24.8,
            "+2 SD": 29.6,
            "+3 SD": 36.3,
          },
          5: {
            L: -0.806,
            M: 21.3341,
            S: 0.14377,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.6,
            Median: 21.3,
            "+1 SD": 24.9,
            "+2 SD": 29.6,
            "+3 SD": 36.2,
          },
          6: {
            L: -0.798,
            M: 21.348,
            S: 0.14386,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.6,
            Median: 21.3,
            "+1 SD": 24.9,
            "+2 SD": 29.6,
            "+3 SD": 36.2,
          },
          7: {
            L: -0.7899,
            M: 21.3617,
            S: 0.14396,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.6,
            Median: 21.4,
            "+1 SD": 24.9,
            "+2 SD": 29.6,
            "+3 SD": 36.2,
          },
          8: {
            L: -0.7819,
            M: 21.3752,
            S: 0.14405,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.6,
            Median: 21.4,
            "+1 SD": 24.9,
            "+2 SD": 29.6,
            "+3 SD": 36.2,
          },
          9: {
            L: -0.7738,
            M: 21.3884,
            S: 0.14414,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.7,
            Median: 21.4,
            "+1 SD": 24.9,
            "+2 SD": 29.6,
            "+3 SD": 36.2,
          },
          10: {
            L: -0.7658,
            M: 21.4014,
            S: 0.14423,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.7,
            Median: 21.4,
            "+1 SD": 24.9,
            "+2 SD": 29.6,
            "+3 SD": 36.2,
          },
          11: {
            L: -0.7577,
            M: 21.4143,
            S: 0.14432,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.7,
            Median: 21.4,
            "+1 SD": 25.0,
            "+2 SD": 29.7,
            "+3 SD": 36.2,
          },
        },
        19: {
          0: {
            L: -0.7496,
            M: 21.4269,
            S: 0.14441,
            "-3 SD": 14.7,
            "-2 SD": 16.5,
            "-1 SD": 18.7,
            Median: 21.4,
            "+1 SD": 25.0,
            "+2 SD": 29.7,
            "+3 SD": 36.2,
          },
        },
      },
    },
  },
  mutations: {},
  actions: {},
  getters: {},
};
