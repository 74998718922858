<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem }">
          <Form :form="editedItem" />
        </template>

        <template #content="{ item }">
          <v-expansion-panels class="rounded-lg pa-3">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Klik untuk melihat
                <template v-slot:actions>
                  <v-icon> mdi-text-search </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span class="text-wrap" v-html="item.content"></span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>

        <template #category_name="{ item }">
          <div class="font-weight-bold">
            <v-chip
              color="red darken-2"
              v-if="item.category_name === 'Gizi Buruk'"
              small
              dark
              >Gizi Buruk (Severely Thinness)</v-chip
            >
            <v-chip
              color="yellow darken-4"
              v-if="item.category_name === 'Gizi Kurang'"
              small
              dark
              >Gizi Kurang (Thinness)</v-chip
            >
            <v-chip
              color="green darken-2"
              v-if="item.category_name === 'Gizi Baik'"
              small
              dark
              >Gizi Baik (Normal)</v-chip
            >
            <v-chip
              color="yellow darken-4"
              v-if="item.category_name === 'Gizi Lebih'"
              small
              dark
              >Gizi Lebih (Overweight)</v-chip
            >
            <v-chip
              color="red darken-2"
              v-if="item.category_name === 'Obesitas'"
              small
              dark
              >Obesitas (Obese)</v-chip
            >
          </div>
        </template>

        <template #actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.sync == 'presync'"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                small
                class="mr-2"
              >
                mdi-clock-time-four-outline
              </v-icon>
            </template>
            <span>Belum Sinkron Server</span>
          </v-tooltip>
          <v-icon color="success" small class="mr-2" @click="notify(item)">
            mdi-bell
          </v-icon>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import Form from "./Form";

export default {
  components: {
    DataTable,
    Form,
  },
  data() {
    return {
      datatable: {
        moduleName: "Rekomendasi",
        title: "Daftar Rekomendasi",
        headers: [
          { text: "No", value: "num", sortable: false },
          { text: "Kategori Status Gizi", value: "category_name", slot: true },
          { text: "Isi Konten", value: "content", slot: true },
          { text: "Tipe", value: "type" },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ],
      },
    };
  },
};
</script>
