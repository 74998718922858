<template>
  <div ref="chartContainer" class="chart mt-5"></div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";
import _ from "lodash";
//eslint-disable-next-line
import { calculateAge, calculateBMI } from "@/helpers/bmiUtils";
export default {
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      chartInstance: null,
      bmi: null,
      result: null,
    };
  },
  computed: {
    ...mapState("bmi", ["bmiData"]),
  },

  /**
   * Mounts the component and initializes the BMI chart.
   *
   * @return {void}
   */
  mounted() {
    this.bmi = this.data;

    setTimeout(() => {
      this.chartInstance = echarts.init(this.$refs.chartContainer);
      if (_.isArray(this.bmi) === false) {
        this.result = calculateBMI(
          this.bmi.weight,
          this.bmi.height,
          this.bmi.student.date_of_birth
        );
      }
      this.drawChart(this.data);
    }, 200);
  },

  methods: {
    /**
     * Clears and disposes the chart instance.
     *
     * @return {void}
     */
    clearAndDisposeChart() {
      this.chartInstance.clear();
      this.chartInstance.dispose();
    },
    /**
     * Draws a chart based on the provided item.
     *
     * @param {Object} item - The item used to generate the chart.
     * @return {null} Returns null if the chart instance is not defined.
     */
    drawChart(item) {
      if (!this.chartInstance) return null;
      let data;
      if (_.isArray(item)) {
        data = item[0];
      } else {
        data = item;
      }
      const sexCategory =
        data.student.sex === "LAKI-LAKI" ? "laki laki" : "perempuan";
      const ageInfo = calculateAge(
        data.student.date_of_birth,
        data.measurement_date
      );
      const age = ageInfo.ageYears * 12 + ageInfo.ageMonths;
      const dat = this.bmiData[sexCategory];
      const option = {
        color: [
          "#000000",
          "#FF0000",
          "#FFFF00",
          "#00FF00",
          "#FFFF00",
          "#FF0000",
          "#000000",
        ],
        xAxis: {
          type: "category",
          name: "Umur",
          data: _.isArray(item)
            ? Array.from({ length: 72 }, (_, index) => index + 61)
            : Array.from({ length: 168 }, (_, index) => index + 61),

          axisLabel: {
            formatter: "{value} Bulan",
          },
        },
        graphic: [
          {
            type: "group",
            left: "13%",
            top: "0%",
            children: [
              {
                type: "rect",
                z: 100,
                left: "center",
                top: "middle",
                shape: {
                  width: 240,
                  height: 50,
                },
                style: {
                  fill: "#fff",
                  stroke: "#555",
                  lineWidth: 1,
                  shadowBlur: 8,
                  shadowOffsetX: 3,
                  shadowOffsetY: 3,
                  shadowColor: "rgba(0,0,0,0.2)",
                },
              },
              {
                type: "text",
                z: 100,
                left: "center",
                top: "middle",
                style: {
                  fill: "#333",
                  width: 230,
                  overflow: "break",
                  text:
                    (_.isArray(item) ? "Pengukuran Terakhir " : "") +
                    `Umur : ${ageInfo.ageYears} tahun ${
                      age % 12
                    } bulan (${age} bulan) , z-score: ${data.z_score}`,
                  font: "14px Microsoft YaHei",
                  fontSize: "14px",
                  fontWeight: "bold",
                },
              },
            ],
          },
        ],
        tooltip: {
          axisPointer: {
            type: "cross",
          },
          formatter: function (params) {
            if (params.seriesName === "IMT/U") {
              return `${params.seriesName} <br> ${params.marker}${params.name} bulan : ${params.value[1]}`;
            } else {
              return `${params.seriesName} <br> ${params.marker}${params.name} bulan : ${params.value}`;
            }
          },
        },
        yAxis: {
          min: 10,
        },
        series: [
          {
            name: "-3 SD",
            type: "line",
            data: this.createDataArray(dat, "-3 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "-3 SD";
              },
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "-2 SD",
            type: "line",
            data: this.createDataArray(dat, "-2 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "-2 SD";
              },
              fontSize: 15,
              color: "red",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "-1 SD",
            type: "line",
            data: this.createDataArray(dat, "-1 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "-1 SD";
              },
              fontSize: 15,
              color: "#b09602",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "Median",
            type: "line",
            data: this.createDataArray(dat, "Median"),
            endLabel: {
              show: true,
              formatter: function () {
                return "Median";
              },
              fontSize: 15,
              color: "green",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "+1 SD",
            type: "line",
            data: this.createDataArray(dat, "+1 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "+1 SD";
              },
              fontSize: 15,
              color: "#b09602",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "+2 SD",
            type: "line",
            data: this.createDataArray(dat, "+2 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "+2 SD";
              },
              fontSize: 15,
              color: "red",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "+3 SD",
            type: "line",
            data: this.createDataArray(dat, "+3 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "+3 SD";
              },
              fontSize: 15,
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "BMI Line",
            type: "line",
            symbol: "none",
            data: _.isArray(item)
              ? item.map((items) => {
                  const ageInfo = calculateAge(
                    items.student.date_of_birth,
                    items.measurement_date
                  );
                  const ages = ageInfo.ageYears * 12 + ageInfo.ageMonths;
                  const result = calculateBMI(
                    items.weight,
                    items.height,
                    items.student.date_of_birth
                  );
                  return [ages - 61, result];
                })
              : [],
            lineStyle: {
              color: "blue",
              width: 2,
              type: "solid",
            },
            z: 110,
          },
          {
            name: "IMT/U",
            type: "scatter",
            data: _.isArray(item)
              ? item.map((items) => {
                  const ageInfo = calculateAge(
                    items.student.date_of_birth,
                    items.measurement_date
                  );
                  const ages = ageInfo.ageYears * 12 + ageInfo.ageMonths;
                  const result = calculateBMI(
                    items.weight,
                    items.height,
                    items.student.date_of_birth
                  );
                  return [ages - 61, result];
                })
              : [[age - 61, this.result]],
            symbolSize: 10,
            label: {
              show: _.isArray(item) ? false : true,
              formatter: function (params) {
                return parseFloat(params.value[1]).toFixed(1);
              },
              position: "top",
              fontWeight: "bold",
            },
            itemStyle: {
              color: _.isArray(item) ? "grey" : "blue",
              borderColor: "blue",
              borderWidth: 2,
            },
            z: 110,
          },
        ],
      };
      this.chartInstance.showLoading();

      this.chartInstance.setOption(option);
      setTimeout(() => {
        this.chartInstance.hideLoading();
      }, 500);
      if (this.chartInstance !== null) {
        window.addEventListener("resize", () => {
          this.chartInstance.resize();
        });
      }
    },
    /**
     * Creates an array of data based on the given BMI data and key data.
     *
     * @param {object} bmiData - The BMI data object.
     * @param {string} keyData - The key data string.
     * @return {array} The array of data.
     */
    createDataArray(bmiData, keyData) {
      const dataArray = [];
      for (let age = 5; age <= 19; age++) {
        if (bmiData[age]) {
          for (const key in bmiData[age]) {
            dataArray.push(bmiData[age][key][keyData]);
          }
        }
      }

      return dataArray;
    },
  },
};
</script>

<style>
.chart {
  width: 100%;
  height: 500px;
}

@media only screen and (max-width: 600px) {
  .chart {
    width: 500px;
    height: 300px;
  }
}
</style>
