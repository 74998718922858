<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          clearable
          v-model="form.code"
          autofocus
          placeholder="Masukan kode puskesmas.."
          :rules="rules.codeRules"
          label="Kode Puskesmas"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          clearable
          v-model="form.name"
          placeholder="Masukan nama puskesmas.."
          :rules="rules.nameRules"
          label="Nama Puskesmas"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          clearable
          v-model="form.address"
          placeholder="Masukan alamat puskesmas.."
          :rules="rules.addressRules"
          label="Alamat"
          prepend-inner-icon="mdi-map-marker-outline"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          clearable
          v-model="form.district_id"
          :items="districts"
          :rules="rules.districtRules"
          item-text="name"
          item-value="id"
          label="Pilih Kecamatan"
          @change="populateVillageDropdown(form.district_id)"
          placeholder="Pilih kecamatan"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          clearable
          v-model="form.village_id"
          :rules="rules.villageRules"
          :items="villages"
          item-text="name"
          item-value="id"
          label="Pilih Kelurahan/Desa"
          placeholder="Pilih kelurahan/desa"
          hint="Jika kolom kelurahan/desa kosong pilih kecamatan terlebih dahulu"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: ["form"],
  data() {
    return {
      districts: [],
      villages: [],
      rules: {
        codeRules: [
          (v) => !!v || "Kolom kode puskesmas tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        nameRules: [
          (v) => !!v || "Kolom nama puskesmas tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        addressRules: [
          (v) => !!v || "Kolom alamat tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        districtRules: [(v) => !!v || "Kolom kecamatan tidak boleh kosong!"],
        villageRules: [(v) => !!v || "Kolom kelurahan tidak boleh kosong!"],
      },
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  watch: {
    "form.district_id": {
      immediate: true,
      handler() {
        if (this.form.district_id) {
          this.populateVillageDropdown(this.form.district_id);
        }
      },
    },
  },
  methods: {
    /**
     * Populates the village dropdown based on the given district ID.
     *
     * @param {number} districtID - The ID of the district.
     * @return {void} This function does not return a value.
     */
    populateVillageDropdown(districtID) {
      this.$http("kelurahan-desa", {
        params: {
          district_id: districtID,
        },
      })
        .then((res) => {
          this.villages = res.data.data.map(({ id, name }) => ({
            id: parseInt(id),
            name,
          }));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
  created() {
    this.$http("kecamatan")
      .then((res) => {
        this.districts = res.data.data.map(({ id, name }) => ({
          id: parseInt(id),
          name,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
};
</script>
