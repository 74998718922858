<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-text-field
          v-model="form.name"
          autofocus
          placeholder="Masukan judul kategori.."
          label="Judul Kategori"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field
          v-model="form.slug"
          placeholder="Masukan slug kategori (opsional).."
          label="Slug Kategori"
          persistent-hint
          disabled
          hint="Slug akan otomatis mengikuti dari judul kategori"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { toSlug } from "../../helpers/strings";

export default {
  props: ["form"],
  components: {
    // VFileInput
  },
  data() {
    return {};
  },
  watch: {
    "form.name": function (val) {
      this.form.slug = val ? toSlug(val) : "";
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>
