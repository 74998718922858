<template>
  <v-container>
    <v-row align="center" justify="center" class="mt-10">
      <v-col cols="12" sm="7" md="6">
        <v-card class="elevation-6" style="height: 650px">
          <v-card-text>
            <router-link to="/">
              <div class="d-flex justify-center">
                <v-avatar size="125">
                  <img src="@/assets/logo_gizi.jpeg" alt="John" />
                </v-avatar>
              </div>
            </router-link>
            <h4 class="text-center mt-3">Login in to Your Account</h4>
            <h6 class="text-center grey--text">
              Log in to your account so you can continue builiding
              <br />and editing your onboarding flows
            </h6>

            <v-row align="center" justify="center" class="mt-10">
              <v-col cols="12" sm="8" md="8">
                <v-alert
                  v-if="error"
                  dense
                  color="red lighten-2"
                  type="error"
                  :class="{ 'mt-1': error }"
                  dark
                >
                  {{ error }}
                </v-alert>
                <v-text-field
                  label="Email"
                  v-model="form.email"
                  outlined
                  dense
                  color="blue"
                  autocomplete="false"
                  :class="{ 'mt-10': !error }"
                />
                <v-text-field
                  label="Password"
                  v-model="form.password"
                  @keyup.enter="login(form)"
                  outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[(v) => !!v || 'Item is required']"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  dense
                  color="blue"
                  autocomplete="false"
                />
                <v-row>
                  <v-col cols="12" sm="8" md="8">
                    <v-checkbox
                      label="Remember Me"
                      class="mt-n1"
                      color="blue"
                    ></v-checkbox>
                  </v-col>
                  <!-- <v-col cols="12" sm="5">
                    <span class="caption blue--text">Forgot password</span>
                  </v-col> -->
                </v-row>
                <v-btn
                  color="blue"
                  dark
                  block
                  tile
                  :loading="loading"
                  class="mt-10"
                  @click="login(form)"
                  >Log in</v-btn
                >

                <!-- <h5
                          class="text-center  grey--text mt-4 mb-3"
                        >Or Log in using</h5> -->
                <!-- <div class="d-flex  justify-space-between align-center mx-10 mb-16">
                        <v-btn depressed outlined color="grey">
                          <v-icon color="red">fab fa-google</v-icon>
                        </v-btn>
                        <v-btn depressed outlined color="grey">
                          <v-icon color="blue">fab fa-facebook-f</v-icon>
                        </v-btn>
                        <v-btn depressed outlined color="grey">
                          <v-icon color="light-blue lighten-3">fab fa-twitter</v-icon>
                        </v-btn>
                         </div> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    step: 1,
    showPassword: false,
    form: {
      email: "admin@gmail.com",
      password: "admin123",
      login: 1,
    },
  }),
  props: {
    source: String,
  },
  methods: {
    ...mapActions("auth", ["login"]),
    register() {
      this.form.login = null;
      this.form.register = true;
      this.login(this.form);
    },
  },
  computed: {
    ...mapState("auth", ["error", "loading"]),
  },
  mounted() {
    document.title = "Login";
  },
};
</script>
<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
