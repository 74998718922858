<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #thumbnail="{ item }">
          <v-card class="px-3 py-3" max-width="200">
            <ImagePreview :src="item.thumbnail_link"></ImagePreview>
          </v-card>
        </template>

        <template #date_posted="{ item }">
          {{
            new Date(item.date_posted).toLocaleDateString("id-ID", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
          }}
        </template>

        <template #is_published="{ item }">
          <v-chip
            class="ma-2"
            :color="`${
              item.is_published ? 'green' : 'secondary lighten-2'
            } font-weight-bold`"
            small
            dark
          >
            {{ item.is_published ? "Published" : "Draft" }}
          </v-chip>
        </template>

        <template #actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.sync == 'presync'"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                small
                class="mr-2"
              >
                mdi-clock-time-four-outline
              </v-icon>
            </template>
            <span>Belum Sinkron Server</span>
          </v-tooltip>
          <v-icon color="success" small class="mr-2" @click="notify(item)">
            mdi-bell
          </v-icon>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";
import ImagePreview from "../../components/ImagePreview.vue";
export default {
  components: {
    DataTable,
    ModulForm,
    ImagePreview,
  },
  data() {
    return {
      datatable: {
        btnAdd: "/blog/tambah",
        btnEdit: "/blog/edit",
        moduleName: "Blog",
        title: "Daftar Blog",
        formData: true,
        headers: [
          { text: "No", value: "num", sortable: false },
          { text: "Judul", value: "title" },
          { text: "Thumbnail", value: "thumbnail", slot: true },
          { text: "Tanggal Publikasi", value: "date_posted", slot: true },
          { text: "Status Publikasi", value: "is_published", slot: true },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ],
        params: {
          sortby: "date_posted",
          sortbydesc: "asc",
        },
      },
    };
  },
};
</script>
