var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"2"}},[_c('DataTable',{attrs:{"moduleName":_vm.moduleName,"headers":_vm.headers,"slots":_vm.slots,"formData":true},on:{"saved":_vm.saved},scopedSlots:_vm._u([{key:"modal-form",fn:function(ref){
var editedItem = ref.editedItem;
return [_c('ModulForm',{attrs:{"form":editedItem}})]}},{key:"avatar",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img-preview',{attrs:{"width":"50","src":item.avatar_link
                ? item.avatar_link
                : require('../../assets/persons/person 3.jpg'),"alt":"Fotomu"}})],1)]}},{key:"is_active",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":((item.is_active === 1 ? "green lighten-2" : "red") + " font-weight-bold"),"small":"","dark":""}},[_vm._v(" "+_vm._s(item.is_active === 1 ? "Aktif" : "Tidak Aktif")+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }