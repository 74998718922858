<template>
  <v-card elevation="2" class="rounded-xl">
    <v-card-title>Edit Blog</v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle>Menu untuk mengubah blog</v-card-subtitle>

    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              clearable
              v-model="form.title"
              autofocus
              placeholder="Masukan judul blog.."
              label="Judul Blog"
              :rules="rules.titleRules"
              prepend-inner-icon="mdi-format-title"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-text-field
              clearable
              v-model="form.slug"
              placeholder="Masukan slug blog.."
              label="Slug Blog"
              disabled
              hint="Slug akan otomatis mengikuti dari judul blog"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              clearable
              v-model="form.description"
              placeholder="Masukan deskripsi blog.."
              label="Deskripsi"
              :rules="rules.descriptionRules"
              prepend-inner-icon="mdi-text-short"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              clearable
              v-model="form.category_id"
              label="Pilih Kategori"
              :items="categories"
              item-text="name"
              item-value="id"
              :rules="rules.categoryIDRules"
              prepend-inner-icon="mdi-tag-outline"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-switch v-model="form.is_published">
              <template v-slot:label>
                Status Publikasi:
                <v-chip
                  class="ma-2"
                  :color="`${
                    form.is_published ? 'green' : 'secondary lighten-2'
                  } font-weight-bold`"
                  small
                  dark
                >
                  {{ form.is_published ? "Published" : "Draft" }}
                </v-chip>
              </template>
            </v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="8">
            <Editor :form="form"></Editor>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-card v-if="imagePreview" class="px-3 py-3">
              <v-img :src="imagePreview"></v-img>
            </v-card>

            <v-file-input
              v-model="form.thumbnail"
              color="deep-purple accent-4"
              counter
              class="pt-4"
              label="Pilih Gambar Thumbnail"
              placeholder="Pilih Gambar Thumbnail"
              prepend-icon="mdi-paperclip"
              outlined
              truncate-length="15"
              :show-size="1000"
              :rules="rules.thumbnailRules"
              @change="previewImage"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip color="deep-purple accent-4" dark label small>
                  {{
                    form.thumbnail.size
                      ? text
                      : truncateMiddle(form.thumbnail, 15)
                  }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>

        <div class="d-flex justify-end">
          <v-btn to="/blog" color="grey darken-1 white--text" class="mr-4"
            >Kembali</v-btn
          >
          <v-btn
            color="blue darken-1 white--text"
            :loading="loading"
            @click="submit"
            class="mr-4"
            >Save</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import Editor from "@/components/FormInput/Editor";
import { mapActions } from "vuex";
import { toSlug } from "../../helpers/strings";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      loading: false,
      imagePreview: null,
      form: {
        title: null,
        slug: null,
        description: null,
        category_id: null,
        is_published: null,
        content: null,
        thumbnail: null,
      },
      categories: [],
      rules: {
        titleRules: [
          (v) => !!v || "Kolom judul blog tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        descriptionRules: [
          (v) => !!v || "Kolom deskripsi tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        categoryIDRules: [(v) => !!v || "Kolom kategori tidak boleh kosong!"],
        contentRules: [
          (v) => !!v || "Kolom konten tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        thumbnailRules: [(v) => !!v || "Kolom thumbnail tidak boleh kosong!"],
      },
    };
  },
  computed: {
    forms() {
      const formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("title", this.form.title);
      formData.append("slug", this.form.slug);
      formData.append("description", this.form.description);
      formData.append("category_id", this.form.category_id);
      formData.append("is_published", this.form.is_published ? 1 : 0);
      formData.append("content", this.form.content);
      if (this.form.thumbnail !== null)
        formData.append("file_thumbnail", this.form.thumbnail);

      return formData;
    },
  },
  methods: {
    ...mapActions("base", ["getData", "saveData", "deleteData"]),
    previewImage(event) {
      const file = event;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.imagePreview = null;
      }
    },
    truncateMiddle(str, maxLength) {
      if (str.length <= maxLength) {
        return str;
      }

      const ellipsis = "...";
      const halfMaxLength = Math.floor((maxLength - ellipsis.length) / 2);

      const firstPart = str.substring(0, halfMaxLength);
      const secondPart = str.substring(str.length - halfMaxLength);

      return firstPart + ellipsis + secondPart;
    },

    async submit() {
      await this.saveData({
        customUrl: "/blog",
        data: this.forms,
      }).then(() => {
        this.$router.push("/blog");
      });
    },
  },
  watch: {
    "form.title": function (val) {
      this.form.slug = val ? toSlug(val) : "";
    },
  },
  mounted() {
    this.$http("kategori", {
      params: {
        all: true,
      },
    }).then((res) => {
      this.categories = res.data.map(({ id, name }) => ({ id, name }));
    });

    this.$http(`blog/${this.$route.params.id}`).then((res) => {
      this.form.id = res.data.id;
      this.imagePreview = res.data.thumbnail_link;
      this.form.title = res.data.title;
      this.form.slug = res.data.slug;
      this.form.description = res.data.description;
      this.form.category_id = res.data.category_id;
      this.form.is_published = res.data.is_published;
      this.form.content = res.data.content;
      this.form.thumbnail = res.data.thumbnail;
    });
  },
};
</script>
