<template>
  <v-container>
    <v-form ref="forms" v-model="valid" lazy-validation>
      <div class="radio-buttons pb-10">
        <label class="radio-button">
          <input type="radio" v-model="form.sex" value="l" />
          <img
            :src="
              form.sex === 'l'
                ? require('@/assets/man.png')
                : require('@/assets/grey-boy.png')
            "
            alt="Man"
            :style="{
              width:
                form.sex === 'l'
                  ? this.$vuetify.breakpoint.mobile
                    ? '75px'
                    : '100px'
                  : this.$vuetify.breakpoint.mobile
                  ? '70px'
                  : '95px',
              height:
                form.sex === 'l'
                  ? this.$vuetify.breakpoint.mobile
                    ? '75px'
                    : '100px'
                  : this.$vuetify.breakpoint.mobile
                  ? '70px'
                  : '95px',
            }"
          />
          <span
            :class="
              form.sex === 'l' ? 'font-weight-black' : 'font-weight-regular'
            "
            >Laki-laki</span
          >
        </label>
        <label class="radio-button">
          <input type="radio" v-model="form.sex" value="p" />
          <img
            v-bind:src="
              form.sex === 'p'
                ? require('@/assets/woman.png')
                : require('@/assets/grey-girl.png')
            "
            alt="Woman"
            :style="{
              width:
                form.sex === 'p'
                  ? this.$vuetify.breakpoint.mobile
                    ? '75px'
                    : '100px'
                  : this.$vuetify.breakpoint.mobile
                  ? '70px'
                  : '95px',
              height:
                form.sex === 'p'
                  ? this.$vuetify.breakpoint.mobile
                    ? '75px'
                    : '100px'
                  : this.$vuetify.breakpoint.mobile
                  ? '70px'
                  : '95px',
            }"
          />
          <span
            :class="
              form.sex === 'p' ? 'font-weight-black' : 'font-weight-regular'
            "
            >Perempuan</span
          >
        </label>
      </div>

      <v-dialog
        ref="formDateOfBirthDialog"
        v-model="formDateOfBirthModal"
        :return-value.sync="form.date_of_birth"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            :rules="rules.dateOfBirthRules"
            v-model="formattedDateOfBirth"
            label="Tanggal Lahir"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="form.date_of_birth" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="formDateOfBirthModal = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.formDateOfBirthDialog.save(form.date_of_birth)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-dialog
        ref="formMeasurementDateDialog"
        v-model="formMeasurementDateModal"
        :return-value.sync="form.measurement_date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            :rules="rules.measurementDateRules"
            v-model="formattedMeasurementDate"
            label="Tanggal Pengukuran"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="form.measurement_date" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="formMeasurementDateModal = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.formMeasurementDateDialog.save(form.measurement_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-text-field
        label="Berat Badan"
        dense
        v-model="form.weight"
        :rules="rules.weightRules"
        @keyup="handleEnterKey($event, 'height')"
        type="number"
        placeholder="Masukan Berat Badan"
        outlined
        suffix="kg"
      ></v-text-field>
      <v-text-field
        ref="height"
        label="Tinggi Badan"
        dense
        v-model="form.height"
        :rules="rules.heightRules"
        @keyup="handleEnterKey($event, null)"
        type="number"
        placeholder="Masukan Tinggi Badan"
        outlined
        suffix="cm"
      ></v-text-field>
    </v-form>

    <v-card-actions>
      <v-btn
        block
        color="primary text-uppercase"
        :class="valid ? '' : 'not-allowed'"
        :loading="loading"
        large
        rounded
        :disabled="!valid || !isFormComplete || loading"
        @click="submit"
        >Hitung z-score</v-btn
      >
    </v-card-actions>
  </v-container>
</template>
<script>
export default {
  props: ["form", "loading"],
  data() {
    return {
      formDateOfBirthModal: false,
      formMeasurementDateModal: false,
      valid: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,
      menu3: false,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      rules: {
        dateOfBirthRules: [
          (value) => !!value || "Kolom tanggal lahir tidak boleh kosong!",
          (value) =>
            this.isBirthDateValid(value) ||
            "Usia harus diantara 5 hingga 19 tahun!",
        ],
        measurementDateRules: [
          (value) => !!value || "Kolom tanggal pengukuran tidak boleh kosong!",
        ],
        heightRules: [(v) => !!v || "Kolom tinggi badan tidak boleh kosong!"],
        weightRules: [(v) => !!v || "Kolom berat badan tidak boleh kosong!"],
      },
      checkbox: false,
    };
  },
  computed: {
    formattedDateOfBirth() {
      return this.form.date_of_birth
        ? new Date(this.form.date_of_birth).toLocaleDateString("id-ID")
        : null;
    },
    formattedMeasurementDate() {
      return this.form.measurement_date
        ? new Date(this.form.measurement_date).toLocaleDateString("id-ID")
        : null;
    },
    isFormComplete() {
      return (
        this.form.sex &&
        this.form.date_of_birth &&
        this.form.weight &&
        this.form.height
      );
    },
  },
  methods: {
    handleEnterKey(event, nextFieldRef) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!nextFieldRef && this.valid && this.isFormComplete) {
          this.submit();
        } else {
          this.$refs[nextFieldRef].focus();
        }
      }
    },
    submit() {
      this.$emit("submit", this.form);
    },
    /**
     * Determines if a birth date is valid.
     *
     * @param {string} date - The birth date in the format 'dd/mm/yyyy'.
     * @return {boolean} Returns `true` if the birth date is valid and the person's age is between 5 and 19 (inclusive), `false` otherwise.
     */
    isBirthDateValid(date) {
      if (!date) return false;

      const [day, month, year] = date.split("/");

      const currentDate = new Date();
      const birthDate = new Date(`${year}-${month}-${day}`);

      let age = currentDate.getFullYear() - birthDate.getFullYear();

      const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-based
      const birthMonth = parseInt(month);
      const currentDay = currentDate.getDate();
      const birthDay = parseInt(day);

      // Check if the current month and day have passed the birth month and day
      if (
        currentMonth < birthMonth ||
        (currentMonth === birthMonth && currentDay < birthDay)
      ) {
        age--;
      }

      return age >= 5 && age <= 19;
    },
  },
};
</script>

<style scoped>
.radio-buttons {
  display: flex;
  justify-content: center;
}

.radio-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  padding-top: 10px;
}

.radio-button img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  transition: border-color 0.3s ease;
  padding-top: 5px;
}

.not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.radio-button input[type="radio"] {
  display: none;
}

.radio-button input[type="radio"]:checked + img {
  box-shadow: 0 0 10px #4d4d4d; /* Add the shadow effect */
}
</style>
