/**
 * Convert string to slug.
 * @param {String} str - The string that wants to be converted to slug.
 * @return {String} The converted slug from string provided on parameter.
 */
export function toSlug(str) {
  // remove special characters and lowercase
  const cleanedString = str.replace(/[^\w\s]/gi, "").toLowerCase();

  // replace spaces with hyphens
  return cleanedString.replace(/\s+/g, "-");
}
