<template>
  <div>
    <v-dialog
      ref="formMeasurementDateDialog"
      v-model="formMeasurementDateModal"
      :return-value.sync="form.measurement_date"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          dense
          :rules="rules.measurementDateRules"
          v-model="formattedMeasurementDate"
          label="Tanggal Pengukuran"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="form.measurement_date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="formMeasurementDateModal = false">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.formMeasurementDateDialog.save(form.measurement_date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-text-field
      label="Berat Badan"
      v-model="form.weight"
      type="number"
      :rules="rules.weightRules"
      placeholder="Masukan Berat Badan"
      outlined
      suffix="kg"
      dense
    ></v-text-field>
    <v-text-field
      label="Tinggi Badan"
      dense
      v-model="form.height"
      :rules="rules.heightRules"
      type="number"
      placeholder="Masukan Tinggi Badan"
      outlined
      suffix="cm"
    ></v-text-field>
  </div>
</template>
<script>
import { getZScore, calculateBMI } from "@/helpers/bmiUtils";
import { mapState } from "vuex";
import { getZScoreCategoryName } from "../../helpers/bmiUtils";
export default {
  props: ["form"],
  components: {},
  data() {
    return {
      formMeasurementDateModal: false,
      valid: false,
      rules: {
        measurementDateRules: [
          (v) => !!v || "Kolom tanggal pengukuran tidak boleh kosong!",
        ],
        weightRules: [(v) => !!v || "Kolom berat badan tidak boleh kosong!"],
        heightRules: [(v) => !!v || "Kolom tinggi badan tidak boleh kosong!"],
      },
      student: null,
    };
  },
  computed: {
    ...mapState("bmi", ["bmiData"]),
    formattedMeasurementDate() {
      return this.form.measurement_date
        ? new Date(this.form.measurement_date).toLocaleDateString("id-ID")
        : null;
    },
  },
  watch: {
    "form.measurement_date": {
      handler() {
        this.form.z_score = getZScore(
          calculateBMI(
            this.form.weight,
            this.form.height,
            this.student.date_of_birth
          ),
          this.bmiData,
          this.student.date_of_birth,
          this.form.measurement_date,
          this.student.sex
        );

        this.form.category_name = getZScoreCategoryName(this.form.z_score);
      },
    },
    "form": {
      handler() {
        this.form.student_id = parseInt(this.$route.params.siswaId);
      },
    },
    "form.height": {
      handler() {
        this.form.imt = calculateBMI(
          this.form.weight,
          this.form.height,
          this.student.date_of_birth
        );
        this.form.z_score = getZScore(
          this.form.imt,
          this.bmiData,
          this.student.date_of_birth,
          this.form.measurement_date,
          this.student.sex
        );

        this.form.category_name = getZScoreCategoryName(this.form.z_score);
      },
    },
    "form.weight": {
      handler() {
        this.form.imt = calculateBMI(
          this.form.weight,
          this.form.height,
          this.student.date_of_birth
        );
        this.form.z_score = getZScore(
          this.form.imt,
          this.bmiData,
          this.student.date_of_birth,
          this.form.measurement_date,
          this.student.sex
        );

        this.form.category_name = getZScoreCategoryName(this.form.z_score);
      },
    },
  },
  created() {
    this.$http("siswa/" + this.$route.params.siswaId, {
      params: {
        with: ["school", "familyMember", "familyMember.user"],
      },
    })
      .then((res) => {
        this.student = res.data;
        console.log("siswa", this.student);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
};
</script>
<style scoped></style>
