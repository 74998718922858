import Dexie from "dexie";
class BarqunDB extends Dexie {
  _table;
  _tableName;
  _params;

  constructor() {
    // select dbname
    super("barqun");
    // define tables
    let tables = {
      user: "++id",
      users: "++id, name, email",
    };
    this.version(1).stores(tables);

    // making accessor of the tables
    for (const key in tables) {
      this[key] = this.table(key);
    }
  }
  // for customized/special query builder use $ func : db.$(tableName)
  // for dexie query builder use db[tableName] : ex : db.user => db['user']
  $(name, params = null) {
    this._tableName = name;
    this._params = params;
    this._table = this[name];
    return this;
  }

  first() {
    return this._table.toCollection().first();
  }

  search(key, search = null) {
    let q = (search || this._params.q).toLowerCase();

    // this will make _table return as Collection
    this._table = this._table.filter((i) => {
      return i[key].toLowerCase().includes(q);
    });
    //

    return this;
  }

  paginate(size = null, page = null) {
    return this._table.count().then(async (total) => {
      let q = ((this._params && this._params.q) || "").toLowerCase();
      let p = page || (this._params && this._params.page);
      let s = size || (this._params && this._params.per_page);

      let offset = p * s;

      let data = await this.table(this._tableName)
        .orderBy("id")
        .reverse()
        .filter((x) => x.name.toLowerCase().includes(q))
        .offset(p == 1 ? 0 : offset - s)
        .limit(s)
        .toArray();

      return {
        // Necessary data for pagination drop here
        data: data,
        total: total,
      };
    });
  }
}

export const db = new BarqunDB();
