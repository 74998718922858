import { calculateAge } from "./bmiUtils";

/**
 * Calculates the total number of students across different schools.
 * @param {Array} students - An array of school objects containing student information.
 * @returns {number} The total count of students.
 */
export function calculateTotalStudent(students) {
  let totalStudents = 0;
  students.forEach((school) => {
    totalStudents += school.students.length;
  });
  return totalStudents;
}

/**
 * Count the number of students based on their gender.
 *
 * @param {Array} students - An array containing student objects.
 * @param {string} gender - The gender to be counted: "LAKI-LAKI" or "PEREMPUAN".
 * @return {number} The count of students with the specified gender.
 */
export function countGender(students, gender) {
  let count = 0;

  students.map((student) => {
    if (student.sex === gender) {
      count++;
    }
  });

  return count;
}
/**
 * This function calculates and generates a human-readable age representation
 * based on the provided date of birth and measurement date. It utilizes the
 * 'calculateAge' function to determine the age in years and months. The resulting
 * age is then formatted into a clear and understandable string, indicating the
 * years and months of age.
 *
 *  @param {String} dateOfBirth - The date of birth of the individual.
 *  @param {String} measurementDate - The date at which the age is being measured.
 *  @return {string} A formatted string indicating the age in years and months.
 */
export function humanReadableAgeFromMeasurementDate(
  dateOfBirth,
  measurementDate
) {
  const { ageYears, ageMonths } = calculateAge(dateOfBirth, measurementDate);
  return `${ageYears} tahun ${ageMonths} bulan`;
}
