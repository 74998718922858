var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"2"}},[_c('DataTable',_vm._b({on:{"saved":_vm.saved},scopedSlots:_vm._u([{key:"modal-form",fn:function(ref){
var editedItem = ref.editedItem;
return [_c('ModulForm',{attrs:{"form":editedItem}})]}},{key:"school_type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":((item.school_type == 'NEGERI' ? "green" : "blue") + " font-weight-bold"),"small":"","dark":""}},[_vm._v(" "+_vm._s(item.school_type)+" ")])]}},{key:"filter",fn:function(ref){
          var load = ref.load;
          var params = ref.params;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('BarqunVSelect',{ref:"selectDis",attrs:{"label":"Kecamatan","autocomplete":"","chips":"","clearable":"","dense":"","small-chips":"","required":"","item-text":"name","item-value":"id","items":_vm.districts,"name":"filterKecamatan","form":params},on:{"input":load,"custom-input":_vm.onDistrictChange}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('BarqunVSelect',{ref:"kel",attrs:{"label":"Kelurahan","autocomplete":"","chips":"","clearable":"","dense":"","small-chips":"","items":_vm.villages,"item-value":"id","item-text":"name","name":"filterKelurahan","form":params},on:{"input":load}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('BarqunVSelect',{attrs:{"label":"Tipe Sekolah","chips":"","clearable":"","dense":"","small-chips":"","items":['NEGERI', 'SWASTA'],"name":"filterTipeSekolah","form":params},on:{"input":load}})],1)],1)]}},{key:"name",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"pt-2"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"pb-1"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"dark":"","small":"","color":"success"}},[_vm._v(_vm._s(item.npsn))])],1)])]}},{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.sync == 'presync')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-time-four-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Belum Sinkron Server")])]),_vm._v(" "+_vm._s(item.uuid)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.notify(item)}}},[_vm._v(" mdi-bell ")])]}}])},'DataTable',_vm.datatable,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }