// import router from "@/router";
import { v4 as uuidv4 } from "uuid";
import { db } from "@/plugins/db";
export default {
  namespaced: true,
  state: {
    moduleName: "",
    moduleUrl: "",
    loading: false,
    item: {},
    items: [],
    params: [],
    options: {
      page: 1,
      per_page: 10,
      q: null,
      sortby: null,
      sortbydesc: null,
    },
    errors: {},
    total: 0,
  },
  mutations: {
    SET_MODULE_NAME(state, value) {
      state.moduleName = value;
      state.moduleUrl = this._vm._.kebabCase(value);
    },
    SET_OPTIONS(state, value) {
      state.options = value;
      state.options.q = value.q;
      state.options.page = value.page;
      state.options.per_page = value.itemsPerPage;
      state.options.sortby = value.sortBy && value.sortBy[0];
      state.options.sortbydesc =
        value.sortDesc && value.sortDesc[0] ? "desc" : "asc";
    },
    SET_ERRORS(state, value) {
      state.errors = Object.assign(state.errors, value);
    },
    DEL_ERRORS(state, value) {
      delete state.errors[value];
    },
    CLEAR_ERRORS(state) {
      state.errors = {};
    },

    SET_ITEM(state, value) {
      state.item = value;
    },
    SET_ITEMS(state, value) {
      state.items = value;
    },
    SET_TOTAL(state, value) {
      state.total = value;
    },
  },
  actions: {
    getData({ state, commit, rootState }, { customUrl, params, id } = {}) {
      return new Promise((resolve, reject) => {
        state.loading = true;
        let url = `${customUrl || state.moduleUrl}` + (id ? `/${id}` : ``);
        let tableName = this._vm._.snakeCase(url);

        if (rootState.onLine)
          this._vm
            .$http(url, {
              params: { ...state.options, ...params },
            })
            .then(({ data }) => {
              if (id) {
                commit("SET_ITEM", data);
              } else {
                commit("SET_ITEMS", data.data);
                commit("SET_TOTAL", data.total);
                // db[tableName].bulkPut(data.data);
              }

              state.loading = false;

              resolve(data);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        else {
          console.log(`[Offline] Getting ${tableName} data...`);
          if (id) {
            db[tableName].get(id).then((data) => {
              resolve(data || {});
            });
          } else {
            db.$(tableName, params)
              .search("name")
              .paginate()
              .then((res) => {
                console.log(res, "res");
                resolve(res);
              });
          }
        }
      });
    },

    async saveData(
      {
        state,
        commit,
        // dispatch,
        rootState,
      },
      {
        customUrl,
        data,
        // params = {}
      }
    ) {
      state.loading = true;

      if (rootState.onLine)
        await this._vm.$http
          .post(customUrl || state.moduleUrl, data)
          .then(() => {
            this._vm.$swal("Success", "Data has been saved", "success");
            commit("CLEAR_ERRORS");
          })
          .catch(({ response }) => {
            this._vm.$swal("Failed", "Failed to save data", "error");
            commit("SET_ERRORS", response.data);
            console.log(response.data);
          });
      else {
        let tname = this._vm._.snakeCase(customUrl || state.moduleUrl);
        let table = db[tname];
        console.log(`[Offline] Saving ${tname} data...`);

        data.uuid = uuidv4();
        data.sync = "presync";

        await table.put(data);
        this._vm.$swal("Success", "Data has been saved", "success");
      }

      // if (!params.noState) dispatch("getData");

      state.loading = false;
    },

    async deleteData(
      { state, dispatch, rootState },
      { customUrl, data, params = {} }
    ) {
      state.loading = true;
      let tname = customUrl || state.moduleUrl;

      if (rootState.onLine)
        await this._vm.$http
          .delete(`${tname}/${data.id}`, {
            ...data,
            params,
          })
          .then((res) => {
            const status = res.data.status;

            if (status === undefined) {
              this._vm.$swal("Success", "Data has been deleted", "success");
            } else {
              const content = res.data.content;
              const parts = content.split("Code:");
              const eror = parts[0].trim();
              this._vm.$swal("Error", eror, "error");
            }
          })
          .catch((err) => {
            console.log(err);
            this._vm.$swal("Failed", "Failed to delete data", "error");
          });
      else {
        console.log(`[Offline] Deleting ${tname} data...`);
        db[tname].delete(data.id);
        this._vm.$swal("Success", "Data has been saved", "success");
      }

      if (!params.noState) dispatch("getData");
      state.loading = false;
    },
  },
};
