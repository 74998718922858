import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import base from "./modules/base";
import theme from "./modules/theme";
import bmi from "./modules/bmi";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: {},
    route: {},
    onLine: navigator.onLine,
    syncing: false,
    showBackOnline: false,
  },
  mutations: {
    SET_ERRORS(state, value) {
      state.errors = Object.assign(state.errors, value);
    },
    SET_ROUTE(state, route) {
      state.route = route;
    },
    SET_ONLINE(state, value) {
      state.onLine = value;
    },
    SET_SHOW_BACK(state, value) {
      state.showBackOnline = value;
    },
    SET_SYNCING(state, value) {
      state.syncing = value;
    },
  },
  actions: {},
  modules: {
    theme,
    auth,
    base,
    bmi,
  },
});
