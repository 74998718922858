<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <v-text-field
          v-model="form.name"
          label="Nama Siswa"
          :rules="rules.nameRules"
          clearable
          autofocus
          placeholder="Masukan nama siswa.."
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="12">
        <v-text-field
          v-model="form.nisn"
          label="Nomor Induk Siswa Nasional"
          :rules="rules.nisnRules"
          clearable
          autofocus
          placeholder="Masukan NISN.."
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-dialog
          ref="formDateOfBirthDialog"
          v-model="formBirthOfDateModal"
          :return-value.sync="form.date_of_birth"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="rules.dateOfBirthRules"
              v-model="formattedDateOfBirth"
              label="Tanggal Lahir"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              placeholder="Pilih tanggal lahir"
            ></v-text-field>
          </template>
          <v-date-picker v-model="form.date_of_birth" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="formBirthOfDateModal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.formDateOfBirthDialog.save(form.date_of_birth)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          clearable
          placeholder="Pilih jenis kelamin"
          label="Pilih Jenis Kelamin"
          v-model="form.sex"
          :rules="rules.sexRules"
          :items="['LAKI-LAKI', 'PEREMPUAN']"
          prepend-inner-icon="mdi-gender-male-female"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          clearable
          placeholder="Pilih asal sekolah"
          label="Pilih Asal Sekolah"
          v-model="form.school_id"
          :items="schools"
          item-text="name"
          :rules="rules.schoolIDRules"
          item-value="id"
          prepend-inner-icon="mdi-home-city-outline"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          clearable
          placeholder="Pilih kelas"
          label="Pilih Kelas"
          v-model="form.school_class"
          :items="schoolClasses"
          :rules="rules.schoolClassRules"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="12">
        <v-autocomplete
          clearable
          placeholder="Pilih wali siswa"
          label="Pilih Wali Siswa"
          v-model="form.family_member_id"
          :items="parents"
          item-text="name"
          :rules="rules.familyMemberIDRules"
          item-value="id"
          prepend-inner-icon="mdi-account-supervisor-circle-outline"
        >
          <template v-slot:item="{ item }">
            {{ item.name }} - {{ item.phoneNumber }}
          </template></v-autocomplete
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: ["form"],
  components: {
    // VFileInput
  },
  data() {
    return {
      formBirthOfDateModal: false,
      formMeasurementDateModal: false,
      schools: [],
      parents: [],
      schoolClasses: [
        "KELAS I",
        "KELAS II",
        "KELAS III",
        "KELAS IV",
        "KELAS V",
        "KELAS VI",
      ],
      rules: {
        nameRules: [
          (v) => !!v || "Kolom nama siswa tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        nisnRules: [(v) => !!v || "Kolom NISN tidak boleh kosong!"],
        dateOfBirthRules: [
          (v) => !!v || "Kolom tanggal lahir tidak boleh kosong!",
        ],
        sexRules: [(v) => !!v || "Kolom jenis kelamin tidak boleh kosong!"],
        schoolIDRules: [(v) => !!v || "Kolom asal sekolah tidak boleh kosong!"],
        schoolClassRules: [(v) => !!v || "Kolom kelas tidak boleh kosong!"],
        familyMemberIDRules: [
          (v) => !!v || "Kolom wali siswa tidak boleh kosong!",
        ],
      },
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    formattedDateOfBirth() {
      return this.form.date_of_birth
        ? new Date(this.form.date_of_birth).toLocaleDateString("id-ID")
        : null;
    },
  },
  created() {
    this.$http("sekolah", {
      params: {
        all: true,
        sortby: "name",
        sortbydesc: "asc",
      },
    })
      .then((res) => {
        this.schools = res.data.map(({ id, name }) => ({ id, name }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    this.$http("wali-siswa", {
      params: {
        with: ["user"],
        all: true,
      },
    })
      .then((res) => {
        this.parents = res.data.map((parent) => {
          return {
            id: parent.id,
            name: parent.user.name,
            phoneNumber: parent.phone_number,
          };
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
};
</script>
