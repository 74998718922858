<template>
  <v-navigation-drawer app class="elevation-5" v-model="drawer.state">
    <!-- <v-img gradient="to top, rgba(0, 0, 0, 0.6) 0%, transparent 100px" :src="require('../assets/sidebar/cover-4.jpg')" max-height="150" dark id="top"> -->
    <v-img
      gradient="to top, rgba(0, 0, 0, 0.6) 0%, transparent 100px"
      src="http://loremflickr.com/640/480/nature,forest,sea"
      max-height="150"
      dark
      id="top"
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img-preview
              :src="
                this.user.avatar != null
                  ? this.user.avatar_link
                  : require('../assets/persons/person 3.jpg')
              "
            ></v-img-preview>
            <!-- <v-img-preview
              :src="
                user.avatar_link || require('../assets/persons/person 3.jpg')
              "
            ></v-img-preview> -->
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item v-if="user.name" link to="/profile">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ (user.sekolah && user.sekolah.nama) || user.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-img>

    <v-list shaped dense>
      <template v-for="(link, key) in links">
        <!-- Single Menu -->
        <v-list-item
          v-if="
            link.route &&
            ((link.can == undefined && link.role == undefined) ||
              userCan(link.can) ||
              userRole(link.role))
          "
          :key="link.id"
          router
          :to="link.route"
          active-class="border barqun-blue--text"
        >
          <v-list-item-action>
            <v-icon color="blue lighten-2">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey-darken2--text">
              {{ link.text }}</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action v-if="link.actions">
            <v-chip
              v-if="link.actions.chip"
              color="blue"
              small
              text-color="white"
            >
              {{ link.actions.chip }}
            </v-chip>
          </v-list-item-action>
        </v-list-item>
        <!-- End Single Menu -->

        <v-divider v-if="link.divider" :key="link.id"></v-divider>

        <v-subheader
          class="text-overline blue--text"
          v-if="
            link.subheader &&
            (link.group_permissions.some((i) => userCan(i)) ||
              !link.group_permissions.length)
          "
          :key="link.id"
          >{{ link.subheader }}</v-subheader
        >
        <!-- Dropdown Menu -->
        <v-list-group
          v-if="
            link.childs &&
            link.childs.some(
              (i) =>
                (i.can == undefined && i.role == undefined) ||
                userCan(i.can) ||
                userRole(i.role)
            )
          "
          :id="`menu_${link.id || key}`"
          :key="link.text"
          :value="link.childs.find((i) => i.route == $route.path)"
          :prepend-icon="link.icon"
          color="blue"
          no-action
          @click="scroll(menuState[key])"
          v-model="menuState[key]"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </template>
          <template v-for="child in link.childs">
            <v-list-item
              :key="child.text"
              v-if="
                (child.can == undefined && child.role == undefined) ||
                userCan(child.can) ||
                userRole(child.role)
              "
              router
              :to="child.route"
              active-class="border"
            >
              <v-list-item-action>
                <v-icon color="blue lighten-2">{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ child.text }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <!-- End Dropdown Menu -->
      </template>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-alert
          :value="!onLine || showBackOnline"
          dense
          :color="onLine ? `green` : `pink`"
          dark
          border="top"
          :icon="onLine ? `mdi-access-point-check` : `mdi-access-point-off`"
          transition="slide-x-transition"
        >
          You're {{ onLine ? "online" : "offline" }}
        </v-alert>
        <v-alert
          :value="syncing"
          dense
          color="orange"
          dark
          border="top"
          icon="mdi-cloud-sync"
          transition="slide-x-transition"
        >
          Updating...

          <template #append>
            <v-progress-circular
              size="20"
              width="3"
              indeterminate
            ></v-progress-circular>
          </template>
        </v-alert>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  props: ["drawer"],

  data() {
    return {
      drawerModel: true,
      menuState: {},
      onLine: navigator.onLine,
      showBackOnline: false,
    };
  },
  computed: {
    ...mapGetters("theme", ["links"]),
    ...mapState(["syncing"]),
    ...mapState("auth", ["user", "isAuth"]),
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  methods: {
    ...mapMutations(["SET_ONLINE", "SET_SHOW_BACK", "SET_SYNCING"]),
    scroll() {
      setTimeout(
        () =>
          document
            // .getElementById(state ? `top` : `menu_settings`)
            .getElementById("top")
            .scrollIntoView({ behavior: "smooth" }),
        250
      );
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
  },
  watch: {
    onLine(v) {
      this.SET_ONLINE(v);
      if (v) {
        this.showBackOnline = true;
        this.SET_SHOW_BACK(true);
        setTimeout(() => {
          this.showBackOnline = false;
          this.SET_SHOW_BACK(false);

          setTimeout(() => {
            this.SET_SYNCING(true);
            setTimeout(() => {
              this.SET_SYNCING(false);
            }, 1000);
          }, 1000);
        }, 1000);
      }
    },
  },
};
</script>
<style scoped>
.border {
  border-left: 4px solid#2f65ed;
}
</style>
<style lang="css">
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}
</style>
