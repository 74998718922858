<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #code="{ item }">
          <v-chip class="ma-2" color="blue font-weight-bold" small dark>
            {{ item.code }}
          </v-chip>
        </template>

        <template #actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.sync == 'presync'"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                small
                class="mr-2"
              >
                mdi-clock-time-four-outline
              </v-icon>
            </template>
            <span>Belum Sinkron Server</span>
          </v-tooltip>
          <v-icon color="success" small class="mr-2" @click="notify(item)">
            mdi-bell
          </v-icon>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";

export default {
  components: {
    DataTable,
    ModulForm,
  },
  data() {
    return {
      datatable: {
        moduleName: "Puskesmas",
        title: "Daftar Puskesmas",
        headers: [
          { text: "No", value: "num", sortable: false },
          { text: "Kode", value: "code", slot: true },
          { text: "Nama Puskesmas", value: "name" },
          { text: "Kecamatan", value: "district.name", sortable: false },
          { text: "Kelurahan", value: "village.name", sortable: false },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ],
        params: {
          sortby: "name",
          sortbydesc: "asc",
          with: ["district", "village"],
        },
      },
    };
  },
};
</script>
