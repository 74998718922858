<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #user.is_active_status_name="{ item }">
          <v-chip
            :color="`${
              item.user.is_active === 1 ? `green lighten-2` : `red`
            } font-weight-bold`"
            small
            dark
          >
            {{ item.user.is_active_status_name }}
          </v-chip>
        </template>

        <template #actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.sync == 'presync'"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                small
                class="mr-2"
              >
                mdi-clock-time-four-outline
              </v-icon>
            </template>
            <span>Belum Sinkron Server</span>
          </v-tooltip>

          <v-icon color="success" small class="mr-2" @click="notify(item)">
            mdi-bell
          </v-icon>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";
// import { mapActions } from 'vuex'
export default {
  components: {
    DataTable,
    ModulForm,
  },
  data() {
    return {
      datatable: {
        title: "Daftar Wali Siswa",
        formData: true,
        moduleName: "WaliSiswa",
        slots: ["avatar"],
        headers: [
          { text: "No", value: "num", sortable: false },
          {
            text: "Nama Wali",
            value: "user.name",
            slot: true,
            sortable: false,
          },
          { text: "Hubungan Keluarga", value: "family_relationship" },
          { text: "Pekerjaan", value: "occupation" },
          { text: "No Telepon", value: "phone_number" },
          {
            text: "Status Akun",
            value: "user.is_active_status_name",
            slot: true,
            sortable: false,
          },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ],
        params: {
          with: ["user"],
        },
      },
    };
  },
  methods: {
    notify(item) {
      console.log(item);
      this.$swal({
        title: "Send Notification",
        text: "Are you sure want to send notification to this user?",
        icon: "warning",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          this.$http(`users/notify/${item.id}`);
        },
      });
    },
  },
};
</script>
