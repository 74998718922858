var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"loading":_vm.loading,"elevation":"2"}},[(_vm.school)?_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Detail Monitoring Gizi di "+_vm._s(this.school.name)+" ")]):_vm._e(),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(!this.userRole('puskesmas'))?_c('NutritionalSchoolAlert',{attrs:{"nutritionsCount":_vm.nutritionsCount}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-select',{attrs:{"items":['ALL', 'LAKI-LAKI', 'PEREMPUAN', 'KELAS'],"label":"Filter","append-icon":"mdi-filter","dense":"","outlined":""},on:{"change":_vm.filter},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.showSelectClass)?_c('v-select',{attrs:{"items":[
              'KELAS I',
              'KELAS II',
              'KELAS III',
              'KELAS IV',
              'KELAS V',
              'KELAS VI' ],"label":"Filter Kelas","dense":"","outlined":""},on:{"change":_vm.filterSchoolClass}}):_vm._e(),(!_vm.pieInstance)?_c('div',{staticClass:"d-flex flex-column align-center justify-center mt-5"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":"","color":"primary"}}),_c('p',{staticClass:"mt-2"},[_vm._v("Loading Chart")])],1):_vm._e(),_c('div',{ref:"pie",staticClass:"chart"})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[(_vm.school)?_c('v-simple-table',{staticClass:"custom-table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',{staticClass:"text-uppercase"},[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Nama Sekolah")]),_c('td',[_vm._v(_vm._s(_vm.school.name))])]),_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Alamat")]),_c('td',[_vm._v(_vm._s(_vm.school.address))])]),_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Kecamatan")]),_c('td',[_vm._v(_vm._s(_vm.school.district.name))])]),_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Kelurahan")]),_c('td',[_vm._v(_vm._s(_vm.school.village.name))])]),_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Jumlah Staff")]),_c('td',[_vm._v(_vm._s(_vm.school.school_staffs.length))])]),_c('tr',[_c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Jumlah Siswa")])]),_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Laki-laki")]),_c('th',{staticClass:"text-center"},[_vm._v("Perempuan")])]),_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.countGender(_vm.school.students, "LAKI-LAKI"))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.countGender(_vm.school.students, "PEREMPUAN"))+" ")])])])]},proxy:true}],null,false,2805196439)}):_vm._e()],1)],1)],1),_c('v-divider'),_c('DataTable',_vm._b({ref:"table",scopedSlots:_vm._u([{key:"modal-form",fn:function(ref){
            var editedItem = ref.editedItem;
return [_c('ModulForm',{attrs:{"form":editedItem}})]}},{key:"name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"pa-2 font-weight-bold"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":"success"}},[_vm._v(_vm._s(item.nisn))])],1),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":"gray"}},[_vm._v(_vm._s(item.school_class))])],1),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":item.sex === 'LAKI-LAKI' ? 'blue' : 'pink'}},[_c('v-icon',[_vm._v("mdi mdi-gender-"+_vm._s(item.sex === "LAKI-LAKI" ? "male" : "female")+" ")]),_vm._v(" "+_vm._s(item.sex)+" ")],1)],1)])]}},{key:"date_of_birth",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"dark":"","small":""}},[_vm._v(" "+_vm._s(new Date(item.date_of_birth).toLocaleDateString("id-ID", { year: "numeric", month: "long", day: "numeric", }))+" ")])]}},{key:"anthropometric_measurements_count",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":"blue-grey","text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-checkbook")])],1),_vm._v(" "+_vm._s(item.anthropometric_measurements_count)+" ")],1)]}},{key:"latest_anthropometric_measurement",fn:function(ref){
            var item = ref.item;
return [(item.anthropometric_measurements_count > 0)?_c('span',[_c('NutritionalCategory',{attrs:{"categoryName":item.latest_anthropometric_measurement.category_name}})],1):_c('span',[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"gray darken-2","small":"","dark":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-alert-outline ")]),_vm._v("Pemeriksaan Belum Ada")],1)],1)]}},{key:"action",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.mobile,"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary rounded-lg","dark":""},on:{"click":function($event){return _vm.show(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Detail")])])],1)]}}])},'DataTable',_vm.datatable,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }