<template>
  <div>
    <v-combobox
      v-if="combobox"
      v-model="form[name]"
      v-bind="$attrs"
      :items="itemList"
      :label="label"
      :item-value="itemValue"
      :item-text="itemText"
      :rules="[(value) => !required || !!value || 'Required.']"
      :error="false"
      @change="
        (value) => {
          this.$emit('custom-input', value);
        }
      "
      @input="
        (value) => {
          this.$emit('custom-input', value);
          this.$emit('input', value);
          this.$emit('input:this', this.$parent);
        }
      "
    >
      >
      <template #label v-if="required">
        {{ label }} <span class="red--text"><strong> *</strong></span>
      </template>
    </v-combobox>
    <v-autocomplete
      v-else-if="autocomplete"
      v-model="form[name]"
      v-bind="$attrs"
      :items="itemList"
      :label="label"
      :item-value="itemValue"
      :item-text="itemText"
      :rules="[(value) => !required || !!value || 'Required.']"
      :error="false"
      :search-input.sync="search"
      @change="
        (value) => {
          this.$emit('custom-input', value);
          this.$emit('custom-input:this', this.$parent);
        }
      "
      @input="
        (value) => {
          this.$emit('input', value);
          this.$emit('input:this', this.$parent);
        }
      "
      @blur="() => this.$emit('blur')"
    >
      <template #label v-if="required">
        {{ label }} <span class="red--text"><strong> *</strong></span>
      </template>
      <template v-slot:append-item v-if="url">
        <div v-intersect="endIntersect" />
      </template>
    </v-autocomplete>
    <v-select
      v-else
      v-bind="$attrs"
      v-model="form[name]"
      :rules="[(value) => !required || !!value || 'Required.']"
      :error="false"
      :label="label"
      :autofocus="autofocus"
      :items="itemList"
      :item-value="itemValue"
      :item-text="itemText"
      @change="
        (value) => {
          const selectedItem = this.itemList.find(
            (item) => item[this.itemText] === value
          );
          const selectedId = selectedItem;
          this.$emit('custom-input', selectedId);
        }
      "
      @input="
        (value) => {
          this.$emit('input', value);
          this.$emit('input:this', this.$parent);
        }
      "
    >
      <template #label v-if="required">
        {{ label }} <span class="red--text"><strong> *</strong></span>
      </template>
    </v-select>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  props: {
    label: String,
    url: String,
    name: String,
    itemValue: String,
    itemText: [String, Function],
    required: Boolean,
    autofocus: Boolean,
    autocomplete: Boolean,
    combobox: Boolean,
    form: {
      type: [Array, Object],
    },
    items: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    params: {
      type: [Object],
    },
  },
  data() {
    return {
      itemList: this.items || [],
      page: 1,
      search: "",
    };
  },

  mounted() {
    if (this.url) this.getData();
  },

  methods: {
    async api() {
      let { data } = await this.$http(this.url, {
        params: {
          ...this.params,
          page: this.page,
          q: this.search,
        },
      });

      return data;
    },
    async getData() {
      let { data } = await this.api();

      this.itemList = data;
    },
    async endIntersect(observer) {
      if (observer[0].isIntersecting) {
        this.page += 1;
        let { data } = await this.api();

        this.itemList = [...this.itemList, ...data];
      }
    },
  },

  watch: {
    items(val) {
      if (this.url) this.getData();
      else this.itemList = val;
    },
    url(val) {
      this.page = 1;
      if (val) this.getData();
    },
    "form.id": function () {},
    search: _.debounce(function () {
      this.page = 1;
      if (this.url) this.getData();
    }, 500),
  },
};
</script>
<style></style>
