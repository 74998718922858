<template>
  <v-container>
    <v-card :loading="loading" elevation="2" class="rounded-xl">
      <v-card-title class="justify-center" v-if="school"
        >Detail Monitoring Gizi di
        {{ this.school.name }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <NutritionalSchoolAlert
              :nutritionsCount="nutritionsCount"
              v-if="!this.userRole('puskesmas')"
            ></NutritionalSchoolAlert>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-select
              :items="['ALL', 'LAKI-LAKI', 'PEREMPUAN', 'KELAS']"
              v-model="selected"
              @change="filter"
              label="Filter"
              append-icon="mdi-filter"
              dense
              outlined
            ></v-select>
            <v-select
              v-if="showSelectClass"
              :items="[
                'KELAS I',
                'KELAS II',
                'KELAS III',
                'KELAS IV',
                'KELAS V',
                'KELAS VI',
              ]"
              @change="filterSchoolClass"
              label="Filter Kelas"
              dense
              outlined
            ></v-select>
            <div
              class="d-flex flex-column align-center justify-center mt-5"
              v-if="!pieInstance"
            >
              <v-progress-circular
                :size="70"
                :width="7"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <p class="mt-2">Loading Chart</p>
            </div>
            <div ref="pie" class="chart"></div>
          </v-col>

          <v-col cols="12" sm="12" md="6">
            <v-simple-table v-if="school" class="custom-table">
              <template v-slot:default>
                <tbody class="text-uppercase">
                  <tr>
                    <th class="text-left">Nama Sekolah</th>
                    <td>{{ school.name }}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Alamat</th>
                    <td>{{ school.address }}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Kecamatan</th>
                    <td>{{ school.district.name }}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Kelurahan</th>
                    <td>{{ school.village.name }}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Jumlah Staff</th>
                    <td>{{ school.school_staffs.length }}</td>
                  </tr>
                  <tr>
                    <th class="text-center" colspan="2">Jumlah Siswa</th>
                  </tr>
                  <tr>
                    <th class="text-center">Laki-laki</th>
                    <th class="text-center">Perempuan</th>
                  </tr>
                  <tr>
                    <td class="text-center">
                      {{ countGender(school.students, "LAKI-LAKI") }}
                    </td>
                    <td class="text-center">
                      {{ countGender(school.students, "PEREMPUAN") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <DataTable v-bind="datatable" ref="table">
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #name="{ item }">
          <div class="pa-2 font-weight-bold">
            <p>{{ item.name }}</p>
            <p>
              <v-chip dark small color="success">{{ item.nisn }}</v-chip>
            </p>
            <p>
              <v-chip dark small color="gray">{{ item.school_class }}</v-chip>
            </p>
            <p>
              <v-chip
                dark
                small
                :color="item.sex === 'LAKI-LAKI' ? 'blue' : 'pink'"
              >
                <v-icon
                  >mdi mdi-gender-{{
                    item.sex === "LAKI-LAKI" ? "male" : "female"
                  }}
                </v-icon>
                {{ item.sex }}
              </v-chip>
            </p>
          </div>
        </template>

        <template #date_of_birth="{ item }">
          <v-chip class="font-weight-bold" dark small>
            {{
              new Date(item.date_of_birth).toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}
          </v-chip>
        </template>

        <template #anthropometric_measurements_count="{ item }">
          <v-chip
            class="ma-2 font-weight-bold"
            color="blue-grey"
            text-color="white"
          >
            <v-avatar left>
              <v-icon>mdi-checkbook</v-icon>
            </v-avatar>
            {{ item.anthropometric_measurements_count }}
          </v-chip>
        </template>

        <template #latest_anthropometric_measurement="{ item }">
          <span v-if="item.anthropometric_measurements_count > 0">
            <NutritionalCategory
              :categoryName="
                item.latest_anthropometric_measurement.category_name
              "
            ></NutritionalCategory>
          </span>

          <span v-else>
            <v-chip class="font-weight-bold" color="gray darken-2" small dark
              ><v-icon small left> mdi-alert-outline </v-icon>Pemeriksaan Belum
              Ada</v-chip
            >
          </span>
        </template>

        <template #action="{ item }">
          <div>
            <v-tooltip
              :disabled="$vuetify.breakpoint.mobile"
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="primary rounded-lg"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="show(item)"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>Detail</span>
            </v-tooltip>
          </div>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import * as echarts from "echarts";
import ModulForm from "./Form.vue";
import { mapState } from "vuex";
import { countGender } from "../../helpers/student";
import NutritionalSchoolAlert from "../../components/NutritionalSchoolAlert.vue";
import NutritionalCategory from "../../components/NutritionalCategory.vue";

export default {
  components: {
    ModulForm,
    DataTable,
    NutritionalSchoolAlert,
    NutritionalCategory,
  },
  data() {
    return {
      bmi: null,
      loading: false,
      chartInstance: null,
      datatable: {
        btnAdd: false,
        moduleName: "siswa",
        btnExport: true,
        title: "Daftar Siswa",
        headers: [
          { text: "no", value: "num", sortable: false },
          { text: "Nama Siswa", value: "name", slot: true },
          {
            text: "Tanggal Lahir",
            value: "date_of_birth",
            slot: true,
            sortable: false,
          },
          {
            text: "Total Pemeriksaan",
            value: "anthropometric_measurements_count",
            slot: true,
            sortable: false,
          },
          {
            text: "Pemeriksaan Terakhir",
            value: "latest_anthropometric_measurement",
            slot: true,
            sortable: false,
          },
          {
            text: "Actions",
            value: "action",
            sortable: false,
            align: "center",
            slot: true,
          },
        ],
        params: {
          with: ["school"],
          sortby: "name",
          sortbydesc: "asc",
          filterAsalSekolah: this.$route.params.id,
          filterKelas: null,
          filterJenisKelamin: null,
        },
      },
      pieInstance: null,
      anthro: null,
      school: null,
      selected: "ALL",
      showSelectClass: false,
      nutritionsCount: {
        malnutrition: 0,
        undernutrition: 0,
        normal: 0,
        overnutrition: 0,
        obesity: 0,
      },
    };
  },
  computed: {
    ...mapState("bmi", ["bmiData"]),
  },
  methods: {
    countGender(students, gender) {
      return countGender(students, gender);
    },
    /**
     * Show the item.
     *
     * @param {type} item - The item to be shown.
     */
    show(item) {
      const detailUrl = `/monitoring-gizi/sekolah/${item.school_id}/siswa/${item.id}`;

      // Navigate to the detail page using Vue Router
      this.$router.push(detailUrl);
    },
    filterSchoolClass(item) {
      this.$http("sekolah/status-nutrisi", {
        params: {
          school_id: this.$route.params.id,
          school_class: item,
        },
      }).then((res) => {
        this.datatable.params.filterJenisKelamin = null;
        this.datatable.params.filterKelas = item;
        this.nutritionsCount = res.data.data;
        this.pieChart();
        this.$refs.table.load();
      });
    },
    filter(item) {
      this.showSelectClass = false;
      if (item === "LAKI-LAKI" || item === "PEREMPUAN") {
        this.datatable.params.filterJenisKelamin = item;
        this.datatable.params.filterKelas = null;
      }

      if (item === "ALL") {
        this.datatable.params.filterJenisKelamin = null;
        this.datatable.params.filterKelas = null;
        this.$http("sekolah/status-nutrisi", {
          params: {
            school_id: this.$route.params.id,
          },
        }).then((res) => {
          this.nutritionsCount = res.data.data;
          this.pieChart();
        });
      } else if (item === "KELAS") {
        this.showSelectClass = true;
      } else {
        this.$http("sekolah/status-nutrisi", {
          params: {
            school_id: this.$route.params.id,
            sex: item,
          },
        }).then((res) => {
          this.nutritionsCount = res.data.data;
          this.pieChart();
        });
      }
      this.$refs.table.load();
    },
    pieChart() {
      const option = {
        title: {
          text: `Persentase Gizi`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          orient: "horizontal",
          bottom: "10%",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            center: ["50%", "30%"],
            label: {
              position: this.$vuetify.breakpoint.xs ? "inside" : "outside",
              formatter: this.$vuetify.breakpoint.xs ? "{d}%" : "{b} {d}%",
              color: "black",
            },
            data: [
              { value: this.nutritionsCount.obesity, name: "Obesitas" },
              {
                value: this.nutritionsCount.overnutrition,
                name: "Gizi Lebih",
              },
              { value: this.nutritionsCount.normal, name: "Normal" },
              {
                value: this.nutritionsCount.undernutrition,
                name: "Gizi Kurang",
              },
              { value: this.nutritionsCount.malnutrition, name: "Gizi Buruk" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      this.pieInstance.setOption(option);
      this.loading = false;

      window.addEventListener("resize", () => {
        this.pieInstance.resize();
      });
    },
  },
  mounted() {
    this.$http("sekolah/" + this.$route.params.id, {
      params: {
        with: ["students", "schoolStaffs", "district", "village"],
      },
    })
      .then((res) => {
        this.school = res.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    this.$http("sekolah/status-nutrisi", {
      params: {
        school_id: this.$route.params.id,
      },
    }).then((res) => {
      this.nutritionsCount = res.data.data;
      this.pieChart();
    });

    this.loading = true;
    this.pieInstance = echarts.init(this.$refs.pie);
    this.pieChart();
  },
};
</script>

<style>
.custom-table {
  border: 1px solid #a7a6a6;
}
.custom-table tbody tr {
  border: 1px solid #a1a1a1;
}
.custom-table th,
.custom-table td {
  border: 1px solid #a5a5a5;
}

.border {
  border-bottom: 1px solid rgb(187, 187, 187);
}
.chart {
  width: 100%;
  height: 500px;
}

@media only screen and (max-width: 600px) {
  .chart {
    width: 600px;
    height: 500px;
  }
}
</style>
