var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"clearable":"","items":['PENINGKATAN', 'TIDAK ADA PERUBAHAN', 'PENURUNAN'],"label":"Kategori Status Gizi","prepend-inner-icon":"mdi-view-list-outline","placeholder":"Pilih kategori status gizi"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-select',{attrs:{"clearable":"","items":[
          'Gizi Buruk',
          'Gizi Kurang',
          'Gizi Baik',
          'Gizi Lebih',
          'Obesitas' ],"label":"Pilih Variabel 1","prepend-inner-icon":"mdi-pill","placeholder":"Pilih variabel 1"},model:{value:(_vm.form.variable_1),callback:function ($$v) {_vm.$set(_vm.form, "variable_1", $$v)},expression:"form.variable_1"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-select',{attrs:{"clearable":"","items":[
          'Gizi Buruk',
          'Gizi Kurang',
          'Gizi Baik',
          'Gizi Lebih',
          'Obesitas' ],"label":"Pilih Variabel 2","placeholder":"Pilih variabel 2","prepend-inner-icon":"mdi-pill"},model:{value:(_vm.form.variable_2),callback:function ($$v) {_vm.$set(_vm.form, "variable_2", $$v)},expression:"form.variable_2"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('Editor',{attrs:{"form":_vm.form}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }