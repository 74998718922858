<template>
  <v-bottom-navigation
    v-model="value"
    background-color="blue lighten-2"
    dark
    shift
    fixed
    grow
    app
  >
    <v-btn to="/">
      <span>Home</span>

      <v-icon>mdi-home-outline</v-icon>
    </v-btn>

    <v-btn to="/kalkulator-antropometri">
      <span>Antropometri Kalkulator</span>

      <v-icon>mdi-calculator-variant-outline</v-icon>
    </v-btn>

    <v-btn v-if="user.id" to="/antropometri-individu">
      <span>Antropometri Individu</span>

      <v-icon>mdi-book-account-outline</v-icon>
    </v-btn>

    <v-btn v-if="user.id" to="/monitoring-gizi">
      <span>Monitoring Gizi</span>

      <v-icon>mdi-chart-box-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({ value: 0 }),

  computed: {
    ...mapState("auth", ["user"]),
    color() {
      switch (this.value) {
        case 0:
          return "blue-grey";
        case 1:
          return "teal";
        case 2:
          return "brown";
        case 3:
          return "indigo";
        default:
          return "blue-grey";
      }
    },
  },
};
</script>
