<template>
  <v-container v-if="userRole('admin') || userRole('dinas kesehatan')">
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-card class="pa-5" elevation="5">
          <div class="d-flex align-center">
            <button type="button" class="blue darken-2 pa-2 rounded-xl mr-2">
              <v-icon dark large>mdi mdi-domain</v-icon>
            </button>
            <div>
              <h4 class="text-h6 font-weight-bold">
                {{ schoolStatistics.total_school }}
              </h4>
              <h4 class="text-subtitle-1">Total Sekolah</h4>
            </div>
          </div>
        </v-card>

        <v-row class="pt-3">
          <v-col cols="12" sm="12" md="6">
            <v-card class="pa-5" elevation="5">
              <div class="d-flex align-center">
                <button type="button" class="blue pa-2 rounded-xl mr-2">
                  <v-icon dark large>mdi mdi-domain</v-icon>
                </button>
                <div>
                  <h4 class="text-h6 font-weight-bold">
                    {{ schoolStatistics.public_school }}
                  </h4>
                  <h4 class="text-subtitle-1">Negeri</h4>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card class="pa-5" elevation="5">
              <div class="d-flex align-center">
                <button type="button" class="indigo pa-2 rounded-xl mr-2">
                  <v-icon dark large>mdi mdi-domain</v-icon>
                </button>
                <div>
                  <h4 class="text-h6 font-weight-bold">
                    {{ schoolStatistics.private_school }}
                  </h4>
                  <h4 class="text-subtitle-1">Swasta</h4>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-card class="pa-5" elevation="5">
          <div class="d-flex align-center">
            <button type="button" class="indigo darken-2 pa-2 rounded-xl mr-2">
              <v-icon dark large>mdi mdi-card-account-details-outline</v-icon>
            </button>
            <div>
              <h4 class="text-h6 font-weight-bold">
                {{ studentStatistics.total_students }}
              </h4>
              <h4 class="text-subtitle-1">Total Siswa</h4>
            </div>
          </div>
        </v-card>

        <v-row class="pt-3">
          <v-col cols="12" sm="12" md="6">
            <v-card class="pa-5" elevation="5">
              <div class="d-flex align-center">
                <button type="button" class="blue pa-2 rounded-xl mr-2">
                  <v-icon dark large>mdi mdi-gender-male</v-icon>
                </button>
                <div>
                  <h4 class="text-h6 font-weight-bold">
                    {{ studentStatistics.male_students }}
                  </h4>
                  <h4 class="text-subtitle-1">Laki-laki</h4>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card class="pa-5" elevation="5">
              <div class="d-flex align-center">
                <button type="button" class="pink pa-2 rounded-xl mr-2">
                  <v-icon dark large>mdi mdi-gender-female</v-icon>
                </button>
                <div>
                  <h4 class="text-h6 font-weight-bold">
                    {{ studentStatistics.female_students }}
                  </h4>
                  <h4 class="text-subtitle-1">Perempuan</h4>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-card class="pa-5" elevation="5">
          <div class="d-flex justify-center">
            <div ref="schoolStatisticsChart"></div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card class="pa-5" elevation="5">
          <div class="d-flex justify-center">
            <div ref="studentStatisticsChart"></div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <div class="d-flex justify-center">
        <v-progress-circular
          v-if="loading"
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
        <div ref="categoryStatisticChart" class="aabb"></div>
      </div>
    </v-card>
  </v-container>
  <v-container v-else>
    <v-card>
      <div class="text-center pt-3">
        <p class="text-h5 font-weight-bold">Gizi SD</p>
      </div>
      <div class="text-center">
        <p class="text-h5 font-weight-bold">Monitoring Status Gizi</p>
      </div>
      <div class="text-center pt-5">
        <v-avatar :size="$vuetify.breakpoint.mobile ? '220' : '320'">
          <img src="@/assets/logo_gizi.jpeg" alt="John" />
        </v-avatar>
      </div>
      <v-card-text
        class="mt-3 text-center font-weight-regular text--primary text-body-1"
      >
        <div class="">
          Aplikasi ini di desain terintegrasi antara sekolah, wali siswa,
        </div>
        <div>Puskesmas dan Dinas Kesehatan</div>
        <div class="ma-8">Fasilitas :</div>

        <v-row>
          <v-col>
            <div>
              <v-card class="pa-2" outline rounded elevation="5">
                <v-icon color="primary">mdi-book-account-outline</v-icon>
                <span>Antropometri Siswa</span>
                <v-card-subtitle> Untuk orang tua/Wali Siswa </v-card-subtitle>
              </v-card>
            </div>
          </v-col>
          <v-col>
            <v-card class="pa-2" outline rounded elevation="5">
              <v-icon color="primary">mdi-chart-box-outline</v-icon>
              <span>Monitoring Gizi</span>
              <v-card-subtitle
                >Untuk Sekolah, Puskesmas dan Dinas Kesehatan</v-card-subtitle
              >
            </v-card>
          </v-col>
          <v-col>
            <v-card class="pa-2" outline rounded elevation="5">
              <v-icon color="primary">mdi-star-check-outline</v-icon>
              <span>Saran Dan Rekomendasi</span>
              <v-card-subtitle
                >Semua pihak sesuai hasil data masalah gizi yang dianalisis
                sistem</v-card-subtitle
              >
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center align-center">
        <div class="ma-10">
          <p>Informasi akses dan kerjasama:</p>
          <v-btn text @click="openWhatsApp()">
            <span class="pr-2">Admin</span>
            <span class="pr-1">(085347008226)</span>
            <v-icon color="green">whatsapp</v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      schoolStatistics: [],
      studentStatistics: [],
      nutritionsCount: null,
      schoolStatisticsChart: null,
      studentStatisticsChart: null,
      categoryChart: null,
      loading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },
  mounted() {
    if (this.user && this.user.id) {
      window.addEventListener("resize", () => {
        this.schoolStatisticsChart.resize();
        this.studentStatisticsChart.resize();
        // this.categoryStatisticChart.resize();
      });

      this.$http("/sekolah/statistik")
        .then((res) => {
          this.schoolStatistics = res.data.data;
        })
        .catch((err) => {
          console.log("Error fetching data:", err);
        });

      this.$http("/siswa/statistik")
        .then((res) => {
          this.studentStatistics = res.data.data;
        })
        .catch((err) => {
          console.log("Error fetching data:", err);
        });

      this.$http("sekolah/status-nutrisi")
        .then((res) => {
          this.nutritionsCount = res.data.data;
          this.showCategoryChart();
        })
        .catch((err) => {
          console.log("Error fetching data:", err);
        });

      this.categoryChart = echarts.init(this.$refs.categoryStatisticChart);
      // this.loading = true;
    }
  },
  updated() {
    // Create the echarts instance
    this.schoolStatisticsChart = echarts.init(
      this.$refs.schoolStatisticsChart,
      null,
      {
        width: 400,
        height: 400,
      }
    );

    // Draw the chart
    this.schoolStatisticsChart.setOption({
      title: {
        text: "Grafik Sekolah",
        left: "center",
      },
      tooltip: {},
      xAxis: {
        data: ["Negeri", "Swasta"],
      },
      yAxis: {},
      series: [
        {
          type: "bar",
          data: [
            {
              value: this.schoolStatistics.public_school,
              itemStyle: {
                color: "#2196F3",
              },
            },
            {
              value: this.schoolStatistics.private_school,
              itemStyle: {
                color: "#3F51B5",
              },
            },
          ],
        },
      ],
    });

    this.studentStatisticsChart = echarts.init(
      this.$refs.studentStatisticsChart,
      null,
      {
        width: 400,
        height: 400,
      }
    );

    // Draw the chart
    this.studentStatisticsChart.setOption({
      title: {
        text: "Grafik Siswa",
        left: "center",
      },
      tooltip: {},
      xAxis: {
        data: ["Laki-laki", "Perempuan"],
      },
      yAxis: {},
      series: [
        {
          type: "bar",
          data: [
            {
              value: this.studentStatistics.male_students,
              itemStyle: {
                color: "#2196F3",
              },
            },
            {
              value: this.studentStatistics.female_students,
              itemStyle: {
                color: "#E91E63",
              },
            },
          ],
        },
      ],
    });
  },
  methods: {
    openWhatsApp() {
      window.open("https://wa.me/6285347008226", "_blank");
    },
    showCategoryChart() {
      // Contoh data total
      const malnutritionTotal = this.nutritionsCount.malnutrition;
      const undernutritionTotal = this.nutritionsCount.undernutrition;
      const normalTotal = this.nutritionsCount.normal;
      const overnutritionTotal = this.nutritionsCount.overnutrition;
      const obesityTotal = this.nutritionsCount.obesity;

      // Menghitung persentase dari data total
      const totalData =
        malnutritionTotal +
        undernutritionTotal +
        normalTotal +
        overnutritionTotal +
        obesityTotal;

      const malnutritionPercentage = (
        (malnutritionTotal / totalData) *
        100
      ).toFixed(2); // Dibulatkan ke 2 desimal
      const undernutritionPercentage = (
        (undernutritionTotal / totalData) *
        100
      ).toFixed(2);
      const normalPercentage = ((normalTotal / totalData) * 100).toFixed(2);
      const overnutritionPercentage = (
        (overnutritionTotal / totalData) *
        100
      ).toFixed(2);
      const obesityPercentage = ((obesityTotal / totalData) * 100).toFixed(2);

      this.categoryChart.setOption({
        title: {
          text: "Status Gizi Anak",
          left: "center",
          top: "5%",
        },
        tooltip: {},
        xAxis: {
          data: [
            "Gizi Buruk",
            "Gizi Kurang",
            "Gizi Baik",
            "Gizi Lebih",
            "Obesitas",
          ],
        },
        yAxis: {},
        series: [
          {
            type: "bar",
            data: [
              {
                value: this.nutritionsCount.malnutrition,
                itemStyle: {
                  color: "#CC0000",
                },
                label: {
                  show: true, // Menampilkan label
                  position: "top", // Menempatkan label di atas bar
                  formatter: function () {
                    return malnutritionPercentage + "%"; // Menambahkan persentase ke label
                  },
                },
              },
              {
                value: this.nutritionsCount.undernutrition,
                itemStyle: {
                  color: "#FFFF00",
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function () {
                    return undernutritionPercentage + "%";
                  },
                },
              },
              {
                value: this.nutritionsCount.normal,
                itemStyle: {
                  color: "#00FF00",
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function () {
                    return normalPercentage + "%";
                  },
                },
              },
              {
                value: this.nutritionsCount.overnutrition,
                itemStyle: {
                  color: "#FFFF00",
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function () {
                    return overnutritionPercentage + "%";
                  },
                },
              },
              {
                value: this.nutritionsCount.obesity,
                itemStyle: {
                  color: "#CC0000",
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function () {
                    return obesityPercentage + "%";
                  },
                },
              },
            ],
          },
        ],
      });
      this.loading = false;
      window.addEventListener("resize", () => {
        this.categoryChart.resize();
      });
      this.categoryChart.on("click", () => {
        this.$router.push("/monitoring-gizi");
      });
    },
  },
};
</script>
<style>
.aabb {
  height: 400px;
  width: 1000px;
}
</style>
