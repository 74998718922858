function menus() {
  return [
    { subheader: "Utama", group_permissions: [] },
    { icon: "mdi-view-dashboard-outline", text: "Dashboard", route: "/" },
    {
      icon: "mdi-calculator-variant-outline",
      text: "Kalkulator Antropometri",
      route: "/kalkulator-antropometri",
    },
    {
      icon: "mdi-book-account-outline",
      text: "Antropometri Individu",
      route: "/antropometri-individu",
      can: "antropometri-individu-view",
    },
    {
      icon: "mdi-chart-box-outline",
      text: "Monitoring Gizi",
      route: "/monitoring-gizi",
      can: "monitoring-gizi-view",
    },
    {
      icon: "mdi-newspaper",
      text: "Nutrition information",
      route: "/informasi",
    },
    // { icon: 'mdi-home-city-outline', text: 'Sekolah', route: '/sekolah' },
    // { icon: 'mdi-card-account-details-outline', text: 'Siswa', route: '/siswa' },
    // { icon: 'mdi-card-account-details-outline', text: 'Wali Siswa', route: '/wali-siswa', role: 'admin' },
    // { icon: 'mdi-card-account-details-outline', text: 'Staff Sekolah', route: '/staff-sekolah', role: 'admin' },
    { divider: true },
    {
      subheader: "Master",
      group_permissions: [
        "siswa",
        "sekolah",
        "wali-siswa",
        "staf-sekolah",
        "staf-puskesmas",
        "puskesmas",
        "blog",
        "rekomendasi",
        "rekomendasi-dua-variabel",
        "kategori",
      ],
    },
    {
      icon: "mdi-database-outline",
      text: "Master Data",
      childs: [
        {
          icon: "mdi-home-city-outline",
          text: "Sekolah",
          route: "/sekolah",
          can: "sekolah-view",
        },
        {
          icon: "mdi-card-account-details-outline",
          text: "Siswa",
          route: "/siswa",
          can: "siswa-view",
        },
        {
          icon: "mdi-human-male-boy",
          text: "Wali Siswa",
          route: "/wali-siswa",
          can: "wali-siswa-view",
        },
        {
          icon: "mdi-badge-account-horizontal-outline",
          text: "Staf Sekolah",
          route: "/staf-sekolah",
          can: "staf-sekolah-view",
        },

        {
          icon: "mdi-hospital-building",
          text: "Puskesmas",
          route: "/puskesmas",
          can: "puskesmas-view",
        },
        {
          icon: "mdi-badge-account-outline",
          text: "Staf Puskesmas",
          route: "/staf-puskesmas",
          can: "staf-puskesmas-view",
        },
        {
          icon: "mdi-thought-bubble-outline",
          text: "Rekomendasi",
          route: "/rekomendasi",
          can: "rekomendasi-view",
        },
        {
          icon: "mdi-thought-bubble-outline",
          text: "Rekomendasi Dua Variabel",
          route: "/rekomendasi-dua-variabel",
          can: "rekomendasi-dua-variabel-view",
        },
      ],
    },
    {
      icon: "mdi-newspaper-variant-multiple-outline",
      text: "Blog",
      childs: [
        {
          icon: "mdi-post-outline",
          text: "List Blog",
          route: "/blog",
          can: "blog-view",
        },
        {
          icon: "mdi-tag-multiple-outline",
          text: "Kategori",
          route: "/kategori",
          can: "kategori-view",
        },
      ],
    },
    // { icon: 'mdi-calendar', text: 'Jadwal Pembelajaran', route: '/jadwal-pembelajaran', can: 'jadwal-pembelajaran-view' },
    // {
    //     icon: 'mdi-notebook-edit',
    //     text: 'Jurnal Guru',
    //     childs: [
    //         { icon: 'mdi-menu-right', text: 'Jurnal Harian Guru', route: '/jurnal-harian-guru', can: 'jurnal-harian-guru-view' },
    //         { icon: 'mdi-menu-right', text: 'Jurnal Kelas', route: '/jurnal-kelas', can: 'jurnal-kelas-view' },
    //         // { icon: 'mdi-menu-right', text: 'Absensi Siswa', route: '/absensi', can: 'absensi-view' },
    //     ]
    // },

    // {
    //     icon: 'history_edu',
    //     text: 'Persuratan & Izin',
    //     childs: [
    //         // { icon: 'mdi-menu-right', text: 'Jenis Surat', route: '/jenis-surat', can: 'jenis-surat-view' },
    //         // { icon: 'mdi-menu-right', text: 'Template Surat', route: '/template-surat', can: 'template-surat-view' },
    //         { icon: 'mdi-menu-right', text: 'Permohonan Surat', route: '/permohonan-surat', can: 'permohonan-surat-view' },
    //         { icon: 'mdi-menu-right', text: 'Surat Izin Siswa', route: '/perizinan-siswa', can: 'perizinan-siswa-view' },
    //         { icon: 'mdi-menu-right', text: 'Surat Izin Pegawai', route: '/perizinan-pegawai', can: 'perizinan-pegawai-view' },
    //     ]
    // },

    // {
    //     icon: 'school',
    //     text: 'Akademik',
    //     childs: [
    //         { icon: 'mdi-menu-right', text: 'Peserta Didik', route: '/peserta-didik', can: 'peserta-didik-view' },
    //         { icon: 'mdi-menu-right', text: 'Guru', route: '/guru', can: 'pendidik-tenaga-kependidikan-view' },
    //         { icon: 'mdi-menu-right', text: 'Tenaga Kependidikan', route: '/tenaga-kependidikan', can: 'pendidik-tenaga-kependidikan-view' },
    //         // { icon: 'mdi-menu-right', text: 'Rombongan Belajar', route: '/rombongan-belajar', can: 'rombel-view' },
    //         { icon: 'mdi-menu-right', text: 'Guru & Mapel', route: '/guru-mapel', can: 'mata-pelajaran-view' },
    //         { icon: 'mdi-menu-right', text: 'Blockgrant', route: '/blockgrant', can: 'blockgrant-view' },
    //     ]
    // },
    // {
    //     icon: 'apartment',
    //     text: 'DUDI & Prakerin',
    //     childs: [
    //         { icon: 'apartment', text: 'Dunia Usaha/Industri', route: '/relasi-dudi', can: 'relasi-dudi-view' },
    //         { icon: 'assignment', text: 'MoU Kerjasama', route: '/mou-kerjasama', can: 'mou-kerjasama-view' },
    //         { icon: 'design_services', text: 'Prakerin Siswa', route: '/prakerin-siswa', can: 'prakerin-siswa-view' },
    //     ]
    // },
    // {
    //     icon: 'mdi-library',
    //     text: 'Perpustakaan',
    //     childs: [
    //         { icon: 'book', text: 'Buku', route: '/buku', can: 'buku-view' },
    //         { icon: 'mdi-book-multiple', text: 'Peminjaman', route: '/peminjaman-buku', can: 'peminjaman-buku-view' },
    //     ]
    // },
    // { divider: true },
    // { subheader: 'Laporan', group_permissions: [] },
    // {
    //     icon: 'mdi-file-document-multiple',
    //     text: 'Laporan',
    //     childs: [
    //         { icon: 'mdi-calendar-text', text: 'Absensi Siswa', route: '/absensi', can: 'absensi-view' },
    //     ]
    // },
    // { divider: true },
    // { subheader: 'Master', group_permissions: ['Admin'] },
    // {
    //     icon: 'home_work',
    //     text: 'Profile Sekolah',
    //     childs: [
    //         { icon: 'mdi-menu-right', text: 'Identitas', route: '/sekolah', can: 'sekolah-view' },
    //         { icon: 'mdi-menu-right', text: 'Data Pelengkap', route: '/data-pelengkap', can: 'data-pelengkap-view' },
    //         { icon: 'mdi-menu-right', text: 'Kontak', route: '/kontak', can: 'kontak-view' },
    //         { icon: 'mdi-menu-right', text: 'Data Periodik', route: '/data-periodik', can: 'data-periodik-view' },
    //         { icon: 'mdi-menu-right', text: 'Sanitasi Sekolah', route: '/sanitasi-sekolah', can: 'sanitasi-sekolah-view' },
    //         { icon: 'mdi-menu-right', text: 'Prasarana', route: '/prasarana', can: 'prasarana-view' },
    //         { icon: 'mdi-menu-right', text: 'Sarana', route: '/sarana', can: 'sarana-view' },
    //     ]
    // },
    // {
    //     icon: 'mdi-database',
    //     text: 'Master Data',
    //     childs: [
    //         { icon: 'mdi-menu-right', text: 'Tahun Ajaran', route: '/tahun-ajaran', can: 'tahun-ajaran-view' },
    //         { icon: 'mdi-menu-right', text: 'Kompetensi Keahlian', route: '/kompetensi-keahlian', can: 'kompetensi-keahlian-view' },
    //         { icon: 'mdi-menu-right', text: 'Rombel', route: '/rombel', can: 'rombel-view' },
    //         { icon: 'mdi-menu-right', text: 'Mata Pelajaran', route: '/mata-pelajaran', can: 'mata-pelajaran-view' },
    //         { icon: 'mdi-menu-right', text: 'Sanitasi', route: '/sanitasi', can: 'sanitasi-view' },
    //     ]
    // },

    // // ===== //

    // // { divider: true },
    // // { subheader: 'Laporan', group_permissions: ['Admin'] },
    // // { icon: 'person', text: 'Siswa', route: '/users', can: 'users-view' },
    // { divider: true },

    // Autocrud
    // #Autocrud#
    { divider: true },
    {
      subheader: "Pengaturan",
      group_permissions: [
        /*'Admin'*/
        "admin",
      ],
    },
    {
      icon: "mdi-account-outline",
      text: "Users",
      route: "/users",
      role: "admin",
      // can: 'users-view'
    },
    {
      icon: "admin_panel_settings",
      text: "Roles & Permissions",
      route: "/roles",
      role: "Admin",
    },
    // {
    //   icon: "notifications_active",
    //   text: "Notifications",
    //   route: "/notifications",
    //   can: "notification-view",
    //   actions: {
    //     chip: rootState.auth.notifications.unRead.length,
    //   },
    // },
    // { icon: 'help', text: 'Panduan', route: '/panduan', auth: true },
    // { icon: 'change_history', text: 'Activity Log', route: '/activity-log', can: 'activity-log-view' },
  ];
}

export default menus;
