"use strict";

import Vue from "vue";
import axios from "axios";
// import vm from '@/app'
// import store from '@/store'
import router from "@/router";

const getToken = () => {
  const token = localStorage.getItem("token");

  return token ? `Bearer ${token}` : "";
};

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: "https://giziweb.barqun.com/api",
  // baseURL: 'http://biss-project.test/api',
  headers: {
    "Content-Type": "application/json",
  },
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.defaults.headers.common["Authorization"] = getToken();
_axios.defaults.timeout = 60000;

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error

    if (error.response.status === 401) {
      console.log(2);
      // vm.$vs.loading({ background: '#7367f0', color: 'rgb(255, 255, 255)' })
      router.replace({ name: "login" });
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
