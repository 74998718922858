<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-text-field
          clearable
          v-model="user.name"
          placeholder="Masukan nama wali siswa.."
          label="Nama Wali Siswa"
          :rules="rules.nameRules"
          autofocus
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          clearable
          label="Hubungan Keluarga"
          v-model="form.family_relationship"
          :rules="rules.familyRelationshipRules"
          :items="['AYAH', 'IBU', 'WALI SISWA']"
          prepend-inner-icon="mdi-family-tree"
          placeholder="Pilih hubungan keluarga"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          clearable
          v-model="form.last_education"
          :items="lastEducations"
          :rules="rules.lastEducationRules"
          label="Pilih Pendidikan Terakhir"
          placeholder="Pilih pendidikan terakhir"
          prepend-inner-icon="mdi-school-outline"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          clearable
          v-model="form.occupation"
          :items="occupations"
          placeholder="Pilih pekerjaan"
          label="Pilih Pekerjaan"
          :rules="rules.occupationRules"
          prepend-inner-icon="mdi-briefcase-outline"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          clearable
          v-model="form.income"
          :rules="rules.incomeRules"
          :items="incomes"
          placeholder="Pilih pendapatan per bulan"
          label="Pilih Pendapatan Per Bulan"
          prepend-inner-icon="mdi-account-cash-outline"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          clearable
          v-model="form.address"
          placeholder="Masukan alamat.."
          :rules="rules.addressRules"
          label="Alamat"
          prepend-inner-icon="mdi-map-marker-outline"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          clearable
          v-model="user.email"
          label="Alamat Email"
          placeholder="Masukan alamat email.."
          :rules="rules.emailRules"
          hint="Email dan password digunakan untuk login"
          prepend-inner-icon="mdi-email-outline"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          clearable
          v-model="form.phone_number"
          type="number"
          placeholder="Masukan nomor telepon.."
          :rules="rules.phoneNumberRules"
          label="No Telepon"
          prepend-inner-icon="mdi-phone-outline"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          clearable
          v-model="user.password"
          placeholder="Masukan password.."
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Password"
          hint="Panjang karakter harus lebih dari 8 karakter"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          clearable
          label="Pilih Status Akun"
          v-model="user.is_active"
          :items="isActiveStatuses"
          :rules="rules.isActiveRules"
          item-text="text"
          item-value="is_active"
          placeholder="Pilih status akun"
          hint="Jika akun tidak aktif maka akun tersebut tidak dapat login"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import VFileInput from '@/components/FormInput/VFileInput'
export default {
  props: ["form"],
  components: {
    // VFileInput
  },
  data() {
    return {
      lastEducations: [
        "TIDAK SEKOLAH",
        "TIDAK TAMAT SD/MI",
        "TAMAT SD/MI",
        "TAMAT SMP/MTS",
        "TAMAT SMA/ MAN",
        "TAMAT DI, DII, DIII",
        "TAMAT DIV / PERGURUAN TINGGI",
      ],
      occupations: [
        "TIDAK BEKERJA",
        "PNS/TNI/POLRI/BUNM/ BUMD",
        "PEGAWAI SWASTA",
        "WIRASWASTA",
        "PETANI/ BURUH TANI",
        "NELAYAN",
        "BUH/ SOPIR/ OJEK/PEMBANTU",
        "LAINNYA (tuliskan)",
      ],
      incomes: [
        "< Rp. 500.000",
        "Rp. 500.000 - 1.500.000",
        "Rp. 1.500.000 - 3.000.000",
        "Rp. 3.000.000 - 4.500.000",
        "Rp. > 4.500.000",
      ],
      isActiveStatuses: [
        {
          is_active: 1,
          text: "AKTIF",
        },
        {
          is_active: 0,
          text: "TIDAK AKTIF",
        },
      ],
      rules: {
        nameRules: [
          (v) => !!v || "Kolom nama wali siswa tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        familyRelationshipRules: [
          (v) => !!v || "Kolom hubungan keluarga tidak boleh kosong!",
        ],
        lastEducationRules: [
          (v) => !!v || "Kolom pendidikan terakhir tidak boleh kosong!",
        ],
        occupationRules: [(v) => !!v || "Kolom pekerjaan tidak boleh kosong!"],
        incomeRules: [
          (v) => !!v || "Kolom pendapatan per bulan tidak boleh kosong!",
        ],
        addressRules: [
          (v) => !!v || "Kolom alamat tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        emailRules: [
          (v) => !!v || "Kolom alamat email tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        phoneNumberRules: [
          (v) => !!v || "Kolom nomor telepon tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        isActiveRules: [
          (v) => v <= 1 || "Kolom status akun tidak boleh kosong!",
        ],
      },
      user: {},
      show1: false,
      email: null,
      password: null,
    };
  },
  methods: {
    // isActiveStatusToBoolean() {
    //   if (this.form.is_active === "Aktif") {
    //     this.form.is_active = true;
    //   } else {
    //     this.form.is_active = false;
    //   }
    // },
  },
  watch: {
    "form.user": {
      handler(val) {
        this.user = val || {};
      },
      immediate: true, // Add this line to trigger the watch immediately
    },
    user: {
      handler(val) {
        this.form.user = val;
      },
      immediate: true, // Add this line to trigger the watch
    },
    form: {
      handler(val) {
        console.log(val.is_active);
      },
    },
  },
};
</script>
<style lang=""></style>
