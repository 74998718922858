<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem, save }">
          <ModulForm :form="editedItem" @save="save" />
        </template>

        <template #filter="{ load, params }">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Asal Sekolah"
                autocomplete
                dense
                :items="schools"
                :form="params"
                clearable
                chips
                small-chips
                item-text="name"
                item-value="id"
                name="filterAsalSekolah"
                @input="load"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Kelas"
                autocomplete
                dense
                :items="[
                  'KELAS I',
                  'KELAS II',
                  'KELAS III',
                  'KELAS IV',
                  'KELAS V',
                  'KELAS VI',
                ]"
                :form="params"
                clearable
                chips
                small-chips
                name="filterKelas"
                @input="load"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Jenis Kelamin"
                autocomplete
                dense
                chips
                small-chips
                :items="['LAKI-LAKI', 'PEREMPUAN']"
                :form="params"
                clearable
                name="filterJenisKelamin"
                @input="load"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Wali Siswa"
                item-text="name"
                autocomplete
                chips
                clearable
                small-chips
                dense
                item-value="id"
                :items="familyMembers"
                :form="params"
                name="filterWaliMurid"
                @input="load"
              ></BarqunVSelect>
            </v-col>
          </v-row>
        </template>

        <template #name="{ item }">
          <div class="pa-2 font-weight-bold">
            <p>{{ item.name }}</p>
            <p>
              <v-chip dark small color="green">{{ item.nisn }}</v-chip>
            </p>
            <p>
              <v-chip dark small color="blue lighten-2">
                <v-avatar left>
                  <v-icon>mdi mdi-home-city-outline</v-icon>
                </v-avatar>
                {{ item.school.name }}
              </v-chip>
            </p>
            <p>
              <v-chip dark small color="gray">{{ item.school_class }}</v-chip>
            </p>
            <p>
              <v-chip
                dark
                small
                :color="item.sex === 'LAKI-LAKI' ? 'blue' : 'pink'"
              >
                <v-icon
                  >mdi mdi-gender-{{
                    item.sex === "LAKI-LAKI" ? "male" : "female"
                  }}
                </v-icon>
                {{ item.sex }}
              </v-chip>
            </p>
          </div>
        </template>

        <template #anthropometric_measurements_count="{ item }">
          <v-chip
            class="ma-2 font-weight-bold"
            color="blue-grey"
            text-color="white"
          >
            <v-avatar left>
              <v-icon>mdi-checkbook</v-icon>
            </v-avatar>
            {{ item.anthropometric_measurements_count }}
          </v-chip>
        </template>

        <template #latest_anthropometric_measurement="{ item }">
          <span v-if="item.anthropometric_measurements_count > 0">
            <NutritionalCategory
              :categoryName="
                item.latest_anthropometric_measurement.category_name
              "
            ></NutritionalCategory>
          </span>

          <span v-else>
            <v-chip class="font-weight-bold" color="gray darken-2" small dark
              ><v-icon small left> mdi-alert-outline </v-icon>Pemeriksaan Belum
              Ada</v-chip
            >
          </span>
        </template>

        <template #action="{ item }">
          <div>
            <v-tooltip
              :disabled="$vuetify.breakpoint.mobile"
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="primary rounded-lg"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="show(item)"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>Detail</span>
            </v-tooltip>
          </div>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";
import { mapActions, mapState } from "vuex";
import BarqunVSelect from "@/components/FormInput/BarqunVSelect";
import NutritionalCategory from "../../components/NutritionalCategory.vue";
export default {
  components: {
    DataTable,
    ModulForm,
    BarqunVSelect,
    NutritionalCategory,
  },
  data() {
    return {
      familyMembers: [],
      schools: [],
      datatable: {
        moduleName: "siswa",
        btnAdd: false,
        formData: true,
        hasFilter: this.userRole("admin") ? true : false,
        headers: [
          { text: "No", value: "num", sortable: false },
          { text: "Nama Siswa", value: "name", slot: true },
          {
            text: "Wali Siswa",
            value: "family_member.user.name",
            sortable: false,
          },
          {
            text: "Total Pemeriksaan",
            value: "anthropometric_measurements_count",
            slot: true,
            sortable: false,
          },
          {
            text: "Pemeriksaan Terakhir",
            value: "latest_anthropometric_measurement",
            slot: true,
            sortable: false,
          },
          {
            text: "Actions",
            value: "action",
            align: "center",
            sortable: false,
            slot: true,
          },
        ],
        params: {
          with: ["school", "familyMember", "familyMember.user"],
          sortby: "name",
          sortbydesc: "asc",
          student_id: null,
        },
      },
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  created() {
    console.log(this.user);
    if (this.userRole("wali siswa") && this.user.siswa && this.user.siswa.id) {
      this.datatable.params.student_id = this.user.siswa.id;
    }

    this.$http("sekolah", {
      params: {
        sortby: "name",
        all: true,
      },
    })
      .then((res) => {
        this.schools = res.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    this.$http("wali-siswa", {
      params: {
        all: true,
        with: [
          "user",
          "user.roles",
          "user.permissions",
          "user.roles.permissions",
        ],
      },
    })
      .then((res) => {
        this.familyMembers = res.data.map((item) => {
          return {
            id: item.id,
            user_id: item.user_id,
            name: item.user.name,
          };
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
  methods: {
    show(item) {
      const detailUrl = `/antropometri-individu/detail/${item.id}`;

      // Navigate to the detail page using Vue Router
      this.$router.push(detailUrl);
    },
    ...mapActions("base", ["saveData"]),
  },
};
</script>
