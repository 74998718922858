<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <v-text-field
          v-model="form.name"
          :rules="[(v) => !!v || 'Item is required']"
          label="Nama"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="12">
        <v-text-field
          v-model="form.email"
          :rules="[(v) => !!v || 'Item is required']"
          label="Email"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="12">
        <v-text-field
          v-model="form.password"
          type="password"
          label="Password"
          :rules="[(v) => !!v || 'Item is required']"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-autocomplete
          dense
          clearable
          chips
          :rules="[(v) => !!v || 'Item is required']"
          label="Role"
          v-model="form.role"
          item-text="name"
          item-value="name"
          :items="role"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-select
          dense
          clearable
          chips
          label="Status"
          v-model="form.is_active"
          item-text="name"
          item-value="is_active"
          :items="status"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="12">
        <VFileInput :form="form" label="File Foto" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VFileInput from "@/components/FormInput/VFileInput";
import { mapState } from "vuex";
export default {
  props: ["form"],
  components: {
    VFileInput,
  },
  data() {
    return {
      role: null,
      status: [
        { name: "Aktif", is_active: 1 },
        { name: "Tidak Aktif", is_active: 0 },
      ],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    // ...mapMutations("base", ["DEL_ERRORS"]),
  },
  created() {
    // this.$http("role", {)
    this.$http("role")
      .then(({ data }) => {
        this.role = data.data.map(({ name }) => ({ name }));
        console.log(this.role);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
};
</script>
<style lang=""></style>
