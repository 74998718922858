var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"2"}},[_c('DataTable',_vm._b({scopedSlots:_vm._u([{key:"modal-form",fn:function(ref){
var editedItem = ref.editedItem;
var save = ref.save;
return [_c('ModulForm',{attrs:{"form":editedItem},on:{"save":save}})]}},{key:"filter",fn:function(ref){
var load = ref.load;
var params = ref.params;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('BarqunVSelect',{attrs:{"label":"Asal Sekolah","autocomplete":"","dense":"","items":_vm.schools,"form":params,"clearable":"","chips":"","small-chips":"","item-text":"name","item-value":"id","name":"filterAsalSekolah"},on:{"input":load}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('BarqunVSelect',{attrs:{"label":"Kelas","autocomplete":"","dense":"","items":[
                'KELAS I',
                'KELAS II',
                'KELAS III',
                'KELAS IV',
                'KELAS V',
                'KELAS VI' ],"form":params,"clearable":"","chips":"","small-chips":"","name":"filterKelas"},on:{"input":load}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('BarqunVSelect',{attrs:{"label":"Jenis Kelamin","autocomplete":"","dense":"","chips":"","small-chips":"","items":['LAKI-LAKI', 'PEREMPUAN'],"form":params,"clearable":"","name":"filterJenisKelamin"},on:{"input":load}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('BarqunVSelect',{attrs:{"label":"Wali Siswa","item-text":"name","autocomplete":"","chips":"","small-chips":"","dense":"","clearable":"","item-value":"id","items":_vm.familyMembers,"form":params,"name":"filterWaliMurid"},on:{"input":load}})],1)],1)]}},{key:"name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"pa-2 font-weight-bold"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":"success"}},[_vm._v(_vm._s(item.nisn))])],1),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":"gray"}},[_vm._v(_vm._s(item.school_class))])],1),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":item.sex === 'LAKI-LAKI' ? 'blue' : 'pink'}},[_c('v-icon',[_vm._v("mdi mdi-gender-"+_vm._s(item.sex === "LAKI-LAKI" ? "male" : "female")+" ")]),_vm._v(" "+_vm._s(item.sex)+" ")],1)],1)])]}},{key:"actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(item.sync == 'presync')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-time-four-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Belum Sinkron Server")])]),_vm._v(" "+_vm._s(item.uuid)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){_vm.notify(_vm.excludeProps(item, ['school', 'family_members']))}}},[_vm._v(" mdi-bell ")])]}}])},'DataTable',_vm.datatable,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }