<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #filter="{ load, params }">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Asal Instansi"
                autocomplete
                dense
                :items="publicHealthCenters"
                :form="params"
                clearable
                chips
                small-chips
                item-text="name"
                item-value="id"
                name="filterAsalInstansi"
                @input="load"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Status Karyawan"
                autocomplete
                dense
                :items="['PNS', 'HONORER']"
                :form="params"
                clearable
                chips
                small-chips
                name="filterStatusKaryawan"
                @input="load"
              ></BarqunVSelect>
            </v-col>
          </v-row>
        </template>

        <template #actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.sync == 'presync'"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                small
                class="mr-2"
              >
                mdi-clock-time-four-outline
              </v-icon>
            </template>
            <span>Belum Sinkron Server</span>
          </v-tooltip>
          <v-icon color="success" small class="mr-2" @click="notify(item)">
            mdi-bell
          </v-icon>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";
import BarqunVSelect from "@/components/FormInput/BarqunVSelect";

export default {
  components: {
    DataTable,
    ModulForm,
    BarqunVSelect,
  },
  data() {
    return {
      publicHealthCenters: [],
      datatable: {
        moduleName: "StafPuskesmas",
        title: "Daftar Staf Puskesmas",
        hasFilter: true,
        headers: [
          { text: "No", value: "num" },
          { text: "Nama Staf", value: "user.name", slot: true },
          {
            text: "Asal Instansi",
            value: "public_health_center.name",
            sortable: false,
          },
          { text: "Status Karyawan", value: "staff_status" },
          { text: "Jabatan", value: "position" },
          { text: "No Telepon", value: "phone_number" },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ],
        params: {
          with: ["user", "publicHealthCenter"],
        },
      },
    };
  },
  mounted() {
    this.$http("puskesmas", {
      params: {
        all: true,
      },
    })
      .then((res) => {
        this.publicHealthCenters = res.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
};
</script>
