<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable
        @saved="saved"
        :moduleName="moduleName"
        :headers="headers"
        :slots="slots"
        :formData="true"
      >
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #avatar="{ item }">
          <v-list-item-avatar>
            <v-img-preview
              width="50"
              :src="
                item.avatar_link
                  ? item.avatar_link
                  : require('../../assets/persons/person 3.jpg')
              "
              alt="Fotomu"
            />
          </v-list-item-avatar>
        </template>

        <template #is_active="{ item }">
          <v-chip
            :color="`${
              item.is_active === 1 ? `green lighten-2` : `red`
            } font-weight-bold`"
            small
            dark
          >
            {{ item.is_active === 1 ? "Aktif" : "Tidak Aktif" }}
          </v-chip>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";
import { mapActions } from "vuex";
export default {
  components: {
    DataTable,
    ModulForm,
  },
  data() {
    return {
      moduleName: "Users",
      slots: ["avatar"],
      headers: [
        { text: "ID", value: "id" },
        // { text: "Avatar", value: "avatar" },
        { text: "Nama", value: "name" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        {
          text: "Status Akun",
          value: "is_active",
          slot: true,
        },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
    };
  },
  mounted() {
    // console.log(this.$swal);
  },
  methods: {
    ...mapActions("auth", ["auth"]),
    saved() {
      this.auth();
    },
    notify(item) {
      this.$swal({
        title: "Send Notification",
        text: "Are you sure want to send notification to this user?",
        icon: "warning",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          this.$http(`users/notify/${item.id}`);
        },
      });
    },
  },
};
</script>
