<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <v-card-title class="justify-center">Detail Data</v-card-title>

      <v-card-text>
        <v-tabs v-model="tab" centered icons-and-text grow show-arrows>
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            Detail Siswa
            <v-icon color="blue">mdi-card-account-details-outline</v-icon>
          </v-tab>
          <v-tab>
            Grafik Keseluruhan
            <v-icon color="blue">mdi-chart-bell-curve-cumulative</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-text
                ><v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-simple-table
                      v-if="studentDetails !== null"
                      class="custom-table"
                    >
                      <template v-slot:default>
                        <tbody class="text-uppercase">
                          <tr>
                            <th class="text-left">Nama Siswa</th>
                            <td>{{ studentDetails.name }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">
                              Nomor Induk Siswa Nasional
                            </th>
                            <td>{{ studentDetails.nisn }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Tanggal Lahir</th>
                            <td>
                              {{
                                new Date(
                                  studentDetails.date_of_birth
                                ).toLocaleDateString("id-ID", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Jenis Kelamin</th>
                            <td>{{ studentDetails.sex }}</td>
                          </tr>

                          <tr>
                            <th class="text-left">Asal Sekolah</th>
                            <td>{{ studentDetails.school.name }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Kelas</th>
                            <td>{{ studentDetails.school_class }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Wali Siswa</th>
                            <td>
                              {{ studentDetails.family_member.user.name }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-simple-table
                      v-if="studentDetails !== null"
                      class="custom-table"
                    >
                      <template v-slot:default>
                        <tbody class="text-uppercase">
                          <tr>
                            <th class="text-left">Nama Wali</th>
                            <td>
                              {{ studentDetails.family_member.user.name }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Hubungan Keluarga</th>
                            <td>
                              {{
                                studentDetails.family_member.family_relationship
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Pendidikan Terakhir</th>
                            <td>
                              {{ studentDetails.family_member.last_education }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Pekerjaan</th>
                            <td>
                              {{ studentDetails.family_member.occupation }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Pendapatan Per Bulan</th>
                            <td>{{ studentDetails.family_member.income }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">No Telepon</th>
                            <td>
                              {{ studentDetails.family_member.phone_number }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Alamat Tinggal</th>
                            <td>{{ studentDetails.family_member.address }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card>
              <v-card-text>
                <Chart
                  ref="chartComponent"
                  :data="studentAnthropometricMeasurements"
                ></Chart>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <div class="d-flex justify-end">
          <v-btn
            class="mt-2"
            color="green"
            dark
            depressed
            @click="fetchRecommendations"
          >
            Daftar Saran & Rekomendasi
          </v-btn>
        </div>
      </v-card-text>

      <FormRekomendasi
        ref="formRecommendation"
        :recommendations="recommendations"
      ></FormRekomendasi>

      <v-divider></v-divider>

      <DataTable v-bind="datatable" ref="datatable">
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #measurement_date="{ item }">
          {{
            new Date(item.measurement_date).toLocaleDateString("id-ID", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          }}
        </template>

        <template #umur="{ item }">
          {{
            humanReadableAge(item.student.date_of_birth, item.measurement_date)
          }}
        </template>

        <template #z_score="{ item }">
          {{ item.z_score.toFixed(2) }}
        </template>

        <template #catgory_name="{ item }">
          <NutritionalCategory
            :categoryName="item.category_name"
          ></NutritionalCategory>
        </template>

        <template #modal-chart="{ editedItem, dialog }">
          <Chart v-if="dialog" ref="chartComponent" :data="editedItem"></Chart>
        </template>

        <template #actions="{ item }">
          <v-tooltip
            :disabled="$vuetify.breakpoint.mobile"
            top
            color="yellow darken-3"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="yellow darken-3"
                v-bind="attrs"
                v-on="on"
                @click="showGraph(item)"
              >
                mdi-chart-areaspline
              </v-icon>
            </template>
            <span class="font-weight-black">Grafik</span>
          </v-tooltip>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";
import Chart from "@/components/LineChart";
import ModulForm from "./Form.vue";
import FormRekomendasi from "./FormRekomendasi.vue";
import { mapState } from "vuex";
import { humanReadableAgeFromMeasurementDate } from "../../helpers/student";
import NutritionalCategory from "../../components/NutritionalCategory.vue";

export default {
  components: {
    ModulForm,
    DataTable,
    Chart,
    FormRekomendasi,
    NutritionalCategory,
  },
  data() {
    return {
      tab: null,
      studentAnthropometricMeasurements: [],
      chartInstance: null,
      recommendations: {
        recommendation: [],
        twoVariables: {
          improvements: [],
          noChanges: [],
          deteriorations: [],
        },
      },
      datatable: {
        moduleName: "AntropometriIndividu",
        url: "bmi",
        formData: true,
        btnAdd: this.userRole("admin") ? true : false,
        title: "Daftar Pemeriksaan Gizi",
        headers: [
          { text: "no", value: "num", sortable: false },
          { text: "Tanggal Pengukuran", value: "measurement_date", slot: true },
          { text: "Berat", value: "weight" },
          { text: "Tinggi", value: "height" },
          { text: "Umur", value: "umur", slot: true, sortable: false },
          { text: "IMT/U", value: "imt" },
          { text: "Z-Score", value: "z_score", slot: true },
          {
            text: "Kategori Status Gizi",
            value: "catgory_name",
            align: "center",
            sortable: false,
            slot: true,
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ],
        params: {
          with: [
            "student",
            "student.school",
            "student.familyMember",
            "student.familyMember.user",
          ],
          filterStudentID: this.$route.params.id,
          sortby: "measurement_date",
        },
      },
      studentDetails: null,
    };
  },
  computed: {
    ...mapState("bmi", ["bmiData"]),
    ...mapState("auth", ["user"]),
  },
  mounted() {
    this.$http(`siswa/${this.$route.params.id}`, {
      params: {
        with: [
          "school",
          "anthropometricMeasurements",
          "familyMember",
          "familyMember.user",
        ],
      },
    })
      .then((res) => {
        this.studentDetails = res.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    this.$http("bmi", {
      params: {
        with: ["student"],
        filterStudentID: this.$route.params.id,
        sortby: "measurement_date",
        sortbydesc: "desc",
      },
    })
      .then((res) => {
        this.studentAnthropometricMeasurements = res.data.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
  methods: {
    showGraph(item) {
      this.$refs.datatable.showItem(item);
    },
    fetchRecommendations() {
      this.$refs.formRecommendation.dialog = true;

      this.$http(`siswa/${this.$route.params.id}`, {
        params: {
          with: [
            "school",
            "anthropometricMeasurements",
            "familyMember",
            "familyMember.user",
          ],
        },
      })
        .then((res) => {
          this.studentDetails = res.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      this.$http("bmi", {
        params: {
          with: ["student"],
          filterStudentID: this.$route.params.id,
          sortby: "measurement_date",
          sortbydesc: "desc",
        },
      })
        .then((res) => {
          this.studentAnthropometricMeasurements = res.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      this.$http("rekomendasi", {
        params: {
          all: true,
          filterStatusKategori:
            this.studentDetails.latest_anthropometric_measurement.category_name,
        },
      })
        .then((res) => {
          this.recommendations.recommendation = res.data;
        })
        .catch((error) => {
          console.log("Error fetching data: ", error);
        });

      // improvements
      this.$http("rekomendasi-dua-variabel", {
        params: {
          filterDuaVariabel: true,
          type: "PENINGKATAN",
          variabel1: this.studentAnthropometricMeasurements[1].category_name,
          variabel2: this.studentAnthropometricMeasurements[0].category_name,
        },
      })
        .then((res) => {
          this.recommendations.twoVariables.improvements = res.data.data;
        })
        .catch((error) => {
          console.log("Error fetching data: ", error);
        });

      // deteriorations
      this.$http("rekomendasi-dua-variabel", {
        params: {
          filterDuaVariabel: true,
          type: "PENURUNAN",
          variabel1: this.studentAnthropometricMeasurements[1].category_name,
          variabel2: this.studentAnthropometricMeasurements[0].category_name,
        },
      })
        .then((res) => {
          this.recommendations.twoVariables.deteriorations = res.data.data;
        })
        .catch((error) => {
          console.log("Error fetching data: ", error);
        });

      // no changes
      this.$http("rekomendasi-dua-variabel", {
        params: {
          filterDuaVariabel: true,
          type: "TIDAK ADA PERUBAHAN",
          variabel1: this.studentAnthropometricMeasurements[1].category_name,
          variabel2: this.studentAnthropometricMeasurements[0].category_name,
        },
      })
        .then((res) => {
          this.recommendations.twoVariables.noChanges = res.data.data;
        })
        .catch((error) => {
          console.log("Error fetching data: ", error);
        });
    },
    humanReadableAge(dateOfBirth, measurementDate) {
      return humanReadableAgeFromMeasurementDate(dateOfBirth, measurementDate);
    },
  },
};
</script>

<style>
.custom-table {
  border: 1px solid #a7a6a6;
}
.custom-table tbody tr {
  border: 1px solid #a1a1a1;
}
.custom-table th,
.custom-table td {
  border: 1px solid #a5a5a5;
}

.border {
  border-bottom: 1px solid rgb(187, 187, 187);
}
.chart {
  width: 100%;
  height: 600px;
}
</style>
