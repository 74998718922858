<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title class="justify-center"
        >Daftar Saran & Rekomendasi Berdasarkan Gizi Terakhir</v-card-title
      >

      <v-tabs
        v-model="recommendationTab"
        centered
        icons-and-text
        grow
        show-arrows
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab>
          Saran ({{ recommendations.recommendation.length }})
          <v-icon color="blue">mdi-thought-bubble-outline</v-icon>
        </v-tab>
        <v-tab>
          Peningkatan ({{ recommendations.twoVariables.improvements.length }})
          <v-icon color="green">mdi-trending-up</v-icon>
        </v-tab>
        <v-tab>
          Tidak Ada Perubahan ({{
            recommendations.twoVariables.noChanges.length
          }})
          <v-icon color="yellow">mdi-trending-neutral</v-icon>
        </v-tab>
        <v-tab>
          Penurunan ({{ recommendations.twoVariables.deteriorations.length }})
          <v-icon color="red">mdi-trending-down</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="recommendationTab">
        <v-tab-item>
          <v-card>
            <v-card-text
              ><v-simple-table fixed-header>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(
                        recommendation, index
                      ) in recommendations.recommendation"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td class="text-wrap">
                        {{ recommendation.category_name }}
                      </td>
                      <td class="text-wrap">
                        <span v-html="recommendation.content"></span>
                      </td>
                    </tr>
                  </tbody>
                </template> </v-simple-table
            ></v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card-text
              ><v-simple-table fixed-header>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(recommendation, index) in recommendations
                        .twoVariables.improvements"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td class="text-wrap">
                        {{ recommendation.type }}
                      </td>
                      <td class="text-wrap">
                        <span v-html="recommendation.content"></span>
                      </td>
                    </tr>
                  </tbody>
                </template> </v-simple-table
            ></v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card-text
              ><v-simple-table fixed-header>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(recommendation, index) in recommendations
                        .twoVariables.noChanges"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td class="text-wrap">
                        <span v-html="recommendation.content"></span>
                      </td>
                    </tr>
                  </tbody>
                </template> </v-simple-table
            ></v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card-text
              ><v-simple-table fixed-header>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(recommendation, index) in recommendations
                        .twoVariables.deteriorations"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td class="text-wrap">
                        {{ recommendation.category_name }}
                      </td>
                      <td class="text-wrap">
                        <span v-html="recommendation.content"></span>
                      </td>
                    </tr>
                  </tbody>
                </template> </v-simple-table
            ></v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["recommendations"],
  data() {
    return {
      recommendationTab: null,
      dialog: false,
    };
  },
};
</script>
