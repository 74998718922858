<template>
  <v-container>
    <v-card :loading="loading" elevation="2" class="rounded-xl">
      <v-card-title class="justify-center">Detail Monitoring Gizi</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <NutritionalSchoolAlert
              :nutritionsCount="nutritionsCount"
              v-if="showNutritionalSchoolAlert"
            ></NutritionalSchoolAlert>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <NutritionalPublicHealthCenterAlert
              :nutritionsCount="nutritionsCount"
              v-if="
                showNutritionalPublicHealthCenterAlert ||
                (this.userRole('puskesmas') && !this.userRole('admin'))
              "
            >
            </NutritionalPublicHealthCenterAlert>
          </v-col>

          <v-col md="6" sm="12">
            <v-select
              v-if="
                this.userRole('admin') ||
                (!this.userRole('guru') && !this.userRole('puskesmas'))
              "
              :items="[
                'ALL',
                'DAERAH',
                'SEKOLAH',
                'JENIS KELAMIN',
                'PUSKESMAS',
              ]"
              v-model="selected"
              @change="filter"
              label="Filter"
              append-icon="mdi-filter"
              dense
              outlined
            ></v-select>
            <v-row>
              <v-col md="12" sm="12" v-if="showSchoolDropdown">
                <v-select
                  :items="schools"
                  label="Pilih Sekolah"
                  item-text="name"
                  item-value="id"
                  @change="filterSchool"
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col md="12" sm="12" v-if="showSexDropdown">
                <v-select
                  :items="['LAKI-LAKI', 'PEREMPUAN']"
                  label="Pilih Jenis Kelamin"
                  @change="filterSex"
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col md="12" sm="12" v-if="showPublicHealthCenterDropdown">
                <v-select
                  :items="publicHealthCenters"
                  label="Pilih Puskesmas"
                  item-text="name"
                  item-value="id"
                  @change="filterPublicHealthCenter"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col sm="12" md="6" v-if="showDistrictDropdown">
                <v-select
                  :items="districts"
                  @change="filterDistrict"
                  item-text="name"
                  item-value="id"
                  label="Pilih Kecamatan"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col sm="12" md="6" v-if="showVillageDropdown">
                <v-select
                  :items="villages"
                  @change="filterVillage"
                  item-text="name"
                  item-value="id"
                  label="Pilih Kelurahan"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div
                  class="d-flex flex-column align-center justify-center mt-5"
                  v-if="!pieInstance"
                >
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <p class="mt-2">Loading Chart</p>
                </div>
                <div ref="pie" class="chart"></div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" sm="12">
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-card class="pa-5">
                  <div class="d-flex align-center">
                    <button
                      type="button"
                      class="blue darken-2 pa-2 rounded-xl mr-2"
                    >
                      <v-icon dark large
                        >mdi mdi-card-account-details-outline</v-icon
                      >
                    </button>
                    <div>
                      <div v-if="loading">
                        <v-progress-circular
                          :size="25"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <h4 class="text-h6 font-weight-bold" v-else>
                        {{ this.nutritionsCount.students_count }}
                      </h4>
                      <h4 class="text-subtitle-1">Jumlah Siswa</h4>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-card class="pa-5">
                  <div class="d-flex align-center">
                    <button type="button" class="blue pa-2 rounded-xl mr-2">
                      <v-icon dark large>mdi mdi-pill</v-icon>
                    </button>
                    <div>
                      <div v-if="loading">
                        <v-progress-circular
                          :size="25"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <h4 class="text-h6 font-weight-bold" v-else>
                        {{ this.nutritionsCount.malnutrition }}
                      </h4>
                      <h4 class="text-subtitle-1">Gizi Buruk</h4>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-card class="pa-5">
                  <div class="d-flex align-center">
                    <button type="button" class="red pa-2 rounded-xl mr-2">
                      <v-icon dark large>mdi mdi-pill</v-icon>
                    </button>
                    <div>
                      <div v-if="loading">
                        <v-progress-circular
                          :size="25"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <h4 class="text-h6 font-weight-bold" v-else>
                        {{ this.nutritionsCount.undernutrition }}
                      </h4>
                      <h4 class="text-subtitle-1">Gizi Kurang</h4>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-card class="pa-5">
                  <div class="d-flex align-center">
                    <button type="button" class="yellow pa-2 rounded-xl mr-2">
                      <v-icon dark large>mdi mdi-pill</v-icon>
                    </button>
                    <div>
                      <div v-if="loading">
                        <v-progress-circular
                          :size="25"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <h4 class="text-h6 font-weight-bold" v-else>
                        {{ this.nutritionsCount.normal }}
                      </h4>
                      <h4 class="text-subtitle-1">Gizi Normal</h4>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-card class="pa-5">
                  <div class="d-flex align-center">
                    <button type="button" class="green pa-2 rounded-xl mr-2">
                      <v-icon dark large>mdi mdi-pill</v-icon>
                    </button>
                    <div>
                      <div v-if="loading">
                        <v-progress-circular
                          :size="25"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <h4 class="text-h6 font-weight-bold" v-else>
                        {{ this.nutritionsCount.overnutrition }}
                      </h4>
                      <h4 class="text-subtitle-1">Gizi Lebih</h4>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-card class="pa-5">
                  <div class="d-flex align-center">
                    <button type="button" class="indigo pa-2 rounded-xl mr-2">
                      <v-icon dark large>mdi mdi-pill</v-icon>
                    </button>
                    <div>
                      <div v-if="loading">
                        <v-progress-circular
                          :size="25"
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                      <h4 class="text-h6 font-weight-bold" v-else>
                        {{ this.nutritionsCount.obesity }}
                      </h4>
                      <h4 class="text-subtitle-1">Obesitas</h4>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #name="{ item }">
          <div class="pa-2 font-weight-bold">
            <p>{{ item.name }}</p>
            <p>
              <v-chip color="blue-grey" dark>
                <v-avatar left>
                  <v-icon>mdi-account-group-outline</v-icon>
                </v-avatar>
                {{ item.students_count.total_students }}
              </v-chip>
            </p>
            <p>
              <v-chip
                :color="`${
                  item.school_type == 'NEGERI' ? `green` : `blue`
                } font-weight-bold`"
                small
                dark
              >
                {{ item.school_type }}
              </v-chip>
            </p>
            <p>
              <v-chip dark small color="blue lighten-2">
                <v-avatar left>
                  <v-icon>mdi mdi-hospital-building</v-icon>
                </v-avatar>
                {{ item.public_health_center.name }}
              </v-chip>
            </p>
          </div>
        </template>

        <template #address="{ item }">
          <span class="text-wrap">{{ item.address }}</span>
        </template>

        <template #action="{ item }">
          <div>
            <v-tooltip
              :disabled="$vuetify.breakpoint.mobile"
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="primary rounded-lg"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="show(item)"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>Detail</span>
            </v-tooltip>
          </div>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";
import { mapActions, mapState } from "vuex";
import * as echarts from "echarts";
import NutritionalSchoolAlert from "../../components/NutritionalSchoolAlert.vue";
import NutritionalPublicHealthCenterAlert from "../../components/NutritionalPublicHealthCenterAlert.vue";

export default {
  components: {
    DataTable,
    ModulForm,
    NutritionalSchoolAlert,
    NutritionalPublicHealthCenterAlert,
  },
  data() {
    return {
      selected: "ALL",
      showDistrictDropdown: false,
      showVillageDropdown: false,
      showPublicHealthCenterDropdown: false,
      showSchoolDropdown: false,
      showSexDropdown: false,
      datatable: {
        ref: "table",
        btnAdd: false,
        btnImport: true,
        moduleName: "Sekolah",
        headers: [
          { text: "No", value: "num", sortable: false },
          { text: "Nama", value: "name", slot: true },
          { text: "Alamat", value: "address", slot: true },
          { text: "Kecamatan", value: "district.name", sortable: false },
          { text: "Kelurahan", value: "village.name", sortable: false },
          {
            text: "Actions",
            value: "action",
            align: "center",
            sortable: false,
            slot: true,
          },
        ],
        params: {
          sortby: "name",
          with: ["district", "village", "publicHealthCenter"],
          filterKecamatan: null,
          filterKelurahan: null,
          filterPuskesmas: null,
          school_id: null,
          public_health_center_id: null,
        },
      },
      districts: [],
      villages: [],
      publicHealthCenters: [],
      pieInstance: null,
      nutritionsCount: {
        malnutrition: 0,
        undernutrition: 0,
        normal: 0,
        overnutrition: 0,
        obesity: 0,
      },
      loading: false,
      selectedDistrict: null,
      selectedVillage: null,
      selectedPublicHealthCenter: null,
      selectedSchool: null,
      chartName: `Persentase Gizi`,
      studentsCount: null,
      schools: [],
      showNutritionalSchoolAlert: false,
      showNutritionalPublicHealthCenterAlert: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  created() {
    if (this.userRole("guru") && this.user.sekolah && this.user.sekolah.id) {
      this.datatable.params.school_id = this.user.sekolah.id;
    }
    if (this.userRole("puskesmas") && this.user.puskesmas) {
      this.datatable.params.public_health_center_id = this.user.puskesmas.id;
    }
  },
  mounted() {
    this.$http("sekolah/status-nutrisi", {
      params: {
        school_id: this.datatable.params.school_id || null,
        public_health_center_id:
          this.datatable.params.public_health_center_id || null,
      },
    }).then((res) => {
      this.nutritionsCount = res.data.data;
    });
    this.loading = true;

    this.$http("kecamatan", {
      params: {
        all: true,
      },
    })
      .then((res) => {
        this.districts = res.data.data.map(({ id, name }) => ({
          id: parseInt(id),
          name,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    this.$http("sekolah", {
      params: {
        all: true,
        sortby: "name",
        orderbydesc: "asc",
      },
    })
      .then((res) => {
        this.schools = res.data;

        this.pieInstance = echarts.init(this.$refs.pie);
        this.pieChart();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    this.$http("puskesmas", {
      params: {
        all: true,
        sortby: "name",
        sortbydesc: "asc",
      },
    })
      .then((res) => {
        this.publicHealthCenters = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    ...mapActions("auth", ["auth"]),
    show(item) {
      const detailUrl = `/monitoring-gizi/sekolah/${item.id}`;

      // Navigate to the detail page using Vue Router
      this.$router.push(detailUrl);
    },
    filterDistrict(districtID) {
      this.showVillageDropdown = true;
      this.loading = true;

      this.selectedDistrict = this.districts.find(
        (district) => district.id === districtID
      );

      this.$http("kelurahan-desa", {
        params: {
          district_id: this.selectedDistrict.id,
        },
      })
        .then(({ data }) => {
          this.villages = data.data.map(({ id, name }) => ({
            id: parseInt(id),
            name,
          }));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      this.$http("sekolah/status-nutrisi", {
        params: {
          district_id: this.selectedDistrict.id,
        },
      }).then((res) => {
        this.nutritionsCount = res.data.data;

        this.chartName += ` Kecamatan ${this.selectedDistrict.name}`;
        this.pieChart();
      });

      this.datatable.params.filterKecamatan = this.selectedDistrict.id;
      this.datatable.params.filterKelurahan = null;
      this.datatable.params.filterPuskesmas = null;
      this.$refs.table.load();
    },
    filterVillage(villageID) {
      this.loading = true;

      this.selectedVillage = this.villages.find((village) => {
        return village.id === villageID;
      });

      this.$http("sekolah/status-nutrisi", {
        params: {
          district_id: this.selectedDistrict.id,
          village_id: this.selectedVillage.id,
        },
      }).then((res) => {
        this.nutritionsCount = res.data.data;

        this.chartName += ` Kelurahan ${this.selectedVillage.name}`;
        this.pieChart();
      });

      this.datatable.params.filterKecamatan = this.selectedDistrict.id;
      this.datatable.params.filterKelurahan = this.selectedVillage.id;
      this.datatable.params.filterPuskesmas = null;
      this.$refs.table.load();
    },
    filterSchool(schoolID) {
      this.loading = true;

      this.selectedSchool = this.schools.find((school) => {
        return school.id === schoolID;
      });

      this.$http("sekolah/status-nutrisi", {
        params: {
          school_id: this.selectedSchool.id,
        },
      }).then((res) => {
        this.nutritionsCount = res.data.data;

        this.chartName += ` Sekolah ${this.selectedSchool.name}`;
        this.pieChart();
      });

      this.showNutritionalSchoolAlert = true;

      this.datatable.params.filterKecamatan = null;
      this.datatable.params.filterKelurahan = null;
      this.datatable.params.filterPuskesmas = null;
      this.$refs.table.load();
    },
    filterSex(sex) {
      this.loading = true;

      this.$http("sekolah/status-nutrisi", {
        params: {
          sex: sex,
        },
      }).then((res) => {
        this.nutritionsCount = res.data.data;

        this.chartName += ` Jenis Kelamin ${sex}`;
        this.pieChart();
      });

      this.$refs.table.load();
    },
    filterPublicHealthCenter(publicHealthCenterID) {
      this.loading = true;

      this.selectedPublicHealthCenter = this.publicHealthCenters.find(
        (publicHealthCenter) => publicHealthCenter.id === publicHealthCenterID
      );

      this.$http("sekolah/status-nutrisi", {
        params: {
          public_health_center_id: this.selectedPublicHealthCenter.id,
        },
      }).then((res) => {
        this.nutritionsCount = res.data.data;

        this.chartName = `${this.selectedPublicHealthCenter.name}`;
        this.pieChart();
      });

      this.showNutritionalPublicHealthCenterAlert = true;

      this.datatable.params.filterKecamatan = null;
      this.datatable.params.filterKelurahan = null;
      this.datatable.params.filterPuskesmas =
        this.selectedPublicHealthCenter.id;
      this.$refs.table.load();
    },
    filter(item) {
      this.showDistrictDropdown = false;
      this.showVillageDropdown = false;
      this.showPublicHealthCenterDropdown = false;
      this.showSchoolDropdown = false;
      this.showSexDropdown = false;
      this.showNutritionalSchoolAlert = false;
      this.showNutritionalPublicHealthCenterAlert = false;

      if (item === "ALL") {
        this.loading = true;
        this.chartName = "Persentase Gizi";
        this.$http("sekolah/status-nutrisi").then((res) => {
          this.nutritionsCount = res.data.data;
          this.pieChart();
        });

        this.datatable.params.filterKecamatan = null;
        this.datatable.params.filterKelurahan = null;
        this.$refs.table.load();
      } else if (item === "DAERAH") {
        this.showDistrictDropdown = true;
      } else if (item === "PUSKESMAS") {
        this.showPublicHealthCenterDropdown = true;
      } else if (item === "SEKOLAH") {
        this.showSchoolDropdown = true;
      } else if (item === "JENIS KELAMIN") {
        this.showSexDropdown = true;
      }
    },
    pieChart() {
      const option = {
        title: {
          text: this.chartName,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          orient: "horizontal",
          bottom: "10%",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            center: ["50%", "30%"],
            label: {
              position: this.$vuetify.breakpoint.xs ? "inside" : "outside",
              formatter: this.$vuetify.breakpoint.xs ? "{d}%" : "{b} {d}%",
              color: "black",
            },
            data: [
              { value: this.nutritionsCount.obesity, name: "Obesitas" },
              {
                value: this.nutritionsCount.overnutrition,
                name: "Gizi Lebih",
              },
              { value: this.nutritionsCount.normal, name: "Normal" },
              {
                value: this.nutritionsCount.undernutrition,
                name: "Gizi Kurang",
              },
              { value: this.nutritionsCount.malnutrition, name: "Gizi Buruk" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.pieInstance.setOption(option);
      this.loading = false;
      window.addEventListener("resize", () => {
        this.pieInstance.resize();
      });
    },
    onDistrictChange(selectedDistrict) {
      if (!selectedDistrict) {
        this.params.filterKelurahan = null;
        this.params.filterDistrict = null;
        this.villages = [];
      } else {
        this.$http("kelurahan-desa", {
          params: {
            district_id: selectedDistrict.id,
          },
        })
          .then(({ data }) => {
            this.villages = data.data.map(({ id, name }) => ({
              id: parseInt(id),
              name,
            }));
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
      this.$refs.table.load();
    },
  },
};
</script>
