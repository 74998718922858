<template>
  <v-data-table
    class="elevation-1 text-uppercase"
    v-bind="bindProps"
    v-model="selected"
    :dense="dense"
    :show-select="showSelected"
    :headers="headers"
    :items="items"
    :items-per-page="perPage"
    :options.sync="options"
    :server-items-length="total"
    :loading="loading"
    :class="{
      clickable: clickable,
      freeze: freeze,
      bordered: bordered,
    }"
    @update:options="load"
    @dblclick:row="rowClick"
  >
    <template #header="{ props }">
      <slot name="header" :props="props"></slot>
    </template>
    <template v-slot:[`group.header`]="{ group, headers }">
      <td :colspan="headers.length">
        <span style="font-weight: bold"> {{ group.toUpperCase() }} </span>
      </td>
    </template>
    <template v-slot:top>
      <div v-if="$vuetify.breakpoint.xs">
        <p class="text-center pt-3 text-h5 font-weight-bold">
          {{ title || `Daftar ${moduleName}` }}
        </p>
        <v-divider></v-divider>
      </div>
      <v-toolbar flat class="rounded-xl">
        <v-toolbar-title v-if="!$vuetify.breakpoint.xs">{{
          title || `Daftar ${moduleName}`
        }}</v-toolbar-title>
        <v-divider
          v-if="!$vuetify.breakpoint.xs"
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-btn
          :disabled="loading"
          v-if="btnImport"
          class="mr-2"
          small
          outlined
          color="primary"
          @click="dialogImport = !dialogImport"
        >
          <v-icon>file_upload</v-icon>
        </v-btn>
        <v-btn
          :disabled="loading"
          v-if="
            (userCan(`${_.kebabCase(moduleName)}-export`) && btnExport) ||
            (userRole('admin') && !$vuetify.breakpoint.xs && btnExport)
          "
          class="mr-2"
          small
          color="primary"
          @click="exportData('excel')"
        >
          <v-icon>file_download</v-icon>
          Export
        </v-btn>
        <!-- v-menu for Export and Add buttons -->

        <v-spacer></v-spacer>

        <v-text-field
          class="mb-2"
          v-model="search"
          @input="handleSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <slot name="header-btn"></slot>

        <v-btn
          v-if="hasFilter && !$vuetify.breakpoint.xs"
          text
          fab
          small
          class="mb-2 ml-1"
          color="primary"
          @click="isFilterActive = !isFilterActive"
        >
          <v-icon>filter_alt</v-icon>
        </v-btn>
        <v-btn
          :disabled="loading"
          v-if="
            btnAdd &&
            userCan(`${_.kebabCase(moduleName)}-create`) &&
            !$vuetify.breakpoint.xs
          "
          color="primary"
          small
          class="mb-2 ml-1"
          :to="btnAdd !== true ? btnAdd : null"
          @click="btnAdd !== true ? null : (dialog = !dialog)"
        >
          <v-icon>add</v-icon> Add
        </v-btn>

        <v-dialog v-model="dialogShow" max-width="80%" scrollable>
          <slot name="modal-show" :editedItem="editedItem"></slot>
        </v-dialog>
        <v-dialog
          v-model="dialogImport"
          scrollable
          :overlay="false"
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title primary-title> Import Data </v-card-title>
            <v-card-text>
              <v-file-input
                v-model="fileImport"
                label="File Import"
                prepend-icon="mdi-paperclip"
              ></v-file-input>
              <v-chip color="pink" class="white--text" @click="importSample">
                <v-icon left> file_download </v-icon>
                Download Sample.xlsx
              </v-chip>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                @click="importData"
                :disabled="!fileImport"
                :loading="loading"
              >
                <v-icon>file_upload</v-icon> Import</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">{{
                !editedItem.id ? "New Item" : "Edit Item"
              }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form v-model="formValid">
                  <slot
                    name="modal-form"
                    :save="save"
                    :dialog="dialog"
                    :editedItem="editedItem"
                    :errors="errors"
                  ></slot>
                </v-form>
              </v-container> </v-card-text
            >`

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn
                color="blue darken-1 white--text"
                :loading="loading"
                @click="save"
                :disabled="!formValid"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="chartDialog" max-width="1000px">
          <v-card>
            <v-card-title>
              <span class="headline">chart</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <slot
                  name="modal-chart"
                  :dialog="chartDialog"
                  :editedItem="editedItem"
                ></slot>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1 white--text"
                dense
                @click="closeDialog"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
          v-if="selected.length && userCan(`${_.kebabCase(moduleName)}-delete`)"
          outlined
          class="ml-2 mb-2"
          color="red"
          small
          :loading="loading"
          @click="deleteItem"
        >
          <v-icon>delete</v-icon> ({{ selected.length }})
        </v-btn>
        <v-btn
          v-if="!$vuetify.breakpoint.xs"
          outlined
          small
          class="ml-2 mb-2"
          color="primary"
          :loading="loading"
          @click="load"
        >
          <v-icon>cached</v-icon>
        </v-btn>
        <v-menu bottom left v-if="$vuetify.breakpoint.xs">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- Export button -->
            <v-list-item
              v-if="
                (userCan(`${_.kebabCase(moduleName)}-export`) && btnExport) ||
                userRole('admin')
              "
              @click="exportData('excel')"
            >
              <v-list-item-icon>
                <v-icon color="primary">file_download</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="primary--text">
                <v-list-item-title>Export</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- Add button -->
            <v-list-item
              v-if="btnAdd && userCan(`${_.kebabCase(moduleName)}-create`)"
              @click="btnAdd !== true ? null : (dialog = !dialog)"
            >
              <v-list-item-icon>
                <v-icon color="primary">add</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="primary--text">
                <v-list-item-title>Add</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasFilter"
              text
              fab
              small
              class="mb-2 ml-1"
              color="primary"
              @click="isFilterActive = !isFilterActive"
            >
              <v-list-item-icon>
                <v-icon color="primary">filter_alt</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="primary--text">
                <v-list-item-title>Filter</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Cached button -->
            <v-list-item @click="load">
              <v-list-item-icon>
                <v-icon color="primary">cached</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="primary--text">
                <v-list-item-title>Load</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-expand-transition>
        <v-row class="mt-1 ml-1" v-show="isFilterActive">
          <v-col cols="12">
            <slot name="filter" :load="load" :params="params">
              <v-select
                dense
                class="mr-2"
                :items="[1, 2, 3]"
                prepend-icon="filter_alt"
                label="Filter Permission"
                hide-details
              >
              </v-select>
            </slot>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-divider></v-divider>
    </template>

    <template v-slot:[`item.num`]="{ item }">
      {{
        items.indexOf(item) + 1 + (meta.page * meta.per_page - meta.per_page)
      }}
    </template>

    <template v-for="slot in slots" v-slot:[`item.${slot}`]="{ item }">
      <slot :name="slot" :item="item"></slot>
    </template>

    <template
      v-for="custom in headers.filter((i) => i.slot)"
      v-slot:[`item.${custom.value}`]="{ item }"
    >
      <slot :name="custom.value" :item="item">
        {{ _.get(item, custom.value) }}
      </slot>
    </template>

    <template #foot="{ items }">
      <slot name="foot" :items="items"></slot>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-row class="text-center">
        <v-col class="text-center">
          <slot name="actions" :item="item"></slot>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item v-if="_.isString(btnShow)" @click="showItem(item)">
                <v-list-item-icon>
                  <v-icon color="info">visibility</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="blue--text">
                  <v-list-item-title>Detail</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="userCan(`${_.kebabCase(moduleName)}-edit`) && btnEdit"
                @click="editItem(item)"
              >
                <v-list-item-icon>
                  <v-icon color="warning">edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="warning--text">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="userCan(`${_.kebabCase(moduleName)}-delete`)"
                @click="deleteItem(item)"
              >
                <v-list-item-icon>
                  <v-icon color="red">delete</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="red--text">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </template>
    <template v-slot:no-data>
      <v-alert
        class="mt-2"
        border="left"
        colored-border
        color="pink accent-4"
        elevation="2"
      >
        Data Tidak Ditemukan
      </v-alert>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions } from "vuex";
import download from "downloadjs";
export default {
  props: {
    title: String,
    moduleName: String,
    url: String,
    headers: Array,
    slots: Array,
    params: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Boolean,
      default: false,
    },
    btnAdd: {
      default: true,
    },
    btnShow: {
      type: [Boolean, String],
      default: false,
    },
    btnEdit: {
      type: [Boolean, String],
      default: true,
    },
    btnImport: {
      type: [Boolean, String],
      default: false,
    },
    btnExport: {
      type: [Boolean, String],
      default: false,
    },
    bindProps: {
      default: () => {
        return {};
      },
    },
    hasFilter: {
      type: [Boolean, Array],
      default: false,
    },
    showSelected: {
      type: Boolean,
      default: false,
    },
    perPage: {
      default: 10,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    freeze: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    selected: [],
    items: [],
    total: 0,
    chartDialog: false,
    search: "",
    options: {},
    errors: {},
    dialog: false,
    dialogShow: false,
    formValid: false,
    dialogImport: false,
    editedItem: {},
    fileImport: null,
    isFilterActive: false,
  }),

  computed: {
    ...mapState(["onLine", "syncing"]),
    meta() {
      return {
        ...this.options,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sortby: this.options.sortBy && this.options.sortBy[0],
        sortbydesc:
          this.options.sortDesc && this.options.sortDesc[0] ? "asc" : "desc",
        q: this.search,
        ...this.params,
      };
    },
    route() {
      return this.url || this._.kebabCase(this.moduleName);
    },
    // form() {
    //   if (this.onLine && this.formData) {
    //     const formData = new FormData()
    //     for (const key in this.editedItem) {
    //       formData.append(key, this.editedItem[key] == null ? '' : this.editedItem[key])
    //     }
    //     console.log(formData)
    //     return formData
    //   } else {
    //     console.log('ey')
    //     console.log(this.editItem)
    //     return this.editedItem
    //   }
    // }
    form() {
      if (this.onLine && this.formData) {
        const formData = new FormData();

        // this.isArrayFormData(this.editedItem, formData);

        for (const key in this.editedItem) {
          if (key !== "school" && key !== "family_members") {
            if (
              this._.isObject(this.editedItem[key]) &&
              !key.includes("file")
            ) {
              for (const k in this.editedItem[key]) {
                //
                if (
                  this._.isObject(this.editedItem[key][k]) &&
                  !key.includes("file")
                ) {
                  for (const y in this.editedItem[key][k]) {
                    if (
                      this._.isObject(this.editedItem[key][k][y]) &&
                      !key.includes("file")
                    ) {
                      for (const i in this.editedItem[key][k][y]) {
                        //
                        formData.append(
                          `${key}[${k}][${y}][${i}]`,
                          this.editedItem[key][k][y][i] == null
                            ? ""
                            : this.editedItem[key][k][y][i]
                        );
                      }
                    } else
                      formData.append(
                        `${key}[${k}][${y}]`,
                        this.editedItem[key][k][y] == null
                          ? ""
                          : this.editedItem[key][k][y]
                      );
                    //
                    // formData.append(
                    //   `${key}[${k}][${y}]`,
                    //   this.editedItem[key][k][y] == null ? "" : this.editedItem[key][k][y]
                    // );
                  }
                } else
                  formData.append(
                    `${key}[${k}]`,
                    this.editedItem[key][k] == null
                      ? ""
                      : this.editedItem[key][k]
                  );
                //
                // formData.append(
                //   `${key}[${k}]`,
                //   this.editedItem[key][k] == null ? "" : this.editedItem[key][k]
                // );
              }
            } else
              formData.append(
                key,
                this.editedItem[key] == null ? "" : this.editedItem[key]
              );
          }
        }
        return formData;
      } else return this.editedItem;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    syncing(v) {
      if (v) this.load();
    },
    onLine(v) {
      if (!v) this.load();
    },
  },

  methods: {
    ...mapActions("base", ["getData", "saveData", "deleteData"]),
    // ...mapMutations('base',['SET_MODULE_NAME']),
    load() {
      this.loading = true;
      this.getData({ customUrl: this.route, params: this.meta }).then((res) => {
        this.items = res.data;
        this.total = res.total;
        this.loading = false;
        this.$emit("loaded", this.items);
      });
    },

    async save() {
      this.loading = true;
      // if (this.form.school !== null) {
      //   delete this.form.school
      //   delete this.form.family_members
      //   console.log('hello')
      // }
      await this.saveData({
        customUrl: this.route,
        data: this.form,
        params: { noState: false },
      });

      setTimeout(() => this.load(), 500);
      this.$emit("saved", this.form);
      if (this._.isEmpty(this.errors)) this.close();
    },

    editItem(item) {
      if (this._.isString(this.btnEdit))
        this.$router.push(`${this.route}/${item.id}/edit`);
      else {
        // this.editedItem = Object.assign({}, item)
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      }
    },
    showItem(item) {
      // this.$router.push(`${this.btnShow}/${item.id}`)
      this.editedItem = Object.assign({}, item);
      this.chartDialog = true;
    },

    async deleteItem(item) {
      try {
        await this.$swal({
          title: "Delete Item",
          text: "Are you sure want to delete this item?",
          icon: "warning",
          confirmButtonText: "Yes!",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.loading = true;
            await this.deleteData({
              customUrl: this.route,
              data: item,
              params: {
                noState: true,
                selected: this.selected.map((i) => i.id),
              },
            });
            this.loading = false;
            this.selected = [];
            this.load();
          },
        });
      } catch (error) {
        // Handle the error here
        console.error(error);
        // Show an error message to the user, if needed
        this.$swal({
          title: "Error",
          text: "An error occurred while deleting the item.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },

    importSample() {
      let fileName;
      switch (this.$route.name) {
        case "MonitoringGizi":
          fileName = "gizi";
          break;
        case "Siswa":
          fileName = "siswa";
          break;
        default:
          fileName = this.route;
          break;
      }
      window.location.assign(`/files/import_sample_${fileName}.xlsx`);
    },

    importData() {
      this.loading = true;
      let data = new FormData();
      data.append("file_import", this.fileImport);
      let link;
      switch (this.$route.name) {
        case "MonitoringGizi":
          link = "AnthropometricMeasurement";
          break;
        case "Siswa":
          link = "Student";
          break;
        default:
          link = this.route;
          break;
      }
      this.$http
        .post(`import/${link}`, data)
        .then(() => {
          this.loading = false;
          this.dialogImport = false;
          this.$swal("success", "success", "success");
          this.load();
        })
        .catch(() => {
          this.loading = false;
          this.dialogImport = false;
          this.$swal("error", "error", "error");
        });
    },

    exportData(type) {
      let module;
      switch (this.$route.name) {
        case "MonitoringGiziDetailSekolah":
          module = "Student";
          break;
        case "MonitoringGiziDetailSiswa":
          module = "AnthropometricMeasurement";
          break;
        default:
          module = this.moduleName;
          break;
      }
      this.loading = true;
      if (type == "pdf") window.open(`api/export/${type}/${this.route}`);
      else if (type == "excel")
        this.$http({
          url: `export/${type}/${this._.kebabCase(module)}`,
          method: "get",
          responseType: "blob",
          params: {
            ...this.params,
            q: this.search,
          },
        })
          .then((response) => {
            this.loading = false;

            let fileName = `EXPORT_${this.moduleName.toUpperCase()}_${this.$moment(
              new Date()
            ).format("DD-MM-YYYY")}.xlsx`;
            let MIMEtypes =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml";

            download(response.data, fileName, MIMEtypes);
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.$swal("error", "error", "error");
          });
    },

    handleSearch() {
      setTimeout(() => {
        this.load();
      }, 500);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({});
      });
    },
    closeDialog() {
      this.chartDialog = false;
    },
    rowClick(event, row) {
      // if (this.btnShow) this.$router.push(`/${this.moduleName}/${row.item.id}`);
      this.$emit("dblclick", row.item);
      this.editedItem = row.item;
      if (this.btnShow) this.dialogShow = true;
    },
  },
};
</script>

<style lang="css" scoped>
.clickable >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.bordered td,
.bordered th {
  border: 1px groove;
}
.freeze > div > table > tbody > tr > td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 1;
  background: white;
}
.freeze > div > table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  /* z-index: 1; */
  z-index: 3;
  background: white;
}
.v-data-table td {
  white-space: nowrap;
}
.v-row-group__header {
  font-weight: bold;
}
</style>
