<template>
  <v-container>
    <v-card :loading="loading" elevation="2" class="rounded-xl">
      <template slot="progress">
        <v-progress-linear
          color="primary"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-title class="justify-center"
        >Kalkulator Antropometri</v-card-title
      >

      <v-card-text>
        <ModulForm :form="form" :loading="loading" m-5 @submit="submit" />
        <transition name="slide-x-transition">
          <template v-if="result !== null">
            <div
              ref="error"
              v-if="this.result.zScore === null"
              class="text-center my-5 mb-10 red--text"
            >
              <v-icon size="50" class="mb-4" color="red"
                >mdi-close-outline</v-icon
              >
              <h2>Terjadi Kesalahan</h2>
              <p class="text-h6">
                Usia anak dibawah 5 tahun atau diatas 19 tahun
              </p>
            </div>
            <div
              v-else
              ref="hasilImt"
              class="d-flex justify-center align-center flex-column"
            >
              <h2>Hasil</h2>
              <div class="mt-3">
                <v-icon
                  size="100"
                  v-if="result.category === 'Gizi Baik'"
                  :color="
                    (result.zScore > -2 && result.zScore <= -1) ||
                    (result.zScore < 2 && result.zScore >= 1)
                      ? 'yellow darken-2'
                      : 'green'
                  "
                  >mdi-emoticon-happy-outline</v-icon
                >
                <v-icon
                  size="100"
                  v-if="
                    result.category === 'Gizi Lebih' ||
                    result.category === 'Gizi Kurang'
                  "
                  color="yellow darken-2"
                  >mdi-emoticon-sad-outline</v-icon
                >
                <v-icon
                  size="100"
                  v-if="
                    result.category === 'Gizi Buruk' ||
                    result.category === 'Obesitas'
                  "
                  color="red darken-2"
                  >mdi-emoticon-cry-outline</v-icon
                >
              </div>
              <p class="text-uppercase font-weight-black mt-3">
                Hasil IMT/U : {{ result.bmi }}
              </p>
              <p class="text-uppercase font-weight-black text-center">
                Z-Score {{ result.zScore }} = Status Gizi Anak Kategori
                {{ result.category }}
              </p>
            </div>
          </template>
        </transition>
        <div ref="chart" id="ch"></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ModulForm from "./Form.vue";
import * as echarts from "echarts";
import { mapState } from "vuex";
import {
  getZScore,
  calculateAge,
  calculateBMI,
  getZScoreCategoryName,
} from "@/helpers/bmiUtils";

export default {
  components: {
    ModulForm,
  },
  data() {
    return {
      loading: false,
      form: {},
      result: null,
      chartInstance: null,
      barData: null,
    };
  },
  computed: {
    ...mapState("bmi", ["bmiData"]),
  },
  methods: {
    /**
     * Submits the form and calculates the BMI and z-score.
     *
     * @return {void}
     */
    submit() {
      this.result = null;
      this.loading = true;
      if (this.chartInstance !== null) {
        this.chartInstance.clear();
        this.chartInstance.dispose();
      }

      setTimeout(() => {
        const calc = calculateBMI(
          this.form.weight,
          this.form.height,
          this.form.date_of_birth
        );
        let z_score = getZScore(
          calc,
          this.bmiData,
          this.form.date_of_birth,
          this.form.measurement_date,
          this.form.sex
        );
        if (calc !== null || z_score !== null) {
          this.result = {
            bmi: calc,
            zScore: z_score,
            category: getZScoreCategoryName(z_score),
          };
          this.$nextTick(() => {
            if (this.result.bmi !== null && this.result.zScore !== null) {
              this.chartInstance = echarts.init(this.$refs.chart, null, {
                width: "auto",
                height: 500,
              });
              this.updateChart();
              this.$refs.hasilImt.scrollIntoView({ behavior: "smooth" });
              this.loading = false;
            } else {
              this.$refs.error.scrollIntoView({ behavior: "smooth" });

              this.loading = false;
            }
          });
        }
      }, 1000);
    },
    updateChart() {
      if (!this.chartInstance) return;

      const sexCategory = this.form.sex === "l" ? "laki laki" : "perempuan";
      const ageInfo = calculateAge(
        this.form.date_of_birth,
        this.form.measurement_date
      );
      const age = ageInfo.ageYears * 12 + ageInfo.ageMonths;
      const bmiTable =
        this.bmiData[sexCategory][Math.floor(age / 12)][age % 12];
      const dat = this.bmiData[sexCategory];

      if (!bmiTable) return;

      const option = {
        color: [
          "#000000",
          "#FF0000",
          "#FFFF00",
          "#00FF00",
          "#FFFF00",
          "#FF0000",
          "#000000",
        ],
        xAxis: {
          type: "category",
          name: "Umur",
          data: Array.from({ length: 168 }, (_, index) => index + 61),

          axisLabel: {
            formatter: "{value} Bulan",
          },
        },
        graphic: [
          {
            type: "group",
            left: "13%",
            top: "10%",
            children: [
              {
                type: "rect",
                z: 100,
                left: "center",
                top: "middle",
                shape: {
                  width: 240,
                  height: 50,
                },
                style: {
                  fill: "#fff",
                  stroke: "#555",
                  lineWidth: 1,
                  shadowBlur: 8,
                  shadowOffsetX: 3,
                  shadowOffsetY: 3,
                  shadowColor: "rgba(0,0,0,0.2)",
                },
              },
              {
                type: "text",
                z: 100,
                left: "center",
                top: "middle",
                style: {
                  fill: "#333",
                  width: 230,
                  overflow: "break",
                  text: `Umur : ${ageInfo.ageYears} tahun ${
                    age % 12
                  } bulan (${age} bulan), z-score: ${this.result.zScore}`,
                  font: "14px Microsoft YaHei",
                  fontSize: "14px",
                  fontWeight: "bold",
                },
              },
            ],
          },
        ],
        tooltip: {
          axisPointer: {
            type: "cross",
          },
          formatter: function (params) {
            if (params.seriesName === "IMT/U") {
              // Customize tooltip for BMI label scatter series
              return `${params.seriesName} <br> ${params.marker}${params.name} bulan : ${params.value[1]}`;
            } else {
              // Customize tooltip for other series (SD lines)
              return `${params.seriesName} <br> ${params.marker}${params.name} bulan : ${params.value}`;
            }
          },
        },
        yAxis: {
          min: 10,
        },
        series: [
          {
            name: "-3 SD",
            type: "line",
            data: this.createDataArray(dat, "-3 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "-3 SD";
              },
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "-2 SD",
            type: "line",
            data: this.createDataArray(dat, "-2 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "-2 SD";
              },
              fontSize: 15,
              color: "red",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "-1 SD",
            type: "line",
            data: this.createDataArray(dat, "-1 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "-1 SD";
              },
              fontSize: 15,
              color: "#b09602",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "Median",
            type: "line",
            data: this.createDataArray(dat, "Median"),
            endLabel: {
              show: true,
              formatter: function () {
                return "Median";
              },
              fontSize: 15,
              color: "green",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "+1 SD",
            type: "line",
            data: this.createDataArray(dat, "+1 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "+1 SD";
              },
              fontSize: 15,
              color: "#b09602",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "+2 SD",
            type: "line",
            data: this.createDataArray(dat, "+2 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "+2 SD";
              },
              fontSize: 15,
              color: "red",
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "+3 SD",
            type: "line",
            data: this.createDataArray(dat, "+3 SD"),
            endLabel: {
              show: true,
              formatter: function () {
                return "+3 SD";
              },
              fontSize: 15,
              fontWeight: "bold",
            },
            smooth: true,
            showSymbol: false,
          },
          {
            name: "IMT/U",
            type: "scatter", // Use bar chart type
            symbolSize: 15,
            data: [
              // Create a data array with a single bar for this.result.bmi at the corresponding age
              [age - 61, this.result.bmi], // age and this.result.bmi will be the x and y values for the bar
            ],
            label: {
              show: true,
              formatter: `IMT/U: ${this.result.bmi}`, // Display the BMI value in the label
              position: "top",
              fontWeight: "bold",
            },
            itemStyle: {
              color: "blue",
            },
            z: 10,
          },
        ],
      };

      this.chartInstance.setOption(option);
      if (this.chartInstance !== null) {
        window.addEventListener("resize", () => {
          this.chartInstance.resize();
        });
      }
    },
    createDataArray(bmiData, keyData) {
      const dataArray = [];
      for (let age = 5; age <= 19; age++) {
        if (bmiData[age]) {
          for (const key in bmiData[age]) {
            dataArray.push(bmiData[age][key][keyData]);
          }
        }
      }

      return dataArray;
    },
  },
};
</script>

<style>
.slide-x-transition-enter-active,
.slide-x-transition-leave-active {
  transition: transform 0.5s ease;
}

.slide-x-transition-enter,
.slide-x-transition-leave-to {
  transform: translateX(100%);
}
</style>
