var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"2"}},[_c('DataTable',_vm._b({scopedSlots:_vm._u([{key:"modal-form",fn:function(ref){
var editedItem = ref.editedItem;
return [_c('ModulForm',{attrs:{"form":editedItem}})]}},{key:"thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"px-3 py-3",attrs:{"max-width":"200"}},[_c('ImagePreview',{attrs:{"src":item.thumbnail_link}})],1)]}},{key:"date_posted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date_posted).toLocaleDateString("id-ID", { year: "numeric", month: "numeric", day: "numeric", }))+" ")]}},{key:"is_published",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":((item.is_published ? 'green' : 'secondary lighten-2') + " font-weight-bold"),"small":"","dark":""}},[_vm._v(" "+_vm._s(item.is_published ? "Published" : "Draft")+" ")])]}},{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.sync == 'presync')?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-time-four-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Belum Sinkron Server")])]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.notify(item)}}},[_vm._v(" mdi-bell ")])]}}])},'DataTable',_vm.datatable,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }