var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"loading":_vm.loading,"elevation":"2"}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Kalkulator Antropometri")]),_c('v-card-text',[_c('ModulForm',{attrs:{"form":_vm.form,"loading":_vm.loading,"m-5":""},on:{"submit":_vm.submit}}),_c('transition',{attrs:{"name":"slide-x-transition"}},[(_vm.result !== null)?[(this.result.zScore === null)?_c('div',{ref:"error",staticClass:"text-center my-5 mb-10 red--text"},[_c('v-icon',{staticClass:"mb-4",attrs:{"size":"50","color":"red"}},[_vm._v("mdi-close-outline")]),_c('h2',[_vm._v("Terjadi Kesalahan")]),_c('p',{staticClass:"text-h6"},[_vm._v(" Usia anak dibawah 5 tahun atau diatas 19 tahun ")])],1):_c('div',{ref:"hasilImt",staticClass:"d-flex justify-center align-center flex-column"},[_c('h2',[_vm._v("Hasil")]),_c('div',{staticClass:"mt-3"},[(_vm.result.category === 'Gizi Baik')?_c('v-icon',{attrs:{"size":"100","color":(_vm.result.zScore > -2 && _vm.result.zScore <= -1) ||
                  (_vm.result.zScore < 2 && _vm.result.zScore >= 1)
                    ? 'yellow darken-2'
                    : 'green'}},[_vm._v("mdi-emoticon-happy-outline")]):_vm._e(),(
                  _vm.result.category === 'Gizi Lebih' ||
                  _vm.result.category === 'Gizi Kurang'
                )?_c('v-icon',{attrs:{"size":"100","color":"yellow darken-2"}},[_vm._v("mdi-emoticon-sad-outline")]):_vm._e(),(
                  _vm.result.category === 'Gizi Buruk' ||
                  _vm.result.category === 'Obesitas'
                )?_c('v-icon',{attrs:{"size":"100","color":"red darken-2"}},[_vm._v("mdi-emoticon-cry-outline")]):_vm._e()],1),_c('p',{staticClass:"text-uppercase font-weight-black mt-3"},[_vm._v(" Hasil IMT/U : "+_vm._s(_vm.result.bmi)+" ")]),_c('p',{staticClass:"text-uppercase font-weight-black text-center"},[_vm._v(" Z-Score "+_vm._s(_vm.result.zScore)+" = Status Gizi Anak Kategori "+_vm._s(_vm.result.category)+" ")])])]:_vm._e()],2),_c('div',{ref:"chart",attrs:{"id":"ch"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }