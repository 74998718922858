<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <transition name="fade">
          <v-card v-if="news" key="news" class="elevation-3">
            <v-img
              class="white--text align-end"
              max-height="500px"
              :src="news.thumbnail_link"
            >
            </v-img>
            <v-card-text>
              <v-card-title class="headline">{{ news.title }}</v-card-title>
              <v-card-subtitle
                ><v-chip
                  ><v-avatar left>
                    <v-icon>mdi-calendar-range-outline</v-icon>
                  </v-avatar>
                  {{ formatDate(news.date_posted) }}</v-chip
                >
                -
                <v-chip>
                  <v-avatar left>
                    <v-icon>mdi-tag-outline</v-icon>
                  </v-avatar>
                  {{ news.category.name }}
                </v-chip>
              </v-card-subtitle>
              <div v-html="news.content" class="text-body-1"></div>
            </v-card-text>
          </v-card>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      news: null,
    };
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("id-ID", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  async created() {
    await this.$http("blog", {
      params: {
        slug: this.$route.params.slug,
        with: ["category"],
      },
    }).then(({ data }) => {
      this.news = data.data[0];
    });
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
