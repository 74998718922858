<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable">
        <template #modal-form="{ editedItem, save }">
          <ModulForm :form="editedItem" @save="save" />
        </template>

        <template #filter="{ load, params }">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Asal Sekolah"
                autocomplete
                dense
                :items="schools"
                :form="params"
                clearable
                chips
                small-chips
                item-text="name"
                item-value="id"
                name="filterAsalSekolah"
                @input="load"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Kelas"
                autocomplete
                dense
                :items="[
                  'KELAS I',
                  'KELAS II',
                  'KELAS III',
                  'KELAS IV',
                  'KELAS V',
                  'KELAS VI',
                ]"
                :form="params"
                clearable
                chips
                small-chips
                name="filterKelas"
                @input="load"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Jenis Kelamin"
                autocomplete
                dense
                chips
                small-chips
                :items="['LAKI-LAKI', 'PEREMPUAN']"
                :form="params"
                clearable
                name="filterJenisKelamin"
                @input="load"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <BarqunVSelect
                label="Wali Siswa"
                item-text="name"
                autocomplete
                chips
                small-chips
                dense
                clearable
                item-value="id"
                :items="familyMembers"
                :form="params"
                name="filterWaliMurid"
                @input="load"
              ></BarqunVSelect>
            </v-col>
          </v-row>
        </template>

        <template #name="{ item }">
          <div class="pa-2 font-weight-bold">
            <p>{{ item.name }}</p>
            <p>
              <v-chip dark small color="success">{{ item.nisn }}</v-chip>
            </p>
            <p>
              <v-chip dark small color="gray">{{ item.school_class }}</v-chip>
            </p>
            <p>
              <v-chip
                dark
                small
                :color="item.sex === 'LAKI-LAKI' ? 'blue' : 'pink'"
              >
                <v-icon
                  >mdi mdi-gender-{{
                    item.sex === "LAKI-LAKI" ? "male" : "female"
                  }}
                </v-icon>
                {{ item.sex }}
              </v-chip>
            </p>
          </div>
        </template>

        <template #actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.sync == 'presync'"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                small
                class="mr-2"
              >
                mdi-clock-time-four-outline
              </v-icon>
            </template>
            <span>Belum Sinkron Server</span>
          </v-tooltip>

          {{ item.uuid }}
          <v-icon
            color="success"
            small
            class="mr-2"
            @click="notify(excludeProps(item, ['school', 'family_members']))"
          >
            mdi-bell
          </v-icon>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";
import { mapActions, mapState } from "vuex";
import BarqunVSelect from "@/components/FormInput/BarqunVSelect";

export default {
  components: {
    DataTable,
    BarqunVSelect,
    ModulForm,
  },
  data() {
    return {
      familyMembers: [],
      schools: [],
      datatable: {
        moduleName: "Siswa",
        formData: true,
        btnImport: true,
        hasFilter: true,
        slots: ["family_members"],
        headers: [
          { text: "No", value: "num", sortable: false },
          { text: "Nama Siswa", value: "name", slot: true },
          {
            text: "Asal Sekolah",
            value: "school.name",
            slot: true,
            sortable: false,
          },
          {
            text: "Wali Siswa",
            value: "family_member.user.name",
            sortable: false,
          },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ],
        params: {
          sortby: "name",
          sortbydesc: "asc",
          with: ["school", "familyMember", "familyMember.user"],
          school_id: null,
        },
      },
    };
  },
  created() {
    if (this.userRole("guru") && this.user.sekolah && this.user.sekolah.id) {
      this.datatable.params.school_id = this.user.sekolah.id;
    }
  },
  mounted() {
    this.$http("wali-siswa", {
      params: {
        all: true,
        with: ["user"],
      },
    })
      .then((res) => {
        this.familyMembers = res.data.map((item) => {
          return {
            id: item.id,
            user_id: item.user_id,
            name: item.user.name,
          };
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    this.$http("sekolah", {
      params: {
        all: true,
      },
    })
      .then((res) => {
        this.schools = res.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
  computed: {
    //
    ...mapState("auth", ["user"]),
  },
  methods: {
    notify(item) {
      this.$swal({
        title: "Send Notification",
        text: "Are you sure want to send notification to this user?",
        icon: "warning",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          this.$http(`users/notify/${item.id}`);
        },
      });
    },
    ...mapActions("base", ["saveData"]),
  },
};
</script>
