<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          clearable
          v-model="user.name"
          label="Nama Staf Puskesmas"
          autofocus
          :rules="rules.nameRules"
          placeholder="Masukan nama lengkap.."
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          clearable
          label="Pilih Status Karyawan"
          v-model="form.staff_status"
          :rules="rules.staffStatusRules"
          :items="['PNS', 'HONORER']"
          prepend-inner-icon="mdi-badge-account-outline"
          placeholder="Pilih status karyawan"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-autocomplete
          clearable
          label="Pilih Asal Instansi"
          v-model="form.public_health_center_id"
          :items="publicHealthCenters"
          :rules="rules.publicHealthCenterIDRules"
          item-text="name"
          item-value="id"
          placeholder="Pilih asal instansi"
          prepend-inner-icon="mdi-home-city-outline"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          clearable
          v-model="form.position"
          label="Jabatan"
          placeholder="Masukan jabatan.."
          :rules="rules.positionRules"
          prepend-inner-icon="mdi-badge-account-horizontal-outline"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          clearable
          v-model="form.phone_number"
          label="Nomor Telepon"
          :rules="rules.phoneNumberRules"
          type="number"
          placeholder="Masukan nomor telepon.."
          prepend-inner-icon="mdi-phone-outline"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="12">
        <v-text-field
          clearable
          v-model="user.email"
          label="Alamat Email"
          :rules="rules.emailRules"
          placeholder="Masukan alamat email.."
          hint="Email dan password digunakan untuk login"
          prepend-inner-icon="mdi-email-outline"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="12">
        <v-text-field
          clearable
          v-model="user.password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Password"
          placeholder="Masukan password.."
          hint="Panjang karakter harus lebih dari 8 karakter"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import VFileInput from '@/components/FormInput/VFileInput'
export default {
  props: ["form"],
  components: {
    // VFileInput
  },
  data() {
    return {
      user: {},
      publicHealthCenters: [],
      show1: false,
      email: null,
      password: null,
      rules: {
        nameRules: [
          (v) => !!v || "Kolom nama staf sekolah tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        staffStatusRules: [
          (v) => !!v || "Kolom status karyawan tidak boleh kosong!",
        ],
        publicHealthCenterIDRules: [
          (v) => !!v || "Kolom asal instansi tidak boleh kosong!",
        ],
        positionRules: [
          (v) => !!v || "Kolom jabatan tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        phoneNumberRules: [
          (v) => !!v || "Kolom nomor telepon tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
        emailRules: [
          (v) => !!v || "Kolom alamat email tidak boleh kosong!",
          (v) => (v && v.length >= 3) || "Panjang karakter minimal 3 karakter!",
          (v) =>
            (v && v.length <= 255) || "Panjang karakter maksimal 255 karakter!",
        ],
      },
    };
  },
  watch: {
    "form.user": {
      handler(val) {
        this.user = val || {};
      },
      immediate: true,
    },
    user: {
      handler(val) {
        this.form.user = val;
      },
      immediate: true,
    },
  },
  mounted() {
    this.$http("puskesmas", {
      params: {
        all: true,
        sortby: "name",
        sortbydesc: "asc",
      },
    })
      .then((res) => {
        this.publicHealthCenters = res.data.map(({ id, name }) => ({
          id,
          name,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
};
</script>
<style lang=""></style>
