var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"elevation-5",attrs:{"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('v-alert',{attrs:{"value":!_vm.onLine || _vm.showBackOnline,"dense":"","color":_vm.onLine ? "green" : "pink","dark":"","border":"top","icon":_vm.onLine ? "mdi-access-point-check" : "mdi-access-point-off","transition":"slide-x-transition"}},[_vm._v(" You're "+_vm._s(_vm.onLine ? "online" : "offline")+" ")]),_c('v-alert',{attrs:{"value":_vm.syncing,"dense":"","color":"orange","dark":"","border":"top","icon":"mdi-cloud-sync","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-progress-circular',{attrs:{"size":"20","width":"3","indeterminate":""}})]},proxy:true}])},[_vm._v(" Updating... ")])],1)]},proxy:true}]),model:{value:(_vm.drawer.state),callback:function ($$v) {_vm.$set(_vm.drawer, "state", $$v)},expression:"drawer.state"}},[_c('v-img',{attrs:{"gradient":"to top, rgba(0, 0, 0, 0.6) 0%, transparent 100px","src":"http://loremflickr.com/640/480/nature,forest,sea","max-height":"150","dark":"","id":"top"}},[_c('v-list',[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img-preview',{attrs:{"src":this.user.avatar != null
                ? this.user.avatar_link
                : require('../assets/persons/person 3.jpg')}})],1)],1),(_vm.user.name)?_c('v-list-item',{attrs:{"link":"","to":"/profile"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s((_vm.user.sekolah && _vm.user.sekolah.nama) || _vm.user.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.user.email)+" ")])],1)],1):_vm._e()],1)],1),_c('v-list',{attrs:{"shaped":"","dense":""}},[_vm._l((_vm.links),function(link,key){return [(
          link.route &&
          ((link.can == undefined && link.role == undefined) ||
            _vm.userCan(link.can) ||
            _vm.userRole(link.role))
        )?_c('v-list-item',{key:link.id,attrs:{"router":"","to":link.route,"active-class":"border barqun-blue--text"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"blue lighten-2"}},[_vm._v(_vm._s(link.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"grey-darken2--text"},[_vm._v(" "+_vm._s(link.text))])],1),(link.actions)?_c('v-list-item-action',[(link.actions.chip)?_c('v-chip',{attrs:{"color":"blue","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(link.actions.chip)+" ")]):_vm._e()],1):_vm._e()],1):_vm._e(),(link.divider)?_c('v-divider',{key:link.id}):_vm._e(),(
          link.subheader &&
          (link.group_permissions.some(function (i) { return _vm.userCan(i); }) ||
            !link.group_permissions.length)
        )?_c('v-subheader',{key:link.id,staticClass:"text-overline blue--text"},[_vm._v(_vm._s(link.subheader))]):_vm._e(),(
          link.childs &&
          link.childs.some(
            function (i) { return (i.can == undefined && i.role == undefined) ||
              _vm.userCan(i.can) ||
              _vm.userRole(i.role); }
          )
        )?_c('v-list-group',{key:link.text,attrs:{"id":("menu_" + (link.id || key)),"value":link.childs.find(function (i) { return i.route == _vm.$route.path; }),"prepend-icon":link.icon,"color":"blue","no-action":""},on:{"click":function($event){return _vm.scroll(_vm.menuState[key])}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(link.text))])]},proxy:true}],null,true),model:{value:(_vm.menuState[key]),callback:function ($$v) {_vm.$set(_vm.menuState, key, $$v)},expression:"menuState[key]"}},[_vm._l((link.childs),function(child){return [(
              (child.can == undefined && child.role == undefined) ||
              _vm.userCan(child.can) ||
              _vm.userRole(child.role)
            )?_c('v-list-item',{key:child.text,attrs:{"router":"","to":child.route,"active-class":"border"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"blue lighten-2"}},[_vm._v(_vm._s(child.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(child.text)+" ")])],1)],1):_vm._e()]})],2):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }