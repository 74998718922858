<template>
  <div>
    <div class="d-flex">
      <v-text-field
        label="Search"
        placeholder="Cari berdasarkan judul.."
        outlined
        dense
        :loading="loading"
        background-color="white"
        v-model="search"
        @keydown.enter="handleSearch"
      >
      </v-text-field>
      <v-btn color="primary" height="38" @click="handleSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>
    <p class="font-weight-black text-h5" v-if="result">
      Hasil Pencarian : ditemukan {{ total }} dengan judul {{ result }}
    </p>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        class="mb-5"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <p>Loading</p>
    </div>
    <v-row>
      <p
        class="mx-auto font-weight-bold"
        v-if="Array.isArray(news) && news.length === 0"
      >
        Tidak Ada
      </p>

      <v-col cols="12" sm="12" md="4" v-for="n in news" :key="n.slug">
        <v-card
          @click="newTab(n.slug)"
          id="carded"
          class="mx-auto fade-in-down clickable-title"
          max-width="520"
        >
          <v-img
            class="white--text align-end"
            height="200px"
            :src="n.thumbnail_link"
          >
            <div class="category-box-shadow">
              <span class="text-caption text-uppercase font-weight-bold">
                <v-icon color="white">mdi-tag-outline</v-icon>
                {{ n.category.name }}
              </span>
            </div>
          </v-img>
          <v-card-title class="card-title">
            <span class="title">
              {{ n.title }}
            </span>
          </v-card-title>
          <v-card-subtitle class="pb-0"> </v-card-subtitle>

          <v-card-text class="text--primary">
            <div>{{ generateExcerpt(n.description) }}</div>
          </v-card-text>

          <v-card-actions class="pa-5">
            <v-row justify="start" align="center">
              <!-- <v-btn color="blue white--text" @click="newTab(n.slug)">
                Read More
              </v-btn> -->
              <span
                class="text-caption font-weight-bold grey--text text--darken-1"
              >
                <v-icon>mdi-calendar-range</v-icon>
                {{ formatDate(n.date_posted) }}
              </span>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="mt-6"></v-divider>
    <div class="text-center mt-6">
      <v-pagination
        ref="pagination"
        v-model="currentPage"
        next-icon="mdi-chevron-double-right"
        prev-icon="mdi-chevron-double-left"
        :length="lastPage"
        @input="goToPage"
        total-visible="7"
      >
      </v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      news: null,
      currentPage: 1,
      lastPage: null,
      search: "",
      loading: false,
      total: null,
      showResult: false,
      result: null,
    };
  },

  methods: {
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.lastPage) {
        if (this.currentPage === this.$refs.pagination.value) {
          return;
        } else {
          this.getBlog();
          this.$nextTick(() => {
            window.scrollTo({
              top: 10,
              left: 0,
              behavior: "smooth",
            });
          });
        }
      }
    },
    handleSearch(event) {
      // preventing multiple requests caused by holding down the Enter button
      // which can result in a Distributed Denial of Service (DDoS) issue
      if (event.repeat) {
        event.preventDefault();
        return;
      }

      this.loading = true;
      this.currentPage = 1;
      this.getBlog().then(() => {
        this.loading = false;
        this.result = this.search;
      });
    },
    generateExcerpt(content, maxWords = 20) {
      const words = content.split(" ");
      if (words.length <= maxWords) {
        return content;
      } else {
        const excerpt = words.slice(0, maxWords).join(" ");
        return excerpt + "......";
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("id-ID", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    newTab(n) {
      this.$router.push("/informasi/" + n);
    },
    async getBlog() {
      await this.$http("/blog", {
        params: {
          per_page: 9,
          is_published: 1,
          page: this.currentPage,
          q: this.search,
          with: ["category"],
        },
      }).then(({ data }) => {
        this.lastPage = data.last_page;
        this.news = data.data;
        this.total = data.total;
      });
    },
  },
  mounted() {
    this.getBlog();
  },
};
</script>

<style>
.clickable-title {
  cursor: pointer;
  color: inherit;
  transition: color 0.3s;
}

.clickable-title:hover .title,
.title:hover {
  color: rgb(69, 151, 245);
}
.clickable-title:hover .category-box-shadow,
.category-box-shadow:hover {
  background-color: rgb(69, 151, 245);
}

.fade-in-down {
  animation: fade-in-down-animation 0.5s ease-in-out both;
}

@keyframes fade-in-down-animation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.category-box-shadow {
  background-color: #636262;
  padding: 4px 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 10px;
  position: absolute;
  top: 10px;
}

.v-pagination__item--active {
  cursor: default !important;
}
</style>
