<template>
  <div class="font-weight-bold">
    <v-chip color="red darken-2" v-if="categoryName === 'Gizi Buruk'" small dark
      >Gizi Buruk (Severely Thinness)</v-chip
    >
    <v-chip
      color="yellow darken-4"
      v-if="categoryName === 'Gizi Kurang'"
      small
      dark
      >Gizi Kurang (Thinness)</v-chip
    >
    <v-chip
      color="green darken-2"
      v-if="categoryName === 'Gizi Baik'"
      small
      dark
      >Gizi Baik (Normal)</v-chip
    >
    <v-chip
      color="yellow darken-4"
      v-if="categoryName === 'Gizi Lebih'"
      small
      dark
      >Gizi Lebih (Overweight)</v-chip
    >
    <v-chip color="red darken-2" v-if="categoryName === 'Obesitas'" small dark
      >Obesitas (Obese)</v-chip
    >
  </div>
</template>

<script>
export default {
  props: ["categoryName"],
};
</script>
