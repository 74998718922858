var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"2"}},[_c('DataTable',_vm._b({scopedSlots:_vm._u([{key:"modal-form",fn:function(ref){
var editedItem = ref.editedItem;
var save = ref.save;
return [_c('ModulForm',{attrs:{"form":editedItem},on:{"save":save}})]}},{key:"filter",fn:function(ref){
var load = ref.load;
var params = ref.params;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('BarqunVSelect',{attrs:{"label":"Asal Sekolah","autocomplete":"","dense":"","items":_vm.schools,"form":params,"clearable":"","chips":"","small-chips":"","item-text":"name","item-value":"id","name":"filterAsalSekolah"},on:{"input":load}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('BarqunVSelect',{attrs:{"label":"Kelas","autocomplete":"","dense":"","items":[
                'KELAS I',
                'KELAS II',
                'KELAS III',
                'KELAS IV',
                'KELAS V',
                'KELAS VI' ],"form":params,"clearable":"","chips":"","small-chips":"","name":"filterKelas"},on:{"input":load}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('BarqunVSelect',{attrs:{"label":"Jenis Kelamin","autocomplete":"","dense":"","chips":"","small-chips":"","items":['LAKI-LAKI', 'PEREMPUAN'],"form":params,"clearable":"","name":"filterJenisKelamin"},on:{"input":load}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('BarqunVSelect',{attrs:{"label":"Wali Siswa","item-text":"name","autocomplete":"","chips":"","clearable":"","small-chips":"","dense":"","item-value":"id","items":_vm.familyMembers,"form":params,"name":"filterWaliMurid"},on:{"input":load}})],1)],1)]}},{key:"name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"pa-2 font-weight-bold"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":"green"}},[_vm._v(_vm._s(item.nisn))])],1),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":"blue lighten-2"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi mdi-home-city-outline")])],1),_vm._v(" "+_vm._s(item.school.name)+" ")],1)],1),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":"gray"}},[_vm._v(_vm._s(item.school_class))])],1),_c('p',[_c('v-chip',{attrs:{"dark":"","small":"","color":item.sex === 'LAKI-LAKI' ? 'blue' : 'pink'}},[_c('v-icon',[_vm._v("mdi mdi-gender-"+_vm._s(item.sex === "LAKI-LAKI" ? "male" : "female")+" ")]),_vm._v(" "+_vm._s(item.sex)+" ")],1)],1)])]}},{key:"anthropometric_measurements_count",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":"blue-grey","text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-checkbook")])],1),_vm._v(" "+_vm._s(item.anthropometric_measurements_count)+" ")],1)]}},{key:"latest_anthropometric_measurement",fn:function(ref){
              var item = ref.item;
return [(item.anthropometric_measurements_count > 0)?_c('span',[_c('NutritionalCategory',{attrs:{"categoryName":item.latest_anthropometric_measurement.category_name}})],1):_c('span',[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"gray darken-2","small":"","dark":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-alert-outline ")]),_vm._v("Pemeriksaan Belum Ada")],1)],1)]}},{key:"action",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.mobile,"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary rounded-lg","dark":""},on:{"click":function($event){return _vm.show(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Detail")])])],1)]}}])},'DataTable',_vm.datatable,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }