<template>
  <nav>
    <v-app-bar color="blue lighten-2" dark app>
      <v-app-bar-nav-icon @click.stop="drawerState"> </v-app-bar-nav-icon>

      <v-toolbar-title class="text-uppercase">
        <router-link to="/" class="text-decoration-none white--text">
          <h4
            v-if="$vuetify.breakpoint.mobile"
            class="font-light"
            title="BARQUN"
          >
            Monitoring Gizi
          </h4>
          <h4 v-else class="text-uppercase font-light">Monitoring Gizi App</h4>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-slide-x-transition>
        <v-chip
          v-if="!onLine || showBackOnline"
          class="ma-2"
          small
          :color="onLine ? `green` : `pink darken-1`"
          dark
          transition="slide-x-transition"
        >
          <v-icon left>
            {{ onLine ? `mdi-access-point-check` : `mdi-access-point-off` }}
          </v-icon>
          <b>
            {{ onLine ? `Online` : `Offline` }}
          </b>
        </v-chip>

        <v-chip
          v-if="syncing"
          class="ma-2"
          small
          color="orange"
          dark
          transition="slide-x-transition"
        >
          <v-icon left> mdi-cloud-sync </v-icon>
          <b> Updating... </b>
          <v-progress-circular
            size="15"
            width="2"
            indeterminate
          ></v-progress-circular>
        </v-chip>
      </v-slide-x-transition>

      <!-- <v-alert
        dense
        color="orange"
        dark
        border="top"
        icon="mdi-cloud-sync"
        transition="slide-x-transition"
      >
        Sync update...

        <template #append>
          <v-progress-circular
            size="20"
            width="3"
            indeterminate
          ></v-progress-circular>
        </template>
      </v-alert> -->
      <v-toolbar-title class="mb-1" v-if="$store.state.auth.isAuth">
        <router-link
          to="/help-center"
          class="font-weight-medium grey--text text-decoration-none help-center"
          ><v-icon>mdi-help-circle-outline</v-icon></router-link
        >
      </v-toolbar-title>
      <!-- <v-menu
        v-if="$store.state.auth.isAuth"
        transition="slide-y-transition"
        bottom
        :offset-y="true"
        max-height="360"
        min-width="360"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge
              overlap
              color="blue"
              dot
              :value="notifications.unRead.length"
            >
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-row no-gutters>
            <v-subheader>
              <span class="body-2">
                <router-link
                  to="/help-center"
                  class="font-weight-medium grey--text text-decoration-none"
                  >Help Center</router-link
                >
              </span>
            </v-subheader>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-3"
                  v-bind="attrs"
                  v-on="on"
                  @click="markAsRead"
                >
                  mark_email_read
                </v-icon>
              </template>
              <span>Mark all read</span>
            </v-tooltip>
          </v-row>
          <v-divider />
          <template v-if="notifications.all.length">
            <v-list-item
              v-for="notif in notifications.all"
              :key="notif.id"
              link
              :to="notif.data.link"
              class="align-start"
              @click="markAsRead(notif.id)"
              two-line
            >
              <v-list-item-content>
                <v-list-item-title :class="notif.read_at ? `grey--text` : ``">
                  {{ notif.data.title }}
                </v-list-item-title>
                <v-list-item-subtitle
                  :class="notif.read_at ? `grey--text` : ``"
                >
                  {{ notif.data.message }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  :class="notif.read_at ? `grey--text` : `info--text`"
                >
                  {{ $moment(notif.created_at).fromNow() }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon :color="notif.read_at ? `grey` : `info`">{{
                  notif.read_at ? `drafts` : `email`
                }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list-item v-else>
            <span class="body-2">Tidak Ada Notifikasi</span>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-menu offset-y v-if="$store.state.auth.isAuth">
        <template v-slot:activator="{ on }">
          <v-btn v-if="$vuetify.breakpoint.mobile" v-on="on" icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <v-btn v-else v-on="on" text>
            <span>{{ $store.state.auth.user.name || "Admin" }}</span>
            <v-icon right>expand_more</v-icon>
          </v-btn>
        </template>
        <v-list flat>
          <v-list-item router to="/profile">
            <v-list-item-title> Profile </v-list-item-title>
            <v-list-item-icon
              ><v-icon color="grey darken-1">person</v-icon></v-list-item-icon
            >
          </v-list-item>
          <!-- <v-list-item router to="/">
            <v-list-item-title> Settings </v-list-item-title>
            <v-list-item-icon>
              <v-icon color="grey darken-1">settings</v-icon>
            </v-list-item-icon>
          </v-list-item> -->
          <!-- <v-list-item @click="darkMode">
            <v-list-item-title
              >{{ $vuetify.theme.dark ? "Light Mode" : "Dark Mode" }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon v-if="$vuetify.theme.dark" color="yellow ">
                mdi-white-balance-sunny
              </v-icon>
              <v-icon v-else color="grey darken-1"> mdi-weather-night </v-icon>
            </v-list-item-icon>
          </v-list-item> -->
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-title> Exit </v-list-item-title>
            <v-list-item-icon><v-icon>exit_to_app</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text to="/login" v-else>
        <span>Login</span>
        <v-icon right>login</v-icon>
      </v-btn>
      <!-- <v-icon right>mdi-dots-vertical</v-icon> -->
    </v-app-bar>

    <Sidebar :drawer="drawer" />
  </nav>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Sidebar from "./Sidebar";
export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      drawer: {
        state: true,
      },
    };
  },
  computed: {
    ...mapState("auth", ["user", "notifications"]),
    ...mapState(["onLine", "showBackOnline", "syncing"]),
  },
  methods: {
    ...mapActions("auth", ["logout", "markAsRead"]),
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    drawerState() {
      this.drawer.state = !this.drawer.state;
    },
  },
};
</script>

<style scoped>
.help-center {
  cursor: pointer;
}
</style>
