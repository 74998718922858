<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-select
          clearable
          v-model="form.category_name"
          :items="[
            'Gizi Buruk',
            'Gizi Kurang',
            'Gizi Baik',
            'Gizi Lebih',
            'Obesitas',
          ]"
          label="Kategori Status Gizi"
          prepend-inner-icon="mdi-pill"
          placeholder="Pilih kategori status gizi"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <Editor :form="form"></Editor>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-select
          clearable
          v-model="form.type"
          :items="['Individu', '?']"
          label="Tipe Rekomendasi"
          placeholder="Pilih tipe rekomendasi"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Editor from "@/components/FormInput/Editor";

export default {
  props: ["form"],
  components: {
    Editor,
  },
};
</script>
