import Vue from "vue";
import VueRouter from "vue-router";
import app from "@/main";
import store from "@/store";

import Login from "@/views/auth/Login";
import Home from "@/views/Home";
import UserIndex from "@/views/users/Index";
import Profile from "@/views/users/Profile";
import RoleIndex from "@/views/roles/Index";
import SekolahIndex from "@/views/sekolah/Index";
import SiswaIndex from "@/views/siswa/Index";
import WaliSiswaIndex from "@/views/wali-siswa/Index";
import StafSekolahIndex from "@/views/staf-sekolah/Index";
import KategoriIndex from "@/views/kategori/Index";
import BlogIndex from "@/views/blog/Index";
import BlogCreate from "@/views/blog/Create";
import BlogEdit from "@/views/blog/Edit";
import KalkulatorAntropometriIndex from "@/views/kalkulator-antropometri/Index";
import AntropometriIndividuIndex from "@/views/antropometri-individu/Index";
import AntropometriIndividuDetail from "@/views/antropometri-individu/Detail";
import MonitoringGiziIndex from "@/views/monitoring-gizi/Index";
import MonitoringGiziDetailSekolah from "@/views/monitoring-gizi/DetailSekolah";
import MonitoringGiziDetailSiswa from "@/views/monitoring-gizi/DetailSiswa";
import NewsIndex from "@/views/news/Index";
import NewsDetail from "@/views/news/Detail";
import PuskesmasIndex from "@/views/puskesmas/Index";
import StafPuskesmasIndex from "@/views/staf-puskesmas/Index";
import RekomendasiIndex from "@/views/rekomendasi/Index";
import RekomendasiDuaVariabelIndex from "@/views/rekomendasi-dua-variabel/Index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/help-center",
    name: "help",
    component: () => import("../views/Help-center.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      isAuth: false,
    },
  },
  {
    path: "/users",
    name: "users",
    component: UserIndex,
    meta: {
      can: "users-view",

      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Users", disabled: false },
      ],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Profile", disabled: false },
      ],
    },
  },
  {
    path: "/roles",
    name: "Role",
    component: RoleIndex,
    meta: {
      can: "roles-view",

      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Roles", disabled: false },
      ],
    },
  },
  {
    path: "/sekolah",
    name: "Sekolah",
    component: SekolahIndex,
    meta: {
      can: "sekolah-view",

      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Sekolah", disabled: false },
      ],
    },
  },
  {
    path: "/siswa",
    name: "Siswa",
    component: SiswaIndex,
    meta: {
      can: "siswa-view",

      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Siswa", disabled: false },
      ],
    },
  },
  {
    path: "/wali-siswa",
    name: "WaliSiswa",
    component: WaliSiswaIndex,
    meta: {
      can: "wali-siswa-view",

      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Wali Siswa", disabled: false },
      ],
    },
  },
  {
    path: "/staf-sekolah",
    name: "StafSekolah",
    component: StafSekolahIndex,
    meta: {
      can: "staf-sekolah-view",
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Staf Sekolah", disabled: false },
      ],
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: BlogIndex,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Blog", disabled: false },
      ],
    },
  },
  {
    path: "/blog/tambah",
    name: "BlogCreate",
    component: BlogCreate,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Daftar Blog", to: "/blog" },
        { text: "Tambah Blog", disabled: false },
      ],
    },
  },
  {
    path: "/blog/:id/edit",
    name: "BlogEdit",
    component: BlogEdit,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Daftar Blog", to: "/blog" },
        { text: "Edit Blog", disabled: false },
      ],
    },
  },
  {
    path: "/kategori",
    name: "Kategori",
    component: KategoriIndex,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Kategori", disabled: false },
      ],
    },
  },
  {
    path: "/kalkulator-antropometri",
    name: "KalkulatorAntropometri",
    component: KalkulatorAntropometriIndex,
    meta: {
      isAuth: false,
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Kalkukator Antropometri", disabled: false },
      ],
    },
  },
  {
    path: "/antropometri-individu",
    name: "AntropometriIndividuIndex",
    component: AntropometriIndividuIndex,
    meta: {
      can: "antropometri-individu-view",
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Antropometri Individu", disabled: false },
      ],
    },
  },
  {
    path: "/monitoring-gizi",
    name: "MonitoringGizi",
    component: MonitoringGiziIndex,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Monitoring Gizi", disabled: false },
      ],
      can: "monitoring-gizi-view",
    },
  },
  {
    path: "/monitoring-gizi/sekolah/:id",
    name: "MonitoringGiziDetailSekolah",
    component: MonitoringGiziDetailSekolah,
    meta: {
      can: "monitoring-gizi-view",
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Monitoring Gizi", to: "/monitoring-gizi" },
        { text: "Detail Monitoring Gizi Sekolah", disabled: false },
      ],
    },
  },
  {
    path: "/monitoring-gizi/sekolah/:id/siswa/:siswaId",
    name: "MonitoringGiziDetailSiswa",
    component: MonitoringGiziDetailSiswa,
    meta: {
      can: "monitoring-gizi-view",
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Monitoring Gizi", to: "/monitoring-gizi" },
        {
          text: "Detail Monitoring Gizi Sekolah",
          to: {
            name: "MonitoringGiziDetailSekolah",
          },
        },
        { text: "Detail Monitoring Gizi Siswa", disabled: false },
      ],
    },
  },
  {
    path: "/antropometri-individu/detail/:id",
    name: "AntropometriIndividuDetail",
    component: AntropometriIndividuDetail,
    meta: {
      can: "antropometri-individu-view",

      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Antropometri Invididu", to: "/antropometri-individu" },
        { text: "Detail", disabled: false },
      ],
    },
  },
  {
    path: "/informasi",
    name: "NutritionInformation",
    component: NewsIndex,
    meta: {
      isAuth: false,
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Nutrition information", disabled: false },
      ],
    },
  },
  {
    path: "/informasi/:slug",
    name: "NutritionInformationDetail",
    component: NewsDetail,
    meta: {
      isAuth: false,
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Nutrition information", to: { name: "NutritionInformation" } },
        { text: "Nutrition information Detail", disabled: false },
      ],
    },
  },
  {
    path: "/puskesmas",
    name: "Puskesmas",
    component: PuskesmasIndex,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Puskesmas", disabled: false },
      ],
    },
  },
  {
    path: "/staf-puskesmas",
    name: "StafPuskesmas",
    component: StafPuskesmasIndex,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Staf Puskesmas", disabled: false },
      ],
    },
  },
  {
    path: "/rekomendasi",
    name: "Rekomendasi",
    component: RekomendasiIndex,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Rekomendasi", disabled: false },
      ],
    },
  },
  {
    path: "/rekomendasi-dua-variabel",
    name: "RekomendasiDuaVariabel",
    component: RekomendasiDuaVariabelIndex,
    meta: {
      breadcrumbs: [
        { text: "Dashboard", to: "/" },
        { text: "Rekomendasi Dua Variabel", disabled: false },
      ],
    },
  },
];

const router = new VueRouter({
  // mode: "history",
  // mode: 'hash',
  mode: process.env.IS_ELECTRON ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("SET_ROUTE", to);
  /* must call `next` */
  // console.log(store.state.auth.isAuth);
  if (store.state.auth.token) await store.dispatch("auth/auth", { from: from });
  //
  // console.log(!(store.state.auth.isAuth || store.state.auth.user.id) && to.name != 'login' && to.meta.isAuth !== false);
  if (
    !(store.state.auth.isAuth || store.state.auth.user.id) &&
    to.name != "login" &&
    to.meta.isAuth !== false
  )
    next("login");

  //
  if (store.state.auth.isAuth && to.name == "login") next("/");

  if (
    to.meta.can == undefined ||
    app.userCan(to.meta.can) ||
    app.userRole(to.meta.role)
  )
    next();
  else {
    next("/");
  }
});

export default router;
