/**
 * Calculates the age based on the birth date and the date of visit.
 *
 * @param {Date} birthDate - The birth date of the person.
 * @param {Date} [dateVisit=new Date()] - The date of visit. Defaults to the current date.
 * @return {Object} An object containing the calculated age.
 *         - ageYears {number} - The number of years.
 *         - ageMonths {number} - The number of months.
 *         - ageDays {number} - The number of days.
 */
export function calculateAge(birthDate, dateVisit = new Date()) {
  const today = new Date(dateVisit);
  const birthDateObj = new Date(birthDate);

  let ageMilliseconds = today - birthDateObj;
  let ageSeconds = ageMilliseconds / 1000;
  let ageMinutes = ageSeconds / 60;
  let ageHours = ageMinutes / 60;
  let ageDays = ageHours / 24;

  let ageYears = Math.floor(ageDays / 365);
  let remainingDays = ageDays % 365;
  let ageMonths = Math.floor(remainingDays / 30);

  return {
    ageYears: ageYears,
    ageMonths: ageMonths,
    ageDays: Math.floor(remainingDays % 30),
  };
}

/**
 * Calculates the z-score based on the BMI, BMI data, birth date, date of visit, and sex.
 *
 * @param {number} bmi - The body mass index.
 * @param {Object} bmiData - The BMI data.
 * @param {string} birthDate - The birth date.
 * @param {string} dateVisit - The date of visit.
 * @param {string} sex - The sex.
 * @returns {number|null} - The calculated z-score or null if the calculation cannot be performed.
 */
export function getZScore(bmi, bmiData, birthDate, dateVisit, sex) {
  // Calculate the age in months based on the birth date and date of visit
  const ageInfo = calculateAge(birthDate, dateVisit);
  const age = ageInfo.ageYears * 12 + ageInfo.ageMonths;

  // Determine the sex category based on the given sex value
  const sexCategory =
    sex === "LAKI-LAKI" || sex === "l" ? "laki laki" : "perempuan";

  let result = null;
  let finalResult = null;

  // Check if the age is within the range of 5 to 19 years (60 to 228 months)
  if (age >= 60 && age <= 228) {
    const bmiTable = bmiData[sexCategory][Math.floor(age / 12)][age % 12];

    // Check if the BMI table data is available for the given age and sex category
    if (bmiTable) {
      const categories = Object.keys(bmiTable);
      const minus2SD = categories[4];
      const minus3SD = categories[3];
      const plus2SD = categories[8];
      const plus3SD = categories[9];
      const L = categories[0];
      const M = categories[1];
      const S = categories[2];

      // Calculate the z-score based on the BMI value and the BMI table data
      result =
        ((bmi / bmiTable[M]) ** bmiTable[L] - 1) / (bmiTable[S] * bmiTable[L]);

      // Adjust the z-score if it is less than -3 or greater than 3
      if (result <= -3) {
        const sd23Neg = bmiTable[minus2SD] - bmiTable[minus3SD];
        finalResult = -3 + (bmi - bmiTable[minus3SD]) / sd23Neg;
      } else if (result >= 3) {
        const sd23Plus = bmiTable[plus3SD] - bmiTable[plus2SD];
        finalResult = 3 + (bmi - bmiTable[plus3SD]) / sd23Plus;
      } else {
        finalResult = result;
      }

      // Return the final z-score rounded to 2 decimal places
      return finalResult.toFixed(2);
    } else {
      // Return null if the BMI table data is not available for the given age and sex category
      return null;
    }
  }

  // Return null if the age is not within the range of 5 to 19 years
  return null;
}

/**
 * Calculates the BMI (Body Mass Index) based on the given weight, height, and birth date.
 *
 * @param {string|number} weight - The weight of the person in kilograms.
 * @param {string|number} height - The height of the person in centimeters.
 * @param {string} birthDate - The birth date of the person in the format 'YYYY-MM-DD'.
 * @return {string|null} The calculated BMI as a string with two decimal places, or null if the input is invalid.
 */
export function calculateBMI(weight, height, birthDate) {
  // Convert weight and height to numbers
  const w = parseFloat(weight);
  const h = parseFloat(height) / 100;

  // Calculate the age in years based on the birth date
  const { ageYears } = calculateAge(birthDate);

  // Check if the weight, height, and age are valid for calculating BMI
  if (w && h && ageYears >= 5 && ageYears <= 19) {
    let bmi;

    // Calculate the BMI using the formula: weight / (height * height)
    bmi = w / (h * h);

    // Return the calculated BMI with two decimal places
    return bmi.toFixed(2);
  } else {
    // Return null if the input is invalid
    return null;
  }
}

/**
 * Returns the nutritional category name based on the given z-score value.
 *
 * @param {number} zScore - The z-score value.
 * @returns {string} The corresponding nutritional category name.
 */
export function getZScoreCategoryName(zScore) {
  // Check if the z-score is less than or equal to -3
  if (zScore <= -3) {
    return "Gizi Buruk"; // Return 'Gizi Buruk' if true
  }

  if (zScore > -3 && zScore <= -2) {
    return "Gizi Kurang"; // Return 'Gizi Kurang' if true
  }

  if (zScore > -2 && zScore <= 1) {
    return "Gizi Baik"; // Return 'Gizi Normal' if true
  }

  if (zScore > 1 && zScore <= 2) {
    return "Gizi Lebih"; // Return 'Gizi Lebih' if true
  }

  // Return 'Obesitas' if none of the previous conditions are met
  return "Obesitas";
}

/**
 * Reset the counts for different nutrition categories to zero.
 *
 * @returns {Object} An object with counts reset to zero for various nutrition categories.
 */
export function resetNutritionCategoryNameCount() {
  return {
    malnutrition: 0,
    undernutrition: 0,
    normal: 0,
    overnutrition: 0,
    obesity: 0,
  };
}

/**
 * Increment the count for the given nutrition category name in the nutritionsCount object.
 *
 * @param {Object} nutritionsCount - The object containing counts for various nutrition categories.
 * @param {string} nutritionCategoryName - The name of the nutrition category to increment.
 */
export function incrementNutritionCategoryName(
  nutritionsCount,
  nutritionCategoryName
) {
  const categoryMap = {
    "Gizi Buruk": "malnutrition",
    "Gizi Kurang": "undernutrition",
    Normal: "normal",
    "Gizi Lebih": "overnutrition",
    Obesitas: "obesity",
  };

  const categoryName = categoryMap[nutritionCategoryName];

  if (categoryName) {
    nutritionsCount[categoryName]++;
  }
}
