import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2, {
  confirmButtonColor: "#2196f3",
  cancelButtonColor: "#ff7674",
});
