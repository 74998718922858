<template>
  <v-container>
    <v-card elevation="2" class="rounded-xl">
      <DataTable v-bind="datatable" @saved="saved">
        <template #modal-form="{ editedItem }">
          <ModulForm :form="editedItem" />
        </template>

        <template #school_type="{ item }">
          <v-chip
            class="ma-2"
            :color="`${
              item.school_type == 'NEGERI' ? `green` : `blue`
            } font-weight-bold`"
            small
            dark
          >
            {{ item.school_type }}
          </v-chip>
        </template>

        <template #filter="{ load, params }">
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <BarqunVSelect
                label="Kecamatan"
                autocomplete
                chips
                clearable
                dense
                ref="selectDis"
                small-chips
                required
                item-text="name"
                item-value="id"
                :items="districts"
                name="filterKecamatan"
                :form="params"
                @input="load"
                @custom-input="onDistrictChange"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <BarqunVSelect
                label="Kelurahan"
                autocomplete
                chips
                ref="kel"
                clearable
                dense
                small-chips
                :items="villages"
                item-value="id"
                item-text="name"
                name="filterKelurahan"
                :form="params"
                @input="load"
              ></BarqunVSelect>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <BarqunVSelect
                label="Tipe Sekolah"
                chips
                clearable
                dense
                small-chips
                :items="['NEGERI', 'SWASTA']"
                name="filterTipeSekolah"
                :form="params"
                @input="load"
              ></BarqunVSelect>
            </v-col>
          </v-row>
        </template>

        <template #name="{ item }">
          <div class="d-flex flex-column">
            <span class="pt-2">{{ item.name }}</span>
            <span class="pb-1">
              <v-chip dark small color="success" class="font-weight-bold">{{
                item.npsn
              }}</v-chip>
            </span>
          </div>
        </template>
        <template #actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.sync == 'presync'"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                small
                class="mr-2"
              >
                mdi-clock-time-four-outline
              </v-icon>
            </template>
            <span>Belum Sinkron Server</span>
          </v-tooltip>

          {{ item.uuid }}
          <v-icon color="success" small class="mr-2" @click="notify(item)">
            mdi-bell
          </v-icon>
        </template>
      </DataTable>
    </v-card>
  </v-container>
</template>
<script>
import DataTable from "@/components/DataTable";
import ModulForm from "./Form";
import BarqunVSelect from "@/components/FormInput/BarqunVSelect";
import { mapActions } from "vuex";
export default {
  components: {
    DataTable,
    ModulForm,
    BarqunVSelect,
  },
  data() {
    return {
      datatable: {
        ref: "table",
        hasFilter: true,
        moduleName: "Sekolah",
        headers: [
          { text: "No", value: "num", sortable: false },
          { text: "Nama Sekolah", value: "name", slot: true },
          { text: "Alamat", value: "address" },
          { text: "Kecamatan", value: "district.name", sortable: false },
          { text: "Kelurahan", value: "village.name", sortable: false },
          { text: "Tipe Sekolah", value: "school_type", slot: true },
          { text: "Puskesmas", value: "public_health_center.name", slot: true },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ],
        params: {
          sortby: "name",
          sortbydesc: "asc",
          with: ["district", "village", "publicHealthCenter"],
          filterKecamatan: "",
          filterKelurahan: "",
        },
        selected: false,
      },
      districts: [],
      villages: [],
    };
  },
  mounted() {
    this.$http("kecamatan", {
      params: {
        all: true,
        pluck: ["name", "id"],
      },
    })
      .then((res) => {
        this.districts = res.data.data.map(({ id, name }) => ({ id, name }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },

  methods: {
    ...mapActions("auth", ["auth"]),
    /**
     * Update DistrictOptions based on the selected district
     *
     * @param {type} selectedDistrict - the selected distrcit
     * @return {type} - no return value
     */
    onDistrictChange(selectedDistrict) {
      console.log(selectedDistrict);
      if (!selectedDistrict) {
        this.params.filterKelurahan = null;
        this.params.filterDistrict = null;
        this.villages = [];
      } else {
        this.$http("kelurahan-desa", {
          params: {
            district_id: selectedDistrict,
          },
        })
          .then(({ data }) => {
            this.villages = data.data.map(({ id, name }) => ({ id, name }));
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    },
    saved() {
      this.auth();
    },
    notify(item) {
      this.$swal({
        title: "Send Notification",
        text: "Are you sure want to send notification to this user?",
        icon: "warning",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          this.$http(`users/notify/${item.id}`);
        },
      });
    },
  },
};
</script>
