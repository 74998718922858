import router from "@/router";
import { db } from "@/plugins/db";

export default {
  namespaced: true,
  state: {
    user: {},
    isAuth: localStorage.getItem("isAuth") || false,
    token: localStorage.getItem("token") || "",
    cancelTokens: [],
    error: "",
    loading: false,
    notifications: {
      all: [],
      unRead: [],
    },
  },
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    },
    getRoute(state, getters, rootState) {
      return rootState.route;
    },
  },
  mutations: {
    SET_ERROR(state, value) {
      state.error = value;
    },
    SET_LOADING(state, value = false) {
      state.loading = value;
    },
    CLEAR_ERROR(state) {
      state.error = "";
    },
    LOGIN(state, data) {
      state.isAuth = true;
      localStorage.setItem("isAuth", true);
      state.token = data.token;
      localStorage.setItem("token", data.token);
    },
    LOGOUT(state) {
      // window.Echo.unsubscribe(`App.Models.User.${state.user.id}`)
      // Echo.private(`App.Models.User.${state.user.id}`)
      //   .stopListening('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated')
      state.isAuth = false;
      state.token = null;
      state.user = {};
      localStorage.removeItem("isAuth");
      state.token = null;
      localStorage.removeItem("token");
    },
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
    },
  },
  actions: {
    login({ commit }, data) {
      commit("CLEAR_ERROR");
      commit("SET_LOADING", true);

      this._vm.$http
        .post("auth", data)
        .then(async (response) => {
          await commit("LOGIN", response.data);

          commit("SET_LOADING");
          // router.push('/')
          // router.push("/");
          if (process.env.IS_ELECTRON) {
            setTimeout(()=> {
              router.push('/news')
            }, 1000)
          }else {
            window.location = '/'

          }
        })
        .catch(({ response }) => {
          commit("SET_LOADING");
          commit("SET_ERROR", response.data.error);
          console.log(response.data);
        });
    },
    async logout({ commit }) {
      await commit("LOGOUT");
      router.push("/login");
    },
    // async auth({ state, dispatch }) {
    //   await this._vm
    //     .$http("auth")
    // },

    async auth({ state, dispatch, rootState }) {
      if (rootState.onLine) {
        await this._vm
          .$http("auth")
          .then(({ data }) => {
            state.user = data;
            state.notifications = data.notifications;

            db.user.put(data);
          })
          .catch(() => {
            dispatch("logout");
          });
      } else {
        db.$("user")
          .first()
          .then((user) => {
            if (user) state.user = user;
            else dispatch("logout");
          });
      }
    },
    loadNotifications({ state }) {
      this._vm.$http
        .get("/notifications")
        .then(({ data }) => (state.notifications = data))
        .catch();
    },
    markAsRead({ dispatch }, payload) {
      this._vm.$http
        .get(`markAsRead/${payload.id || ""}`)
        .then(() => dispatch("loadNotifications"))
        .catch();
    },
    CANCEL_PENDING_REQUESTS(context) {
      context.state.cancelTokens.forEach((request) => {
        if (request.cancel) request.cancel();
      });

      context.commit("CLEAR_CANCEL_TOKENS");
    },
  },
};
