<template>
  <div>
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header class="font-weight-bold">
          <div>
            <v-icon size="38" color="yellow darken-3" right
              >mdi mdi-alert-decagram-outline</v-icon
            >
            Pemberitahuan Penting! (klik untuk melihat)
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-5">
          <!-- Obesity alert -->
          <v-alert
            dense
            outlined
            type="error"
            text
            v-if="nutritionsCountPercentage.obesity > 20"
          >
            <div class="text-h6">Pemberitahuan Penting! (Obesitas >20%)</div>

            <div>
              Kami ingin memberitahukan bahwa berdasarkan data terbaru, sejumlah
              anak di wiliayah kerja anda telah teridentifikasi memiliki
              <span class="font-weight-bold">status gizi obesitas</span> dan
              menjadi
              <span class="font-weight-bold"
                >masalah gizi kesehatan masyarakat</span
              >.
            </div>

            <div>
              Kami sangat berharap puskesmas dapat berkolaborasi dengan sekolah
              untuk mengambil langkah-langkah pencegahan dan tindakan yang
              sesuai untuk membantu anak-anak mencapai status gizi yang lebih
              sehat.
            </div>

            <v-divider class="my-4 error"></v-divider>

            <div>Terima kasih atas perhatian dan kerjasamanya.</div>
          </v-alert>

          <!-- Overnutrition alert -->
          <v-alert
            dense
            outlined
            type="warning"
            text
            v-if="nutritionsCountPercentage.overnutrition > 10"
          >
            <div class="text-h6">Pemberitahuan Penting! (Gizi Lebih >10%)</div>

            <div>
              Kami ingin memberitahukan bahwa berdasarkan data terbaru, sejumlah
              anak di wilayah kerja anda telah teridentifikasi memiliki
              <span class="font-weight-bold">status gizi overweight</span> dan
              menjadi masalah gizi kesehatan masyarakat.
            </div>

            <div>
              Kami sangat berharap puskesmas dapat berkolaborasi dengan sekolah
              untuk mengambil langkah-langkah pencegahan dan tindakan yang
              sesuai untuk membantu anak-anak mencapai status gizi yang lebih
              sehat.
            </div>

            <v-divider class="my-4 warning"></v-divider>

            Terima kasih atas perhatian dan kerjasamanya.
            <div></div>
          </v-alert>

          <!-- Malnutrition alert -->
          <v-alert
            dense
            outlined
            type="error"
            text
            v-if="nutritionsCountPercentage.malnutrition > 10"
          >
            <div class="text-h6">Pemberitahuan Penting! (Gizi Buruk >10%)</div>

            <div>
              Kami ingin memberitahukan bahwa berdasarkan data terbaru, sejumlah
              anak di wilayah kerja anda telah teridentifikasi memiliki
              <span class="font-weight-bold">status gizi buruk</span> dan
              menjadi
              <span class="font-weight-bold"
                >masalah gizi kesehatan masyarakat</span
              >.
            </div>

            <div>
              Kami sangat berharap puskesmas dapat berkolaborasi dengan sekolah
              untuk mengambil langkah-langkah pencegahan dan tindakan yang
              sesuai untuk membantu anak-anak mencapai status gizi yang lebih
              sehat.
            </div>

            <v-divider class="my-4 error"></v-divider>

            <div>Terima kasih atas perhatian dan kerjasamanya.</div>
          </v-alert>

          <!-- Undernutrition alert -->
          <v-alert
            dense
            outlined
            type="warning"
            text
            v-if="nutritionsCountPercentage.undernutrition > 10"
          >
            <div class="text-h6">Pemberitahuan Penting! (Gizi Kurang >10%)</div>

            <div>
              Kami ingin memberitahukan bahwa berdasarkan data terbaru, sejumlah
              anak di wilayah kerja anda telah teridentifikasi memiliki
              <span class="font-weight-bold">status gizi kurang</span> dan
              menjadi masalah gizi kesehatan masyarakat.
            </div>

            <div>
              Kami sangat berharap puskesmas dapat berkolaborasi dengan sekolah
              untuk mengambil langkah-langkah pencegahan dan tindakan yang
              sesuai untuk membantu anak-anak mencapai status gizi yang lebih
              sehat.
            </div>

            <v-divider class="my-4 warning"></v-divider>

            Terima kasih atas perhatian dan kerjasamanya.
            <div></div>
          </v-alert>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: ["nutritionsCount"],
  data() {
    return {
      nutritionsCountPercentage: {
        malnutrition: null,
        obesity: null,
        overnutrition: null,
        undernutrition: null,
      },
    };
  },
  watch: {
    nutritionsCount: function () {
      this.calculatePercentage();
    },
  },
  mounted() {
    this.calculatePercentage();
  },
  methods: {
    calculatePercentage() {
      // obesity
      const obesityPercentageResult = this.calculatePercentageNutritionStatus(
        this.nutritionsCount.students_count,
        this.nutritionsCount.obesity
      );

      this.nutritionsCountPercentage.obesity = obesityPercentageResult;

      // overnutrition
      const overnutritionPercentageResult =
        this.calculatePercentageNutritionStatus(
          this.nutritionsCount.students_count,
          this.nutritionsCount.overnutrition
        );

      this.nutritionsCountPercentage.overnutrition =
        overnutritionPercentageResult;

      // malnutrition
      const malnutritionPercentageResult =
        this.calculatePercentageNutritionStatus(
          this.nutritionsCount.students_count,
          this.nutritionsCount.malnutrition
        );

      this.nutritionsCountPercentage.malnutrition =
        malnutritionPercentageResult;

      // undernutrition
      const undernutritionPercentageResult =
        this.calculatePercentageNutritionStatus(
          this.nutritionsCount.students_count,
          this.nutritionsCount.undernutrition
        );

      this.nutritionsCountPercentage.undernutrition =
        undernutritionPercentageResult;
    },
    /**
     * Calculate the percentage of students with a specific nutrition status.
     *
     * @param {Number} totalStudent - Total number of students.
     * @param {Number} totalNutritionStatus - Total number of students with the specific nutrition status.
     * @returns {Number} The percentage of students with the specific nutrition status (rounded down to the nearest integer).
     */
    calculatePercentageNutritionStatus(totalStudent, totalNutritionStatus) {
      // formula : (total nutrition status / total of students) * 100
      const percentage = (totalNutritionStatus / totalStudent) * 100;

      return Math.trunc(percentage);
    },
  },
};
</script>
