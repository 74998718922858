var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.combobox)?_c('v-combobox',_vm._b({attrs:{"items":_vm.itemList,"label":_vm.label,"item-value":_vm.itemValue,"item-text":_vm.itemText,"rules":[function (value) { return !_vm.required || !!value || 'Required.'; }],"error":false},on:{"change":function (value) {
        this$1.$emit('custom-input', value);
      },"input":function (value) {
        this$1.$emit('custom-input', value);
        this$1.$emit('input', value);
        this$1.$emit('input:this', this$1.$parent);
      }},scopedSlots:_vm._u([(_vm.required)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" *")])])]},proxy:true}:null],null,true),model:{value:(_vm.form[_vm.name]),callback:function ($$v) {_vm.$set(_vm.form, _vm.name, $$v)},expression:"form[name]"}},'v-combobox',_vm.$attrs,false),[_vm._v(" > ")]):(_vm.autocomplete)?_c('v-autocomplete',_vm._b({attrs:{"items":_vm.itemList,"label":_vm.label,"item-value":_vm.itemValue,"item-text":_vm.itemText,"rules":[function (value) { return !_vm.required || !!value || 'Required.'; }],"error":false,"search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function (value) {
        this$1.$emit('custom-input', value);
        this$1.$emit('custom-input:this', this$1.$parent);
      },"input":function (value) {
        this$1.$emit('input', value);
        this$1.$emit('input:this', this$1.$parent);
      },"blur":function () { return this$1.$emit('blur'); }},scopedSlots:_vm._u([(_vm.required)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" *")])])]},proxy:true}:null,(_vm.url)?{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}:null],null,true),model:{value:(_vm.form[_vm.name]),callback:function ($$v) {_vm.$set(_vm.form, _vm.name, $$v)},expression:"form[name]"}},'v-autocomplete',_vm.$attrs,false)):_c('v-select',_vm._b({attrs:{"rules":[function (value) { return !_vm.required || !!value || 'Required.'; }],"error":false,"label":_vm.label,"autofocus":_vm.autofocus,"items":_vm.itemList,"item-value":_vm.itemValue,"item-text":_vm.itemText},on:{"change":function (value) {
        var selectedItem = this$1.itemList.find(
          function (item) { return item[this$1.itemText] === value; }
        );
        var selectedId = selectedItem;
        this$1.$emit('custom-input', selectedId);
      },"input":function (value) {
        this$1.$emit('input', value);
        this$1.$emit('input:this', this$1.$parent);
      }},scopedSlots:_vm._u([(_vm.required)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" *")])])]},proxy:true}:null],null,true),model:{value:(_vm.form[_vm.name]),callback:function ($$v) {_vm.$set(_vm.form, _vm.name, $$v)},expression:"form[name]"}},'v-select',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }